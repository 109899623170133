/*
 * @Description: 科目分类树形结构处理工具函数
 * 注：在业务代码中也会使用到下面的常量和方法，请勿删除或重命名
 */

// 科目类型
export const TYPE_MAP = { Default: '原子科目', CustomFormula: '复合科目', BizCalculate: '二次计算科目', Stat: '统计科目' }
export const TYPE_COLOR_MAP = { Default: 'm-type-primary', CustomFormula: 'm-type-success', BizCalculate: 'm-type-error', Stat: 'm-type-warning' }

// 统计维度
export const DIMENSION_MAP = { Shop: '店铺', Anchor: '主播', Sku: '单品' }
export const DIMENSION_COLOR_MAP = { Shop: 'm-type-primary', Anchor: 'm-type-success', Sku: 'm-type-warning' }

// 业务类型
export const BIZ_TYPE_MAP = { Expense: '费用', Income: '收入', Cost: '成本', Other: '其他' }
export const BIZ_TYPE_COLOR_MAP = { Expense: 'm-type-primary', Income: 'm-type-success', Cost: 'm-type-warning', Other: 'm-tips-color' }

export const TREE_CONFIG = { id: '_value', children: '_children' }

/**
 * @description: 删除没用的属性，减少数据量
 * @param {object} item 原始对象
 * @return {object} 删除没用属性后的对象
 */
const filterProps = (item, options = {}) => {
  let filterValidProps = options.filterValidProps || []
  // 全量返回
  if (filterValidProps === 'all') return item
  if (!Array.isArray(filterValidProps)) filterValidProps = []

  Object.keys(item).map((key) => {
    const validProps = [
      '_isItem',
      '_value',
      '_rawValue',
      '_code',
      '_title',
      '_label',
      '_children',
      'disabled',
      '_isTop',
      'isDefault',
      'type',
      'bizType',
      'isSupport',
      'isShow',
      'remark',
      'dimension',
      ...filterValidProps
    ]
    if (!validProps.includes(key)) {
      delete item[key]
    }
  })
  return item
}

/**
 * @description: 原始数据格式转换
 * @param {array} rawData [{id,classCode,classId,className,parentId,children:[],item:[{itemCode,itemId,itemName,isDefault,isShow,isSupport,type,bizType,remark}]}]
 * @param {object} options {multiple}
 * @return {array} [{_value,_rawValue,_title,_label,_isItem,_isTop,_code,_children:[...前面带下划线的属性,isDefault,isShow,isSupport,type,bizType,remark]}]
 */
export const transformRawData = (rawData, options) => {
  return new Promise((resolve) => {
    if (!Array.isArray(rawData)) resolve([])

    const { multiple = true } = options || {}

    // 标注一级节点
    _.each(rawData, (node) => (node._isTop = true))

    const traverse = (nodes) => {
      for (const node of nodes) {
        node._isItem = !!node.itemId
        node._value = node._isItem ? `item_${node.itemId}` : `class_${node.classId}`
        node._rawValue = node.itemId || node.classId
        node._code = node.itemCode || node.classCode
        node._title = node.itemName || node.className
        node._label = node._title // 该值可用做搜索的字段，可按照搜索需求进行修改

        // 由于用到ASwitch组件，把该值转为Boolean类型
        if (node.isShow !== undefined) node.isShow = Boolean(node.isShow)

        const children = node.children || []
        // 兼容不同接口：部分接口返回item，部分返回items字段
        const items = node.item || node.items || []
        if (_.isEmpty(children) && _.isEmpty(items)) {
          node._children = undefined
        } else {
          node._children = _.concat(node._children || [], children, items)
        }

        // 如果是单选模式，禁用所有分类，仅可选择科目
        if (!multiple && !node._isItem) node.disabled = true

        filterProps(node, options)

        if (_.size(node._children)) traverse(node._children)
      }
    }

    traverse(rawData)

    resolve(rawData)
  })
}
