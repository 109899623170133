/*
 * @Description: 树结构操作工具函数
 */
import cloneDeep from 'lodash/cloneDeep'

const DEFAULT_CONFIG = {
  id: 'id', // 唯一标识属性名
  pid: 'pid', // 父节点标识属性名
  children: 'children', // 子节点属性名
}

const getConfig = (config = {}) => Object.assign({}, DEFAULT_CONFIG, config)

/**
 * @description:  列表结构转树结构
 * @param {Array} list
 * @param {Object} config 使用到的配置项：id、pid、children
 * @return {Array} [{id: '0', title: '节点0', parentId: '', _level: 1, children: [{id: '1', title: '节点1', parentId: '0', _level: 2, children: Array(2)}]}]
 * @example toTree(list[, config])
 */
export const toTree = (list = [], config = {}) => {
  // 进行深拷贝，避免改到原列表
  list = cloneDeep(list)

  const { id, pid, children } = getConfig(config)

  const nodeMap = list.reduce((map, node) => {
    node._level = 1
    map[node[id]] = node
    return map
  }, new Map())

  return list.filter((node) => {
    const parent = nodeMap[node[pid]]
    if (parent) {
      // 非叶子节点添加 children
      !parent[children] && (parent[children] = [])
      node._level = parent._level + 1
      // 先删除后增加=> 确保顺序和list保持一致
      const index = parent[children].findIndex((item) => item[id] === node[id])
      if (index > -1) parent[children].splice(index, 1)
      parent[children].push(node)
    }
    // 没有父节点则表示该节点为顶级节点，可存在多个平级的顶级节点
    return !parent
  })
}

/**
 * @deprecated 使用flattenTree
 * @description: 树结构转列表结构 - 循环实现
 * @param {Array} tree
 * @param {Object} config 使用到的配置项：children
 * @return {Array} [{id: '0', title: '节点0', parentId: ''}, {id: '1', title: '节点1', parentId: '0'}, {id: '1-1', title: '节点1-1', parentId: '1'}]
 * @example toList(tree[, config])
 */
export const toList = (tree = [], config = {}) => {
  // 进行深拷贝，避免改到原树
  tree = cloneDeep(tree)

  const { children } = getConfig(config)

  const result = tree.map((node) => ((node._level = 1), node))
  for (let i = 0; i < result.length; i++) {
    if (!result[i][children]) continue

    const list = result[i][children].map((node) => ((node._level = result[i]._level + 1), node))
    // 在 i+1 索引处插入 ...list
    result.splice(i + 1, 0, ...list)
  }
  return result
}

export const flatten = (tree, config = {}) => {
  const res = []
  const { children: childrenKey } = getConfig(config)
  for (let i = 0; i < tree.length; i++) {
    const node = cloneDeep(tree[i])
    res.push(node)
    if (node[childrenKey]) {
      res.push(...flatten(node[childrenKey]))
    }
  }
  return res
}

/**
 * @description: 树结构查找 - 查找符合条件的单个节点
 * @param {Array} tree
 * @param {Function} func 回调函数，接收node参数，表示约束条件
 * @param {Object} config 使用到的配置项：children
 * @return {Object|Null} 返回广度优先遍历查找到的第一个符合条件(callback(node)为true)的节点，没有则返回null // {id: '1-1', title: '节点1-1', parentId: '1', _level: 3} 或 null
 * @example findNode(tree, callback[, config]) // findNode(tree, (node) => node.parentId === '1')
 */
export const findNode = (tree, func, config = {}) => {
  const { children } = getConfig(config)
  const list = [...tree]
  for (const node of list) {
    if (func(node)) return node
    node[children] && list.push(...node[children])
  }
  return null
}

/**
 * @description: 树结构查找 - 查找符合条件的所有节点
 * @param {Array} tree
 * @param {Function} func 回调函数，接收node参数，表示约束条件
 * @param {Object} config 使用到的配置项：children
 * @return {Array} [{id: '1-1', title: '节点1-1', parentId: '1', _level: 3}, {id: '1-2', title: '节点1-2', parentId: '1', _level: 3}]
 * @example findNodeAll(tree, callback[, config]) // findNodeAll(tree, (node) => node.parentId === '1')
 */
export const findNodeAll = (tree, func, config = {}) => {
  const { children } = getConfig(config)
  const list = [...tree]
  const result = []
  for (const node of list) {
    func(node) && result.push(node)
    node[children] && list.push(...node[children])
  }
  return result
}

/**
 * @description: 树结构查找 - 查找符合条件的单个节点的路径
 * @param {Array} tree
 * @param {Function} func 回调函数，接收node参数，表示约束条件
 * @param {Object} config config 使用到的配置项：children
 * @return {Array|Null} 返回符合条件(callback(node)为true)的节点的所有祖先节点有序组成的数组，没有找到节点则返回null // [{id: '0', title: '节点0', parentId: '', _level: 1, children: Array(1)},{id: '1', title: '节点1', parentId: '0', _level: 2, children: Array(2)},{id: '1-1', title: '节点1-1', parentId: '1', _level: 3}]
 * @example findPath(tree, callback[, config]) // findPath(tree, (node) => node.id === '1-1')
 */
export const findPath = (tree, func, config = {}) => {
  const { children } = getConfig(config)
  const path = []
  const list = [...tree]
  const visitedSet = new Set()
  while (list.length) {
    const node = list[0]
    if (visitedSet.has(node)) {
      path.pop()
      list.shift()
    } else {
      visitedSet.add(node)
      node[children] && list.unshift(...node[children])
      path.push(node)
      if (func(node)) return path
    }
  }
  return null
}

/**
 * @description: 树结构查找 - 查找符合条件的所有节点的路径
 * @param {Array} tree
 * @param {Function} func 回调函数，接收node参数，表示约束条件
 * @param {Object} config 使用到的配置项：children
 * @return {Array} 返回符合条件(callback(node)为true)的节点路径组成的数组，每一条符合条件的路径就是一个数组 // [[结构和单个节点路径一样],[],...]
 * @example findPathAll(tree, callback[, config]) // findPathAll(tree, (node) => node.id === '1-1' || node.id === '2-1')
 */
export const findPathAll = (tree, func, config = {}) => {
  const { children } = getConfig(config)
  const path = []
  const list = [...tree]
  const result = []
  const visitedSet = new Set()
  while (list.length) {
    const node = list[0]
    if (visitedSet.has(node)) {
      path.pop()
      list.shift()
    } else {
      visitedSet.add(node)
      node[children] && list.unshift(...node[children])
      path.push(node)
      func(node) && result.push([...path])
    }
  }
  return result
}

/**
 * @description: 树结构筛选
 * @param {Array} tree
 * @param {Function} func 回调函数，接收node参数，表示约束条件
 * @param {Object} config 使用到的配置项：children
 * @return {Array} 返回符合筛选条件(callback(node)为true)的树节点构成的树，一个节点符合条件，其祖先节点也会被保留返回
 * @example filter(tree, callback[, config]) // filter(tree, (node) => node.id === '1-1' || node.id === '2-1')
 */
export const filter = (tree, func, config = {}) => {
  const { children } = getConfig(config)
  function listFilter(list) {
    return list
      .map((node) => ({ ...node }))
      .filter((node) => {
        node[children] = node[children] && listFilter(node[children])
        return func(node) || (node[children] && node[children].length)
      })
  }
  return listFilter(tree)
}

/**
 * @description: 树结构遍历 - 对于所有节点node调用callback(node)，深度优先
 * @param {Array} tree
 * @param {Function} func 回调函数，接收node参数
 * @param {Object} config 使用到的配置项：children
 * @return {Array}
 * @example forEach(tree, callback[, config])
 */
export const forEach = (tree, func, config = {}) => {
  const { children } = getConfig(config)
  const list = [...tree]
  for (let i = 0; i < list.length; i++) {
    func(list[i])
    list[i][children] && list.splice(i + 1, 0, ...list[i][children])
  }
}

/**
 * @description: 插入节点
 * @param {Array} tree
 * @param {Object} node
 * @param {Object} targetNode 新插入的节点
 * @param {Object} config 使用到的配置项：children
 * @param {Number} after 0在node之前 1在node之后
 * @return {Array}
 */
const _insert = (tree, node, targetNode, config, after) => {
  const { children } = getConfig(config)
  function insert(list) {
    const idx = list.indexOf(node)
    idx < 0
      ? list.forEach((n) => insert(n[children] || []))
      : list.splice(idx + after, 0, targetNode)
  }
  insert(tree, node)
}

/**
 * @description: 节点插入 - 在指定oldNode前插入newNode
 * @param {Array} tree
 * @param {Object} newNode
 * @param {Object} oldNode
 * @param {Object} config 使用到的配置项：children
 * @return {Array} 如果树中没有oldNode，则不会改变原数组。注意oldNode和newNode的参数顺序，和它们在树中的顺序一致
 * @example insertBefore(tree, newNode, oldNode[, config])
 */
export const insertBefore = (tree, newNode, oldNode, config = {}) => {
  _insert(tree, oldNode, newNode, config, 0)
}

/**
 * @description: 节点插入 - 在指定oldNode后插入newNode
 * @param {Array} tree
 * @param {Object} oldNode
 * @param {Object} newNode
 * @param {Object} config 使用到的配置项：children
 * @return {Array} 如果树中没有oldNode，则不会改变原数组。注意oldNode和newNode的参数顺序，和它们在树中的顺序一致
 * @example insertAfter(tree, oldNode, newNode[, config])
 */
export const insertAfter = (tree, oldNode, newNode, config = {}) => {
  _insert(tree, oldNode, newNode, config, 1)
}

/**
 * @description: 节点删除 - 删除符合条件的所有节点
 * @param {Array} tree
 * @param {Function} func 回调函数，接收node参数
 * @param {Object} config 使用到的配置项：children
 * @return {Array} 删除符合条件(callback(node)为true)的所有节点及其子节点
 * @example removeNode(tree, func[, config])
 */
export const removeNode = (tree, func, config = {}) => {
  const { children } = getConfig(config)
  const list = [tree]
  while (list.length) {
    const nodeList = list.shift()

    const delList = nodeList.reduce((r, n, idx) => (func(n) && r.push(idx), r), [])
    delList.reverse()
    delList.forEach((idx) => nodeList.splice(idx, 1))
    const childrenList = nodeList.map((n) => n[children]).filter((l) => l && l.length)
    list.push(...childrenList)
  }
}

const handlers = {
  toTree,
  toList,
  findNode,
  findNodeAll,
  findPath,
  findPathAll,
  filter,
  forEach,
  insertBefore,
  insertAfter,
  removeNode,
}

/**
 * @description: 创建闭包了配置项config的实例
 * 为了避免每个函数都传入config参数，可使用该API创建一个实例，以上所有API可以当成实例方法使用
 * @param {Object} config
 * @return {Object}
 * @example createInstance(config)
 */
export const createInstance = (config) => {
  const obj = {}
  for (const key in handlers) {
    const func = handlers[key]
    obj[key] = (...args) => func(...args, config)
  }
  return obj
}
