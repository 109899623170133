import PropTypes from 'ant-design-vue/es/_util/vue-types'
import { Component, Vue } from 'vue-property-decorator'
import './style.scss'

const InputDeptSearchProps = {
  userId: PropTypes.string,
  deptId: PropTypes.string,
  data: PropTypes.func.isRequired
}

@Component({ props: InputDeptSearchProps })
export default class InputDeptSearch extends Vue {
  static props = InputDeptSearchProps

  inputValue = ''

  deptSearchData = {}

  loading = false
  _visible = false
  get visible() {
    return this.inputValue !== '' && this.$data._visible
  }
  set visible(value) {
    this.$data._visible = value
  }

  clear(force) {
    // 清空搜索条件
    if (force) {
      this.emitSearch({ userId: null })
    } else {
      this.$vnode.context.searchParams.userId = null
    }
    this._dirty = false
  }

  beforeCreate() {
    const handleSearch = async (name) => {
      try {
        if (this.inputValue) {
          this.loading = true
          this.deptSearchData = await this.data({ name })
        }
      } finally {
        this.loading = false
      }
    }
    this.handleSearch = _.debounce(handleSearch, 500)
  }

  emitSearch(payload) {
    const { userId, deptId } = this
    this._dirty = true
    this.$emit('search', { userId, deptId, ...payload })
  }

  render() {
    const { visible, loading, deptSearchData } = this

    return (
      <ADropdown
        visible={visible}
        overlay={
          loading || (_.isEmpty(deptSearchData.depts) && _.isEmpty(deptSearchData.users)) ? (
            <ACard bordered={false}>
              <ASpin spinning={loading}>
                <AEmpty description="无匹配数据" />
              </ASpin>
            </ACard>
          ) : (
            <AMenu>
              {!_.isEmpty(deptSearchData.depts) && (
                <AMenuItemGroup title="部门" class="menu-item">
                  {_.map(deptSearchData.depts, (dept, i) => (
                    <AMenuItem
                      key={i}
                      onClick={() => {
                        this.emitSearch(dept)
                      }}
                    >
                      {(dept.ancestorsName && `${dept.ancestorsName.replace(/,/g, '/')}/`) + dept.deptName}
                    </AMenuItem>
                  ))}
                </AMenuItemGroup>
              )}
              <AMenuDivider />
              {!_.isEmpty(deptSearchData.users) && (
                <AMenuItemGroup title="用户" class="menu-item">
                  {_.map(deptSearchData.users, (user, i) => (
                    <AMenuItem
                      key={i}
                      onClick={() => {
                        this.emitSearch(user)
                      }}
                    >
                      {(user.deptName && `${user.deptName}/`) + user.userName}
                    </AMenuItem>
                  ))}
                </AMenuItemGroup>
              )}
            </AMenu>
          )
        }
      >
        <div class="flex flex-align-center">
          <AInputSearch
            vModel_trim={this.inputValue}
            allowClear
            placeholder="搜索部门和员工"
            onChange={(e) => {
              if (e.target.value) {
                this.handleSearch(e.target.value)
              } else if (this._dirty) {
                this.clear(true)
              }
            }}
            onFocus={() => {
              this.visible = true
            }}
            onBlur={() => {
              // fix: MenuItem click event handler 不会被触发 当 Dropdown closed (chrome)
              _.delay(() => {
                this.visible = false
              }, 300)
            }}
          />
        </div>
      </ADropdown>
    )
  }
}
