/*
 * @Description: 报表模板的基础数据结构
 * 注：在业务代码中也会使用到下面的常量和方法，请勿删除或重命名
 */
import { TYPE_COLOR_MAP } from '../CostItemTableTree/useCostItem'

// 根节点id
export const ROOT_ID = '0'

// 科目类型字典
export const TYPE_MAP = {
  classification: '科目分类',
  // 科目类型type，Default：原子科目，CustomFormula：复合科目，Stat：统计科目
  costItemDefault: '原子科目', // 对应“科目-科目管理”中的“原子科目”
  costItemCustomFormula: '复合科目', // 对应“科目-科目管理”中的“复合科目”
  costItemBizCalculate: '二次计算科目', // 对应“科目-科目管理”中的“二次计算科目”
  costItemStat: '复合科目', // 对应“科目-科目管理”中的“统计科目”
  formula: '自定义公式'
}
// 统计方式字典
export const STAT_TYPE_MAP = {
  api: '调用API获取',
  summary: '由直属下级汇总',
  formula: '根据自定义公式计算'
}

// 默认的节点结构
export const DEFAULT_TPL_CONFIG = {
  id: '',
  name: '',
  type: 'classification', // 类型，classification（科目分类）、costItemDefault（费用项-原子科目）、costItemCustomFormula（费用项-复合科目）、costItemBizCalculate（费用项-二次计算科目）、costItemStat（费用项-统计科目）、formula（自定义公式）
  statType: 'summary', // 统计方式，api（调用API获取）、summary（汇总，即数据来自直属下级汇总）、formula（自定义公式）
  formulaCode: '', // 计算公式编码，示例：#{科目ID}×0.05
  formulaName: '', // 计算公式名称，示例：#{销售额}×0.05
  formulaRules: [], // 计算公式规则数组
  show: true, // 在查看报表时是否显示
  level: 1, // 当前所属层级
  pid: ROOT_ID, // 父级ID
  style: {}, // 自定义单元格样式，如 { backgroundColor: '#fff', fontWeight: 'normal' }
  percent: 0, // 是否显示为百分比（该参数仅在type=formula或statType=formula时生效，0-否，1-是）。显示为百分比时，将会把计算公式得到的结果乘以100之后进行显示。如公式得到的结果为“0.25”，则最终显示为“25%”。
  children: undefined // statType为summary时，该值为数组，用于储存子科目
}

// 公式对外展示的名称转换   #{销售额}-#{水电费}*1.5%  ===>  【销售额】 - 【水电费】 * 1.5%
export const transformFormulaName = (formulaName = '') => {
  return formulaName.replace(/\#\{/g, '【').replace(/\}/g, '】').replace(/\+/g, ' + ').replace(/\-/g, ' - ').replace(/\*/g, ' * ').replace(/\//g, ' / ')
}

// 获取科目类型
export const getSubjectType = (type, statType) => {
  const subjectTypeMap = {
    costItemDefault: '原子科目',
    costItemCustomFormula: '复合科目',
    costItemBizCalculate: '二次计算科目',
    costItemStat: '统计科目',
    formula: '自定义公式',
    classificationSummary: '科目分类 (汇总)',
    classificationFormula: '科目分类 (自定义公式)'
  }
  const subjectTypeColorMap = {
    costItemDefault: TYPE_COLOR_MAP.Default,
    costItemCustomFormula: TYPE_COLOR_MAP.CustomFormula,
    costItemBizCalculate: TYPE_COLOR_MAP.BizCalculate,
    costItemStat: TYPE_COLOR_MAP.Stat,
    formula: 'm-type-error',
    classificationSummary: 'm-text-color',
    classificationFormula: 'm-type-error'
  }
  let subjectType = type
  if (type === 'classification' && statType !== 'api') {
    subjectType = statType === 'summary' ? 'classificationSummary' : 'classificationFormula'
  }
  return { value: subjectType, text: subjectTypeMap[subjectType], color: subjectTypeColorMap[subjectType] }
}
