// 自动导入svg图标
const iconsMap = {}
const requireContent = require.context('./icons', true, /\.svg$/)
requireContent.keys().forEach((key) => {
  const contentName = key.replace(/^\.\//, '').replace(/\.\w+$/, '')
  const content = require(`./icons/${contentName}.svg?inline`)
  iconsMap[contentName] = content.__esModule && content.default ? content.default : content
})

module.exports = iconsMap
