/*
 * @Description: 日期相关的方法
 * @Author: yitong
 * @Date: 2021-10-14 10:02:20
 * @LastEditors: 成一通
 * @LastEditTime: 2022-10-18 11:15:08
 */
import { isDate } from './test'
import moment from 'moment'

/**
 * 给时间自动补0
 * @param {Number} i 时间
 */
export const checkTime = (i) => {
  i = parseInt(i)
  return i >= 10 ? i : i <= 0 ? '00' : `0${i}`
}
/**
 * 获取某个时间格式的时间戳,由于ios不支持2021-10-14格式的时间，所以需要把时间转换成2021/10/14的格式，否则会报 Invalid Date 错误
 * @param  {String|Number} stringTime 任何合法的时间格式、秒或毫秒的时间戳 有效值如：2021-10-14、2021-10-14 10:13:13、2021/10/14 9:10、2021/10/14 09:10、2021/10-14 9:10、Thu Oct 14 2021 10:30:05 GMT+0800 (GMT+08:00)。年月日之间可以用"/"或者"-"分隔(不能用中文分隔)，时分秒之间用":"分隔，不符合规则的直接返回当前时间。
 * @return {Number} 时间戳，单位为ms，共13位
 */
export const stringTimeToTimestamp = (stringTime = null) => {
  // 如果为null,则格式化当前时间戳，单位：毫秒
  if (!stringTime) return Number(new Date())
  // 判断是否包括“/”或者“-”，如 2021-10-14 或者 2021/10/14
  const isStrFormat = /\/|-/.test(stringTime)
  // 如果是字符串格式的时间戳需要转换为数字格式
  if (!isDate(isStrFormat ? stringTime : Number(stringTime))) {
    throw new Error(`[${stringTime}]为无效的时间格式`)
  }
  // 字符串格式
  if (isStrFormat) return Date.parse(new Date(stringTime.replace(/\-/g, '/')))
  // 时间戳或者Date类型，转为毫秒
  stringTime = Number(new Date(Number(stringTime)))
  if (stringTime.toString().length === 10) stringTime *= 1000
  return stringTime
}
/**
 * 格式化时间
 * 该函数必须传入第一个参数，第二个参数是可选的，函数返回一个格式化好的时间。
 * @param {String} time 任何合法的时间格式、秒或毫秒的时间戳
 * @param {String} format 时间格式，可选。默认为yyyy-mm-dd，年为"yyyy"，月为"mm"，日为"dd"，时为"hh"，分为"MM"，秒为"ss"，格式可以自由搭配，如： yyyy:mm:dd，yyyy-mm-dd，yyyy年mm月dd日，yyyy年mm月dd日 hh时MM分ss秒，yyyy/mm/dd/，MM:ss等组合
 * @returns
 */
export const timeFormat = (time = null, format = 'yyyy-mm-dd') => {
  if (!time) return null
  const date = new Date(stringTimeToTimestamp(time))
  let ret
  const opt = {
    'y+': date.getFullYear().toString(), // 年
    'm+': (date.getMonth() + 1).toString(), // 月
    'd+': date.getDate().toString(), // 日
    'h+': date.getHours().toString(), // 时
    'M+': date.getMinutes().toString(), // 分
    's+': date.getSeconds().toString() // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  }
  for (const k in opt) {
    ret = new RegExp(`(${k})`).exec(format)
    if (ret) {
      format = format.replace(
        ret[1],
        ret[1].length === 1 ? opt[k] : opt[k].padStart(ret[1].length, '0')
      )
    }
  }
  return format
}
/**
 * 多久以前
 * @param {String} time 任何合法的时间格式、秒或毫秒的时间戳
 * @param {String | false} format 时间格式，默认为yyyy-mm-dd，年为"yyyy"，月为"mm"，日为"dd"，时为"hh"，分为"MM"，秒为"ss"，格式可以自由搭配，如： yyyy:mm:dd，yyyy-mm-dd，yyyy年mm月dd日，yyyy年mm月dd日 hh时MM分ss秒，yyyy/mm/dd/，MM:ss等组合。 如果时间戳距离此时的时间，大于一个月，则返回一个格式化好的时间，如果此参数为false，返回均为"多久之前"的结果。
 */
export const timeFrom = (time = null, format = 'yyyy-mm-dd') => {
  const timestamp = stringTimeToTimestamp(time)
  const timer = (Number(new Date()) - timestamp) / 1000
  // 如果小于5分钟,则返回"刚刚",其他以此类推
  let tips = ''
  switch (true) {
    case timer < 300:
      tips = '刚刚'
      break
    case timer >= 300 && timer < 3600:
      tips = `${parseInt(timer / 60)}分钟前`
      break
    case timer >= 3600 && timer < 86400:
      tips = `${parseInt(timer / 3600)}小时前`
      break
    case timer >= 86400 && timer < 2592000:
      tips = `${parseInt(timer / 86400)}天前`
      break
    default:
      // 如果format为false，则无论什么时间戳，都显示xx之前
      if (format === false) {
        if (timer >= 2592000 && timer < 365 * 86400) {
          tips = `${parseInt(timer / (86400 * 30))}个月前`
        } else {
          tips = `${parseInt(timer / (86400 * 365))}年前`
        }
      } else {
        tips = timeFormat(timestamp, format)
      }
  }
  return tips
}
/**
 * 查找日期位于一年中的第几天
 * @param {Date,String,Number} time 任何合法的时间格式、秒或毫秒的时间戳
 * @returns {Number}
 */
export const dayOfYear = (time) => {
  const date = new Date(stringTimeToTimestamp(time))
  return Math.floor((date - new Date(date.getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24)
}
/**
 * 计算2个日期之间相差多少天
 * @param {Date,String,Number} date1 任何合法的时间格式、秒或毫秒的时间戳
 * @param {Date,String,Number} date2 任何合法的时间格式、秒或毫秒的时间戳
 * @returns {Number}
 */
export const dayDif = (date1 = null, date2 = null) => {
  return Math.ceil(Math.abs(stringTimeToTimestamp(date1) - stringTimeToTimestamp(date2))) / 86400000
}
/**
 * @description 时间比较
 * @param {String} t1 任何合法的时间格式、秒或毫秒的时间戳
 * @param {String} t2 任何合法的时间格式、秒或毫秒的时间戳
 * @return {Number} 0:相等；1:v1>v2；-1:v1<v2
 */
export const compareTime = (t1, t2) => {
  const reduce = stringTimeToTimestamp(t1) - stringTimeToTimestamp(t2)
  return reduce === 0 ? 0 : reduce > 0 ? 1 : -1
}
/**
 * 判断某个时间戳是不是在今天内（00:00:00 ~ 23:59:59）
 * @param {String} time 任何合法的时间格式、秒或毫秒的时间戳
 * @returns {Boolean}
 */
export const isToday = (time) => {
  if (!time) return false

  const today = new Date(new Date()).toLocaleDateString() // 2021/10/18
  const beginTime = stringTimeToTimestamp(`${today} 00:00:00`) // 当天开始时间戳
  const endTime = stringTimeToTimestamp(`${today} 23:59:59`) // 当天结束时间戳

  const timestamp = stringTimeToTimestamp(time)
  return timestamp >= beginTime && timestamp <= endTime
}
/**
 * @description 计算现在距离结束时间剩余时间，可用于倒计时计算
 * @property {String | Number} timestamp 结束时间(时间戳1601363958，单位毫秒或秒都可以，最终会转换成秒来计算。或字符串格式2020-08-08 08:08:08)
 * @property {Boolean} showDay 是否显示天数
 * @returns {Object} {leftTime, days, hours, totalHours, minutes, seconds, value}
 */
export const calcLeftTime = (timestamp, showDay = false) => {
  timestamp = stringTimeToTimestamp(timestamp)

  const now = Number(new Date())
  const leftTime = (timestamp - now) / 1000 // 转为秒来计算
  const days = parseInt(leftTime / 60 / 60 / 24, 10) // 计算剩余的天数
  const hours = parseInt((leftTime / 60 / 60) % 24, 10) // 计算剩余的小时
  const minutes = parseInt((leftTime / 60) % 60, 10) // 计算剩余的分钟
  const seconds = parseInt(leftTime % 60, 10) // 计算剩余的秒数

  const _totalHours = checkTime(days * 24 + hours)
  const _days = checkTime(days)
  const _hours = checkTime(hours)
  const _minutes = checkTime(minutes)
  const _seconds = checkTime(seconds)

  let str = ''
  if (days > 0) {
    str = showDay
      ? `${_days}:${_hours}:${_minutes}:${_seconds}`
      : `${_totalHours}:${_minutes}:${_seconds}`
  } else if (hours > 0) {
    str = `${_hours}:${_minutes}:${_seconds}`
  } else {
    str = `${_minutes}:${_seconds}`
  }

  return {
    leftTime: leftTime <= 0 ? 0 : leftTime,
    days: _days,
    hours: _hours,
    totalHours: _totalHours,
    minutes: _minutes,
    seconds: _seconds,
    value: str
  }
}

/**
 * @description 格式化${timestamp}距现在的已过时间 把timestamp转换为更为直观的日期格式，类似朋友圈中的日期，如`今天 09:10`、`昨天 09:10`
 * @property {String, Number} timestamp 开始时间(时间戳1601363958，单位毫秒或秒都可以，最终会转换成秒来计算。或字符串格式2020-08-08 08:08:08)
 * @return {String} `今天 09:10`、`昨天 09:10`、今年返回`5月8日 09:10`、非今年返回`2021.05.08 09:10`
 */
export const formatPassTime = (timestamp) => {
  if (!timestamp) return ''
  // 如果timestamp中间包含“-”，则当作字符串格式处理，转换为10位时间戳
  if (timestamp.toString().indexOf('-') > -1) {
    timestamp = stringTimeToTimestamp(timestamp)
  }
  const num = timestamp.toString().length === 13 ? 1 : 1000
  const date = new Date(timestamp * num) // timestamp所对应的日期
  const Year = date.getFullYear()
  const Month = date.getMonth() + 1
  const Days = date.getDate()
  const Hours = date.getHours()
  const Minutes = date.getMinutes()
  // 当前时间
  const now = new Date()
  const curYear = now.getFullYear()
  const curMonth = now.getMonth() + 1
  const curDays = now.getDate()

  const hm = `${checkTime(Hours)}:${checkTime(Minutes)}`

  // 今天
  if (isToday(timestamp)) return `今天 ${hm}`
  // 昨天
  if (curYear === Year && curMonth === Month && curDays === Days + 1) return `昨天 ${hm}`
  // 同一年
  if (curYear === Year) return `${Month}月${Days}日 ${hm}`
  // 不同年
  return `${Year}.${checkTime(Month)}.${checkTime(Days)} ${hm}`
}

/**
 * @description: 获取两个日期之间包含多少秒/分钟/日/月/年(包括开始和结束日期) (since v0.1.4)
 * @param {array<string | date>} rangeDate [开始,结束] 时间段，开始日期~结束日期 YYYY-MM-DD(YYYY-MM) 或 date格式
 * @param {string} type second-秒 minute-分钟 day-日 month-月 year-年
 * @return {number} 返回秒/分钟/日/月/年数
 */
export const getDiffTimes = (rangeDate, type = 'day') => {
  if (!Array.isArray(rangeDate)) {
    console.warn('参数需要为数组')
    return 0
  }
  const value = moment(rangeDate[1]).endOf(type).diff(moment(rangeDate[0]), type) + 1
  return Math.abs(value)
}

/**
 * @description: 获取两个日期之间包括的所有日期 (since v0.1.4)
 * @param {array<string | date>} rangeDate 时间段，开始日期~结束日期 YYYY-MM-DD 或 date格式
 * @return {array} 返回日期数组，包括开始和结束日期 ['2022-07-01','2022-07-02','2022-07-03']
 */
export const getRangeDays = (rangeDate) => {
  if (!Array.isArray(rangeDate)) {
    console.warn(`参数需要为数组，当前入参为：${rangeDate}`)
    return []
  }
  if (rangeDate.length === 1) return rangeDate

  const arr = []
  const begin = moment(rangeDate[0])
  const end = moment(rangeDate[1])

  // eslint-disable-next-line no-unmodified-loop-condition
  while (end >= begin) {
    arr.push(begin.format('YYYY-MM-DD'))
    begin.add(1, 'day')
  }

  return arr
}

/**
 * @description: 获取两个月份之间包括的所有月份 (since v0.1.6)
 * @param {array<string | date>} rangeMonth 月份段，开始月份~结束月份 YYYY-MM 或 date格式，如：['2022-01','2022-05']
 * @return {array} 返回月份数组，包括开始和结束月份 ['2022-01','2022-02','2022-03','2022-04','2022-05']
 */
export const getRangeMonths = (rangeMonth) => {
  if (!Array.isArray(rangeMonth)) {
    console.warn(`参数需要为数组，当前入参为：${rangeMonth}`)
    return []
  }
  if (rangeMonth.length === 1) return rangeMonth

  const arr = []
  const begin = moment(rangeMonth[0])
  const end = moment(rangeMonth[1])

  // eslint-disable-next-line no-unmodified-loop-condition
  while (end >= begin) {
    arr.push(begin.format('YYYY-MM'))
    begin.add(1, 'month')
  }

  return arr
}

/**
 * @description: 获取某个月份或年份的第一天和最后一天 (since v0.1.4)
 * @param {string | date} date 月份，如“2022-08”，或年份，如“2022”
 * @param {string} type month-月 year-年
 * @return {array} [第一天, 最后一天]
 */
export const getRangeDate = (date, type = 'month') => {
  // .toString() 预防外部输入的年份为数字，导致获取1970年的日期
  if (typeof date === 'number') date = date.toString()
  const beginDate = moment(date).startOf(type).format('YYYY-MM-DD')
  const endDate = moment(date).endOf(type).format('YYYY-MM-DD')
  return [beginDate, endDate]
}

/**
 * @description: date格式时间转为字符串格式 (since v0.1.4)
 * @param {array<date>} date
 * @param {string} format 默认 YYYY-MM-DD
 * @return {array}
 */
export const dateToString = (date, format = 'YYYY-MM-DD') => {
  if (Array.isArray(date) && date.length === 2) {
    return [moment(date[0]).format(format), moment(date[1]).format(format)]
  }
  return moment(date).format(format)
}
