import NProgress from 'nprogress'
import axios from 'axios/dist/axios' // FIXME 直接使用import axios from 'axios'打包报错
const { cloneDeep, merge } = require('lodash')

class Http {
  // 设置全局默认配置
  setConfig(customConfig) {
    // 深度合并对象，否则会造成对象深层属性丢失
    this.config = merge(this.config, customConfig)
  }

  // 主要请求部分
  request(url = '', params = {}, options = {}) {
    options = merge(this.config, options)
    options.method = options.method || this.config.method
    options.interceptor = options.interceptor || {}

    this.instance = axios.create(options)
    const CancelToken = axios.CancelToken
    this.source = CancelToken.source()

    const { reqInterceptor, reqInterceptorCatch, resInterceptor, resInterceptorCatch } =
      options.interceptor

    // 请求拦截器
    this.instance.interceptors.request.use(
      (config) => {
        if (reqInterceptor && typeof reqInterceptor === 'function') {
          // 如果拦截器返回false，意味着拦截器定义者需要取消该请求
          console.log(reqInterceptor(config) === false)
          if (reqInterceptor(config) === false) {
            // cancel the request (the message parameter is optional)
            this.source.cancel('Operation canceled by the user.')
          } else {
            return reqInterceptor(config)
          }
        }
        return config
      },
      (error) => {
        // error = { response: { data: {}, status: 200, statusText: 'OK', headers: {} }, request: {}, message:'', config: {} }
        // 判断是否存在拦截器
        if (reqInterceptorCatch && typeof reqInterceptorCatch === 'function') {
          return reqInterceptorCatch(error)
        }
        return Promise.reject(error)
      }
    )

    // 响应拦截器
    this.instance.interceptors.response.use(
      (response) => {
        // response = { config: {}, data: {} // 服务端接口返回的数据, headers: {}, request: {} ,status: 200, statusText: "OK" }

        // 判断用户对拦截返回数据的要求，如果originalData为true，返回所有的数据(response)到拦截器，否则只返回response.data
        const originalData =
          options.originalData !== undefined ? options.originalData : this.config.originalData

        const responseFn = (result) => {
          // 判断是否存在拦截器
          if (resInterceptor && typeof resInterceptor === 'function') {
            const resInterceptors = resInterceptor(result)
            // 如果拦截器不返回false，就将拦截器返回的内容给this.$m.post的then回调
            if (resInterceptors !== false) {
              return Promise.resolve(resInterceptors)
            }
            // 如果拦截器返回false，意味着拦截器定义者认为返回有问题，直接接入catch回调
            return Promise.reject(result)
          }
          // 没有拦截器
          return Promise.resolve(result)
        }

        if (originalData) return responseFn(response)
        if (response.status === 200) return responseFn(response.data)
        // 不返回原始数据的情况下，服务器状态码不为200，返回最原始的数据
        return Promise.reject(response)
      },
      (error) => {
        // error = { response: {data:{}, status:200, headers:{}},request:{}, message:'',config:{} }
        if (resInterceptorCatch && typeof resInterceptorCatch === 'function') {
          return resInterceptorCatch(error)
        }
        return Promise.reject(error)
      }
    )

    // 改写 get 请求：axios.get(url[, config]) => axios.get(url[, params[, config]])
    this.instance.get = (() => {
      const { get } = this.instance
      // eslint-disable-next-line no-shadow
      return (url, params, config) =>
        get(url, { params, ...config, cancelToken: this.source.token })
    })()

    // 改写 delete：请求 axios.delete(url[, config]) => axios.delete(url[, data[, config]])
    const _instance = cloneDeep(this.instance) // 使用深拷贝，否则会导致死循环
    // eslint-disable-next-line no-shadow
    this.instance.delete = (url, data, config) =>
      _instance.delete(url, { data, ...config, cancelToken: this.source.token })

    // post 请求添加取消请求source
    // eslint-disable-next-line no-shadow
    this.instance.post = (url, data, config) =>
      _instance.post(url, data, { ...config, cancelToken: this.source.token })

    // put 请求添加取消请求source
    // eslint-disable-next-line no-shadow
    this.instance.put = (url, data, config) =>
      _instance.put(url, data, { ...config, cancelToken: this.source.token })

    // 是否显示loading
    // 加一个是否已有timer定时器的判断，否则有两个同时请求的时候，后者会清除前者的定时器id
    // 而没有清除前者的定时器，导致前者超时，一直显示loading
    if (options.showLoading && !options.timer) {
      options.timer = setTimeout(() => {
        NProgress.start() // start progress bar
        options.timer = null
      }, options.loadingTime)
    }
    // 发起请求
    return this.instance[options.method.toLowerCase()](url, params, options).finally(() => {
      NProgress.done()
      clearTimeout(options.timer)
      options.timer = null
    })
  }

  constructor() {
    this.config = {
      // 自定义属性
      originalData: false, // 是否在拦截器中返回服务端的原始数据
      showLoading: true, // 是否显示请求中的loading
      loadingMask: true, // 展示loading的时候，是否给一个透明的蒙层，防止触摸穿透
      loadingText: '请求中...',
      loadingTime: 800, // 在此时间内，请求还没回来的话，就显示加载中动画，单位ms
      timer: null, // 定时器
      // 拦截器 { reqInterceptor:(config)=>{return config}, reqInterceptorCatch:(err)=>{return Promise.reject(err)},
      // resInterceptor:(response)=>{return Promise.resolve(response)}, resInterceptorCatch:(err)=>{return Promise.reject(err)} }
      interceptor: {},
      // 以下为 axios config 的部分属性
      baseURL: '', // `baseURL` will be prepended to `url` unless `url` is absolute.
      headers: { 'content-type': 'application/json;charset=UTF-8' }, // 默认的请求头
      method: 'GET',
      // `timeout` specifies the number of milliseconds before the request times out.
      // If the request takes longer than `timeout`, the request will be aborted.
      timeout: 0, // default is `0` (no timeout)
      responseType: 'json', // 服务端返回的数据格式，默认为 json。如果需要返回文件流，如下载excel文件时设置为 blob
      withCredentials: false // `withCredentials` indicates whether or not cross-site Access-Control requests
    }

    // 配置 get、post、put、delete 请求
    ;['GET', 'POST', 'PUT', 'DELETE'].forEach((item) => {
      this[item.toLowerCase()] = (url, params = {}, config) => {
        return this.request(url, params, {
          method: item,
          ...config
        })
      }
    })
  }
}

export default new Http()
