/**
 * 全站路由配置
 *
 * meta参数说明
 * keepAlive是否缓冲页面
 * isTab是否加入到tag导航
 * isAuth是否需要授权
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'

// hack router push callback
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch((err) => err)
}
Vue.use(VueRouter)
// 创建路由
export const createRouter = () => {
  const router = new VueRouter({
    mode: 'history',
    // https://router.vuejs.org/zh/guide/advanced/scroll-behavior.html#%E5%BC%82%E6%AD%A5%E6%BB%9A%E5%8A%A8
    // 这个方法 是控制滚动条
    // 如果 return falsy || {} ,则不发生滚动
    scrollBehavior(to, from, savedPosition) {
      // savedPosition 这个参数当且仅当导航 (通过浏览器的 前进/后退 按钮触发) 时才可用  效果和 router.go() 或 router.back()
      if (savedPosition) {
        // 返回savedPosition 其实就是 当用户点击 返回的话，保持之前游览的高度
        return savedPosition
      }
      if (from.meta.keepAlive) {
        from.meta.savedPosition = document.body.scrollTop
      }
      return {
        x: 0,
        y: to.meta.savedPosition || 0,
      }
    },
    routes,
  })
  return router
}
const router = createRouter() // 获得 route 实例

export function resetRouter() {
  // 重置路由 比如用于身份验证失败，需要重新登录时 先清空当前的路有权限
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
