/**
 * 注册全局插件
 * @param {*} Vue
 * @param {*} options
 */

// 用于减少无用的容器节点
// 如：<div><span v-if="status==1">正常</span><span v-else>异常</span></div>
// <fragment>这里是具体内容</fragment>
import Fragment from 'vue-fragment'

export default function install(Vue, options) {
  if (install.installed) {
    return
  }

  Vue.use(Fragment.Plugin)

  require
    .context('@/plugins', true, /\.js$/)
    .keys()
    // ignore current file
    .filter((key) => !key.endsWith('./index.js'))
    .forEach(async (key) => {
      const { default: plugin } = require(`@/plugins${key.substring(1)}`)
      Vue.use(plugin, options)
    })
}
