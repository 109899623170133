let flag
/**
 * 节流原理：在一定时间内，只能触发一次
 * 比如我们设定500ms，在这个时间内，无论点击按钮多少次，它都只会触发一次。具体场景可以是抢购时候，由于有无数人 快速点击按钮，如果每次点击都发送请求，就会给服务器造成巨大的压力，但是我们进行节流后，就会大大减少请求的次数。
 *
 * @param {Function} func 触发回调执行的函数
 * @param {Number} wait 时间间隔，单位ms，默认500ms
 * @param {Boolean} immediate 在开始还是结束处触发，比如设置wait为1000ms，如果在一秒内进行了5次操作，只触发一次，如果immediate为true，那么就会在第一次操作 触发回调，如果为false，就会在第5次操作触发回调。
 * @return undefined
 */
export const throttle = (func, wait = 500, immediate = true) => {
  if (immediate) {
    if (!flag) {
      flag = true
      // 如果是立即执行，则在wait毫秒内开始时执行
      typeof func === 'function' && func()
      setTimeout(() => {
        flag = false
      }, wait)
    }
  } else if (!flag) {
    flag = true
    // 如果是非立即执行，则在wait毫秒内的结束处执行
    setTimeout(() => {
      flag = false
      typeof func === 'function' && func()
    }, wait)
  }
}
