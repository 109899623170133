import axios from '@/utils/axios'

/**
 * 用户自定义表头设置
 * @param {object} params
 * @param {string} params.code 表头编码
 * @param {string} params.titleValue 表头内容
 * @param {string} params.type 设置类型： 0 默认设置，1 用户设置
 */
export const postConfigUpdate = (params) => axios.post('/v1/title/config/update', params)

/**
 * 用户自定义表头设置查询
 * @param {object} params
 * @param {string} params.code 表头编码
 * @returns {Promise<{
 *   id: string
 *   code: string
 *   titleValue: string
 *   type: string
 * }>} ** 返回体 **
 *   - id: 设置ID
 *   - code: 表头编码
 *   - titleValue: 表头设置内容
 *   - type: 表头类型： 0 默认设置, 1 用户设置
 */
export const getConfigOne = (params) => axios.get('/v1/title/config/one', { params })
