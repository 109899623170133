<template>
  <BasisModal v-model="visible" size="large" @ok="handleOk">
    <template #title>
      <div class="m-flex">
        {{ title2 || title }}
        <div class="m-m-l-16 m-tips-color m-fw-normal">
          已选择(<span class="m-type-primary">{{ selectedUserIds && selectedUserIds.length }}</span
          >)人
          <a-tooltip placement="rightTop" :title="selectedUser">
            <a v-if="selectedUser" class="m-type-primary">明细</a>
          </a-tooltip>
        </div>
      </div>
    </template>

    <a-layout style="height: calc(70vh - 64px)">
      <a-layout-sider>
        <a-input-search
          v-model.trim="keyword"
          allow-clear
          placeholder="输入部门名称搜索"
          @change="debouncedSearch"
        />
        <div class="tree-box">
          <a-tree
            :replace-fields="replaceFields"
            :expanded-keys="expandedKeys"
            :auto-expand-parent="autoExpandParent"
            :tree-data="treeData"
            :selected-keys="selectedKeys"
            :default-selected-keys="defaultValue"
            @select="handleSelectTree"
            @expand="onExpand"
          >
            <template #title="data">
              <span
                v-if="data[replaceFields.title].indexOf(keyword) > -1"
                :title="data[replaceFields.title]"
                class="m-flex"
              >
                {{
                  data[replaceFields.title].substr(0, data[replaceFields.title].indexOf(keyword))
                }}
                <span style="background-color: #ff9632">{{ keyword }}</span>
                {{
                  data[replaceFields.title].substr(
                    data[replaceFields.title].indexOf(keyword) + keyword.length,
                  )
                }}
              </span>
              <span v-else :title="data[replaceFields.title]">{{ data[replaceFields.title] }}</span>
            </template>
          </a-tree>
        </div>
      </a-layout-sider>
      <a-layout-content class="m-m-l-16">
        <BasisTableLayout
          ref="basisTableLayout"
          :default-selected-row-keys="defaultSelectedUserIds"
          :default-selected-rows="defaultSelectedUsers"
          :immediate="!roleCode"
          ellipsis
          :selectable="true"
          :scroll="{ x: 'auto', y: 'calc(70vh - 185px)' }"
          :method="getUserPage"
          :params="tableParams"
          :columns="columns"
          :row-selection-fixed="true"
          :transform-data-source="transformDataSource"
          row-key="userId"
          :row-selection="{
            type: multiple ? 'checkbox' : 'radio',
          }"
          @rowSelectionChange="handleRowSelectionChange"
        >
          <template #layoutHeader>
            <div class="m-flex m-flex-1">
              <a-form-model layout="inline">
                <a-form-model-item label="关键词">
                  <a-input
                    v-model.trim="tableParams.keyWord"
                    allow-clear
                    style="width: 180px"
                    placeholder="用户姓名、手机号、工号，支持模糊搜索"
                    @keyup.enter="reload"
                  />
                </a-form-model-item>
                <!-- <a-form-model-item label="角色">
                  <a-select
                    v-model="tableParams.roleId"
                    :loading="isRoleInfoLoading"
                    :options="
                      _.map(roleInfo, (role) => ({
                        label: role.name,
                        value: role.id
                      }))
                    "
                    placeholder="请选择角色"
                    style="width: 180px"
                  />
                </a-form-model-item> -->

                <!-- <a-form-model-item label="手机号码">
                  <a-input v-model.trim="tableParams.mobile" allow-clear style="width: 180px" placeholder="手机号码，支持模糊搜索" @keyup.enter="reload"></a-input>
                </a-form-model-item> -->
                <!-- <a-form-model-item label="工号">
                  <a-input v-model.trim="tableParams.jobNumber" allow-clear style="width: 180px" placeholder="工号，支持模糊搜索" @keyup.enter="reload"></a-input>
                </a-form-model-item> -->
                <!-- <a-form-model-item label="职位">
                  <a-input v-model.trim="tableParams.title" allow-clear style="width: 180px" placeholder="职位，支持模糊搜索" @keyup.enter="reload"></a-input>
                </a-form-model-item> -->
                <a-form-model-item>
                  <a-button type="primary" @click="reload">查询</a-button>
                  <a-button @click="reset">重置</a-button>
                </a-form-model-item>
              </a-form-model>
            </div>
          </template>

          <template #name="{ record }">
            <a-popover :title="record.name" placement="right" trigger="hover">
              <template #content>
                <div class="m-flex m-row-center">
                  <a-avatar
                    shape="square"
                    style="flex-shrink: 0; background-color: #2483ff"
                    :size="128"
                    :src="record.avatar"
                  >
                    {{ record.name.slice(-2) }}
                  </a-avatar>
                </div>
              </template>
              <div class="m-flex">
                <a-avatar
                  shape="square"
                  style="flex-shrink: 0; background-color: #2483ff"
                  size="small"
                  :src="record.avatar"
                >
                  {{ record.name.slice(-2) }}
                </a-avatar>
                <span class="m-m-l-4 m-line-1" :title="record.name">{{ record.name }}</span>
              </div>
            </a-popover>
          </template>

          <template #mobile="{ record }">
            <!-- <span :title="record.mobile + '_点击可复制'" @click="copymobile(record.mobile)">{{ $m.dataDesensitization(record.mobile) }}</span> -->
            <span
              :title="record.mobile + '_点击可复制'"
              @click="$m.copyText(record.mobile) && $message.success('复制成功')"
            >
              {{ dataDesensitization(record.mobile, 3, 3) }}
            </span>
          </template>
        </BasisTableLayout>
      </a-layout-content>
    </a-layout>
  </BasisModal>
</template>

<script>
import _ from 'lodash'
import * as leadsApi from '@/api/crm/leads'
import { getUserPage } from '@/api/crm/sale'
import { getRoleInfo } from '@/api/crm/role'
import { getUserList } from '@/api/crm/user'
import { getDepartmentTree as getDeptTree } from '@/api/crm/department'
import { mapGetters } from 'vuex'

export default {
  name: 'UserSelectModal', // 选择员工模态框组件，该组件一般用于通过弹框形式从组织架构中选择员工
  components: {},
  props: {
    title: {
      type: String,
      default: '选择员工',
    },
    // 是否必须选择至少一个员工
    required: {
      type: Boolean,
      default: false,
    },
    // 提示语
    desc: {
      type: String,
      default: undefined,
    },
    // 自定义属性 - 默认选中员工id数组
    defaultSelectedUserIds: {
      type: Array,
      default: () => [],
    },
    // 自定义属性 - 默认选中的员工数组
    defaultSelectedUsers: {
      type: Array,
      default: () => [], // [{userName:'',userId:'',jobNumber:''}]
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    /** 限定角色 */
    roleCode: {
      type: String,
      default: '',
    },
    ownerUserId: {
      type: Number,
      default: null,
    },
    isFilter: {
      type: [Number, undefined],
      default: undefined,
    },
    leadsIds: {
      type: String,
      default: '',
    },
    isGetAll: {
      type: [Number, undefined],
      default: undefined,
    },
    selectedRowKeys: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      title2: '',
      visible: false,
      // ! 组织架构 Begin
      keyword: '',
      selectedKeys: [],
      defaultValue: [],
      expandedKeys: [],
      isRoleInfoLoading: false,
      roleInfo: [],
      autoExpandParent: true,
      treeData: [],
      replaceFields: { children: 'children', title: 'name', key: 'id' },
      // ! 组织架构 End

      // ! 员工列表 Begin
      columns: [
        // { title: '用户ID', dataIndex: 'userId', width: 100 },
        {
          title: '员工姓名',
          dataIndexc: 'name',
          width: 145,
          scopedSlots: { customRender: 'name' },
        },
        { title: '账号', dataIndex: 'account', width: 100 },
        // { title: '头像', dataIndex: 'avatar', width: 100, customRender: (text) => <img src={text} alt="avatar" style={{ width: '30px', height: '30px', borderRadius: '50%' }} /> },
        {
          title: '手机号码',
          dataIndex: 'mobile',
          width: 100,
          scopedSlots: { customRender: 'mobile' },
        },
        { title: '分机号', dataIndex: 'telephone', width: 100 },
        { title: '职位', dataIndex: 'position', width: 100 },
        { title: '员工邮箱', dataIndex: 'email', width: 150 },
        {
          title: '状态',
          dataIndex: 'status',
          width: 80,
          customRender: (text) => (text === 1 ? '有效' : '无效'),
        },
        // { title: '所属部门ID列表', dataIndex: 'deptIds', width: 150, customRender: (text) => text.split(',').join(', ') },
        { title: '所属部门名称列表', dataIndex: 'deptNames', width: 150 },
        // { title: '是否超级管理员', dataIndex: 'isAdmin', width: 120 },

        {
          title: '角色信息',
          dataIndex: 'roles',
          width: 250,
          sorter: (a, b) =>
            _.map(a.roles).join()?.localeCompare(_.map(b.roles, 'name').join(), 'zh'),
          customRender: (roles) => _.map(roles, 'name').join(','),
        },
        // You can add more columns if needed
        // { dataIndex: 'operation', title: '操作', fixed: 'right', align: 'center', width: 100, scopedSlots: { customRender: 'operation' }, sort: false }
      ],
      tableParams: {
        deptId: void 0, // 部门ID（包括子部门员工）
        name: undefined, // 员工姓名，模糊搜索
        jobNumber: undefined, // 员工工号，模糊搜索
        isFilter: this.isFilter,
        isGetAll: this.isGetAll,
        ownerUserId: this.ownerUserId,
      },
      selectedUserIds: [], // 选择的员工id数组
      selectedUsers: [], // 选择的员工数组
      // ! 员工列表 End
    }
  },
  computed: {
    ...mapGetters(['deptId']),
    selectedUser() {
      return this.selectedUsers.map((item) => item.userName).toString()
    },
  },
  watch: {
    visible: {
      async handler(val) {
        if (val) {
          await this.getDeptTree()
          await this.getRoleInfo()
          if (this.defaultSelectedUserIds.length > 0)
            this.selectedUserIds = this.defaultSelectedUserIds
          if (this.defaultSelectedUsers.length > 0) this.selectedUsers = this.defaultSelectedUsers
        } else {
          this.close()
        }
      },
      immediate: true,
    },
  },
  created() {
    // 使用 Lodash 实现防抖
    this.debouncedSearch = _.debounce(this.handleSearch, 200)
  },
  unmounted() {
    // 移除组件时，取消定时器
    this.debouncedSearch.cancel()
  },
  methods: {
    dataDesensitization(str, frontLen, endLen) {
      if (str.length === frontLen) {
        frontLen = 1
        endLen = 1
      }
      const len = str.length - frontLen - endLen
      let xing = ''
      for (let i = 0; i < len; i++) {
        xing += '*'
      }
      return str.substring(0, frontLen) + xing + str.substring(str.length - endLen)
    },
    // 提供给父组件通过 this.$refs.userSelectModal.open() 调用
    open(title2 = '') {
      if (title2) {
        this.title2 = title2
      }
      this.visible = true
      this.reset()
    },
    // 提供给父组件通过 this.$refs.userSelectModal.close() 调用
    close() {
      this.selectedKeys = []
      this.selectedUsers = []
      this.selectedUserIds = []
      this.visible = false
    },
    async handleOk() {
      if (this.selectedUserIds.length === 0 && this.required) {
        this.$message.warning('请选择需要添加的人员')
        return
      }
      const listener = this.$listeners.ok
      let res = true

      if (typeof listener === 'function') {
        res = listener({ selectedUserIds: this.selectedUserIds, selectedUsers: this.selectedUsers })
      }
      res instanceof Promise && (await Promise.all([res]))
      if (this.title === '选择管户人') {
        await leadsApi.postOwnerUpdate({
          leadsIds: this.selectedRowKeys.join(',') || this.leadsIds,
          ownerUserId: this.selectedUserIds.join(','),
        })
      } else if (this.title2 === '添加协作人') {
        await leadsApi.postCollaboratorAdd({
          leadsIds: this.selectedRowKeys.join(',') || this.leadsIds,
          userIds: this.selectedUserIds.join(','),
        })
      } else if (this.title2 === '变更协作人') {
        await leadsApi.postCollaboratorUpdate({
          leadsIds: this.selectedRowKeys.join(',') || this.leadsIds,
          userIds: this.selectedUserIds.join(','),
        })
      }
      this.$emit('submit')
      this.$message.success('操作成功')

      if (res !== false) {
        this.selectedUserIds = []
        this.selectedUsers = []
        this.visible = false
      }
    },
    // ! 组织架构 Begin
    async getDeptTree() {
      this.treeData = await getDeptTree()
      this.defaultValue = [this.treeData[0].id]
      this.selectedKeys = [this.treeData[0].id]
      this.expandedKeys = [this.treeData[0].id]
    },
    handleSelectTree(selectedKeys) {
      // 选择同一个部门不传值处理
      if (selectedKeys.length === 0 && this.tableParams.deptId) {
        return
      }
      this.selectedKeys = selectedKeys || undefined
      this.tableParams.deptId = selectedKeys[0] || undefined
      this.reload()
    },
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys
      this.autoExpandParent = false
    },
    // ! 组织架构 End

    // ! 员工列表 Begin
    // 对员工列表进行数据格式转换，统一使用 userId、userName
    transformDataSource(data) {
      data.forEach((item) => {
        item.userId = item.userId || item.id
        item.userName = item.userName || item.name
      })
    },
    // 搜索员工列表
    handleSearch() {
      const { keyword, treeData, replaceFields } = this
      if (!keyword) {
        this.expandedKeys = [treeData[0].id]
      } else {
        const list = this.$m.tree.toList(treeData, { pid: 'parentId', children: 'children' })
        const _list = list.filter((item) => item[replaceFields.title].indexOf(keyword) > -1)
        const targetValues = _list.map((item) => item[replaceFields.key])
        let tempArr = []
        targetValues.forEach((item) => {
          const arr = this.$m.tree.findPathAll(
            treeData,
            (node) => node[replaceFields.key] === item,
            { pid: 'parentId', children: 'children' },
          )
          const _values = arr[0].map((_item) => _item[replaceFields.key])
          tempArr = [...tempArr, ..._values]
        })
        this.expandedKeys = tempArr
      }
    },
    reset() {
      this.selectedKeys = this.defaultValue
      Object.keys(this.tableParams).forEach((key) => {
        if (this.roleCode && key === 'roleId') return
        if (key === 'deptId') {
          this.tableParams[key] = this.defaultValue[0]
          return
        }
        if (key === 'isFilter') {
          this.tableParams[key] = 1
          return
        }
        if (key === 'ownerUserId') {
          this.tableParams[key] = this.ownerUserId
          return
        }
        if (key === 'isGetAll' && this.title2) {
          this.tableParams[key] = 1
          return
        }
        this.tableParams[key] = undefined
      })
      this.reload()
    },
    handleRowSelectionChange({ selectedRowKeys, selectedRows }) {
      this.selectedUserIds = selectedRowKeys
      this.selectedUsers = selectedRows
    },
    reload() {
      this.$refs.basisTableLayout && this.$refs.basisTableLayout.reload()
    },

    // 加载角色列表
    async getRoleInfo() {
      try {
        this.isRoleInfoLoading = true
        const res = await getRoleInfo({ type: 1 })
        this.roleInfo = res
        if (this.roleCode && _.find(res, { code: this.roleCode })) {
          this.$set(this.tableParams, 'roleId', _.find(res, { code: this.roleCode }).id)
          this.$set(this.tableParams, 'deptId', this.treeData[0]?.id)
        }
        this.roleCode && this.reload()
      } finally {
        this.isRoleInfoLoading = false
      }
    },

    getUserList,
    getUserPage,
  },
}
</script>

<style lang="less" scoped>
.tree-box {
  overflow: auto;
  height: calc(100% - 40px); // 40 = 32(搜索框) + (上边距)
  margin-top: 8px;
}
.ant-layout {
  background-color: #fff !important;
}
</style>
