/**
 * @description 客户/销售档案
 */
import axios from '@/utils/axios'

/**
 * 成员线索/商机数量统计
 * @param {object} params
 * @param {string} params.userId 用户Id
 * @param {string} params.roleCode 角色编码
 * @param {string} [params.divisionDeptId] 事业部Id
 * @param {string} [params.businessDeptId] 业务部Id
 * @returns {Promise<{
 *   screenNum: string
 *   developNum: string
 *   screenBack: string
 *   developBack: string
 *   screenAvgScore: string
 *   developRate: string
 *   sigingRate: string
 *   cooperateDevelop: string
 * }>} ** 返回体 **
 *   - screenNum: 线索数量
 *   - developNum: 商机数量
 *   - screenBack: 退回线索数量
 *   - developBack: 退回商机数量
 *   - screenAvgScore: 线索平均分
 *   - developRate: 线索转化率
 *   - sigingRate: 客户转化率
 *   - cooperateDevelop: 协作商机数量
 */
export const getStatInfo = (params) => axios.get('/v1/sale/leads/stat/info', { params })

/**
 * 销售档案线索时间统计列表
 * @param {object} params
 * @param {string} params.cycleType 查询维度： 1 日，2 月， 3 年
 * @param {string} [params.statDate] 查询时间： YYYY-MM/YYYY， 当cycleType=3时不传
 * @param {string} params.userId 用户ID
 * @param {string} params.roleCode roleCode
 * @param {string} [params.divisionDeptId] 事业部ID
 * @param {string} [params.businessDeptId] 业务部Id
 * @param {string} params.status 我的线索： 1 ， 我的线索： 2 我的商机
 * @returns {Promise<{
 *   statDate: string
 *   leadsCount: string
 * }>} ** 返回体 **
 *   - statDate: 统计时间
 *   - leadsCount: 线索数量
 */
export const getStatList = (params) => axios.get('/v1/sale/leads/stat/list', { params })

/**
 * 销售档案业绩查询
 * @param {object} params
 * @param {string} params.userId 用户ID
 * @param {string} params.roleCode 角色编码
 * @param {string} [params.divisionDeptId] 事业部Id
 * @param {string} [params.businessDeptId] 业务部Id
 * @param {string} params.month 月份YYYY-MM
 * @returns {Promise<{
 *   signups: string
 *   commission: string
 *   ranking: string
 *   outstandingAmount: string
 *   month: string
 * }>} ** 返回体 **
 *   - signups: 签单数（业绩）
 *   - commission: 提成
 *   - ranking: 排名
 *   - outstandingAmount: 待回款金额
 *   - month: 月份
 */
export const getPerformanceInfo = (params) => axios.get('/v1/sale/performance/info', { params })

/**
 * 成员业绩查询
 * @param {object} params
 * @param {string} params.userId 用户ID
 * @param {string} params.roleCode 当前档案卡片，角色编码
 * @param {string} [params.divisionDeptId] 事业部Id
 * @param {string} [params.businessDeptId] 业务部Id
 * @param {string} params.month 月份YYYY-MM
 * @param {string} params.pageSize 分页大小
 * @param {string} params.pageNo 页码
 * @returns {Promise<{
 *   current: number
 *   total: string
 *   pages: number
 *   records: {
 *     userId: string
 *     userName: string
 *     position: string
 *     divisionDeptId: string
 *     divisionDeptName: string
 *     businessDeptId: string
 *     businessDeptName: string
 *     leaderUserId: string
 *     leaderUserName: string
 *     screenNum: string
 *     developNum: string
 *     sigingNum: string
 *     signups: string
 *     commission: string
 *     outstandingAmount: string
 *   }[]
 *   size: number
 * }>} ** 返回体 **
 *   - current: 当前页码
 *   - total: 总条数
 *   - pages: 总页数
 *   - records:
 *     - userId: 用户ID
 *     - userName: 用户名称
 *     - position: 职位
 *     - divisionDeptId: 事业部Id
 *     - divisionDeptName: 事业部名称
 *     - businessDeptId: 业务部Id
 *     - businessDeptName: 业务部名称
 *     - leaderUserId: 上级用户ID
 *     - leaderUserName: 上级名称
 *     - screenNum: 有效线索
 *     - developNum: 商机开发
 *     - sigingNum: 客户维护
 *     - signups: 本月业绩
 *     - commission: 提成
 *     - outstandingAmount: 待回款金额
 *   - size: 分页大小
 */
export const getPerformancePage = (params) => axios.get('/v1/sale/performance/page', { params })

/**
 * 销售档案用户信息详情查询接口
 * @param {object} params
 * @param {string} params.userId 用户ID
 * @returns {Promise<{
 *   userId: string
 *   account: string
 *   jobNo: string
 *   name: string
 *   avatar: string
 *   mobile: string
 *   telephone: string
 *   position: string
 *   email: string
 *   status: string
 *   roleId: string
 *   roleCode: string
 *   roleName: string
 *   divisionDeptId: {
 *   }
 *   divisionDeptName: string
 *   businessDeptId: string
 *   businessDeptName: string
 *   leaderUserId: string
 *   leaderUserName: string
 * }>} ** 返回体 **
 *   - userId: 用户ID（非钉钉员工ID）
 *   - account: 账号
 *   - jobNo: 工号
 *   - name: 员工姓名
 *   - avatar: 头像url
 *   - mobile: 手机号码
 *   - telephone: 分机号
 *   - position: 职位
 *   - email: 员工邮箱
 *   - status: 状态，1-有效，0-无效
 *   - roleId: 角色ID
 *   - roleCode: 角色编码
 *   - roleName: 角色名称
 *   - divisionDeptId: 事业部Id
 *   - divisionDeptName: 事业部名称
 *   - businessDeptId: 业务部ID
 *   - businessDeptName: 业务部名称
 *   - leaderUserId: 直属上级ID
 *   - leaderUserName: 直属上级名称
 */
export const getUserInfo = (params) => axios.get('/v1/sale/user/info', { params })

/**
 * 销售人员信息列表查询
 * @param {object} params
 * @param {string} params.deptId 部门Id
 * @param {string} params.pageSize 分页大小
 * @param {string} params.pageNo 页码
 * @returns {Promise<{
 *   current: number
 *   total: string
 *   pages: number
 *   records: {
 *     userId: string
 *     name: string
 *     account: string
 *     mobile: string
 *     deptId: string
 *     deptName: string
 *     roleId: string
 *     roleCode: string
 *     roleName: string
 *   }[]
 *   size: number
 * }>} ** 返回体 **
 *   - current: 当前页码
 *   - total: 总条数
 *   - pages: 总页数
 *   - records:
 *     - userId: 用户ID
 *     - name: 用户名称
 *     - account: 用户账号
 *     - mobile: 用户手机号
 *     - deptId: 部门ID
 *     - deptName: 部门名称
 *     - roleId: 角色Id
 *     - roleCode: 角色编码
 *     - roleName: 角色名称
 *   - size: 分页大小
 */
export const getUserPage = (params) => axios.get('/v1/sale/user/page', { params })
