<template>
  <div>
    <!--顶部操作栏占位，勾选行时不显示-->
    <div v-if="$scopedSlots.leftMenu || $scopedSlots.rightMenu" class="top-menu">
      <div><slot name="leftMenu" :selection="selection" /></div>
      <div><slot name="rightMenu" :selection="selection" /></div>
    </div>
    <el-table ref="table" v-loading="loading" :size="size" :data="data" v-bind="elAttrs" :max-height="maxHeight" v-on="listeners">
      <el-table-column v-if="_config.enableMultiSelect" type="selection" width="55" />
      <el-table-column v-if="_config.showIndexColumn" type="index" width="50" />
      <table-column v-for="column in columns" :key="column.prop" :column="column">
        <slot v-if="column.slot" slot-scope="props" :name="column.prop" v-bind="props"></slot>
        <slot v-if="column.slot" slot="header" slot-scope="props" :name="column.prop + '-header'" v-bind="props" />
      </table-column>
      <el-table-column v-if="_config.showHandler" v-bind="handlerColumn">
        <slot slot-scope="props" name="handler" v-bind="props" />
      </el-table-column>
    </el-table>
    <table-pagination v-if="page && page.total > 0" :page="page" :page-config="pageConfig" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
  </div>
</template>

<script>
import { elTableAttrs } from './config'
import { get } from './util'
import tableColumn from './tableColumn'
import tablePagination from './tablePagination'

export default {
  name: 'CommonTable',
  components: {
    tableColumn,
    tablePagination
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Array,
      default: () => []
    },
    loading: Boolean,
    config: {
      type: Object,
      default: () => ({})
    },
    pageConfig: { type: Object, default: () => ({}) },
    page: {
      type: Object,
      default: () => null
    },
    // Table 的尺寸 默认 default 48 / medium 44 / small 40 / mini 36
    size: {
      type: String,
      default: 'small'
    },
    maxHeight: {
      type: String,
      default: () => 'auto'
    }
  },
  data() {
    return {
      selection: []
    }
  },
  computed: {
    _config() {
      return Object.assign(
        {
          showHandler: false,
          handlerColumn: {},
          highlightSelect: true,
          showIndexColumn: false,
          tooltipEffect: 'dark'
        },
        this.config
      )
    },
    // 操作列配置
    handlerColumn() {
      return Object.assign(
        {
          label: '操作',
          minWidth: 200,
          fixed: 'right'
        },
        this._config.handlerColumn
      )
    },
    // el-table组件属性
    elAttrs() {
      const copy = {}
      for (const key in this._config) {
        if (elTableAttrs.includes(key)) {
          copy[key] = this._config[key]
        }
      }
      if (this._config.highlightSelect) {
        Object.assign(copy, {
          'row-class-name': this.rowClassName
        })
      }
      return copy
    },
    // el-table监听事件
    listeners() {
      return Object.assign({}, this.$listeners, {
        select: this.handleSelect,
        'select-all': this.handleSelectAll
      })
    }
  },
  watch: {
    // 如果当前数据有已被选中的则设置为已勾选
    data(val) {
      this.$nextTick(() => {
        if (val.length > 0 && this.selection.length > 0) {
          val.forEach((row) => {
            if (this.selection.findIndex((item) => this._getRowKey(item) === this._getRowKey(row)) >= 0) {
              this.$refs.table.toggleRowSelection(row, true)
            }
          })
        }
      })
    }
  },
  methods: {
    _getRowKey(row) {
      const config = this._config
      if (typeof config.rowKey === 'function') {
        return config.rowKey(row)
      } else if (typeof config.rowKey === 'string') {
        return get(row, config.rowKey)
      }
      return row.id
    },
    handleCurrentChange(val) {
      this.$emit('current-page-change', val)
    },
    handleSizeChange(val) {
      this.$emit('page-size-change', val)
    },
    handleSelect(selection, row) {
      if (selection.includes(row)) {
        this.selection.push(row)
      } else {
        this.selection.splice(this.selection.indexOf(row), 1)
      }
      this.$emit('select', this.selection, row)
    },
    handleSelectAll(selection) {
      let index
      this.data.forEach((row) => {
        index = this.selection.findIndex((item) => this._getRowKey(item) === this._getRowKey(row))
        // 全选时
        if (selection.length > 0 && index < 0) {
          this.selection.push(row)
          // 全不选时
        } else if (selection.length === 0 && index >= 0) {
          this.selection.splice(index, 1)
        }
      })
      this.$emit('selectAll', this.selection)
    },
    // 高亮当前选中行
    rowClassName({ row }) {
      for (let index = 0; index < this.selection.length; index++) {
        if (this.selection[index] === row) {
          return 'row__active'
        }
      }
      return ''
    },
    clearSelection() {
      this.selection = []
      this.$refs.table.clearSelection()
    },
    toggleRowSelection() {
      return this.$refs.table.toggleRowSelection(...arguments)
    },
    toggleAllSelection() {
      return this.$refs.table.toggleAllSelection(...arguments)
    },
    toggleRowExpansion() {
      return this.$refs.table.toggleRowExpansion(...arguments)
    },
    setCurrentRow() {
      return this.$refs.table.setCurrentRow(...arguments)
    },
    clearSort() {
      return this.$refs.table.clearSort(...arguments)
    },
    clearFilter() {
      return this.$refs.table.clearFilter(...arguments)
    },
    doLayout() {
      return this.$refs.table.doLayout(...arguments)
    },
    sort() {
      return this.$refs.table.sort(...arguments)
    }
  }
}
</script>
<style lang="scss" scoped>
.top-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 40px;
  margin-bottom: 12px;
}
</style>
