import provinceData from './province'
import cityData from './city'
import areaData from './area'

/**
 * @description: 获取省市区层级结构（返回Promise）
 * @param {String} level 返回的层级 province省 city市 area区
 * @return {Array} [{name:'广东省',code:440000,children:[{name:'广州市',code:440100,children:[{name:'天河区',code:440106}]}]}]
 */
export const getAreaTree = (level = 'area') => {
  return new Promise((resolve, reject) => {
    if (level === 'province') resolve(provinceData)
    if (['city', 'area'].includes(level)) {
      provinceData.forEach((province, index) => {
        province.children = cityData[index]

        if (level === 'area') {
          cityData.forEach((city, cityIndex) => {
            city.forEach((city2, cityIndex2) => {
              city2.children = areaData[cityIndex][cityIndex2]
            })
          })
        }
      })
    }
    resolve(provinceData)
  })
}
