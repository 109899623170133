/*
 * @Description: 组件自动注册及导出
 */
// 受 webpack 限制，import 和 module.exports 不能共用，故不使用 import 导入模块，否则打包后会报错 Uncaught TypeError: Cannot assign to read only property 'exports' of object '#<Object>'
let Vue = require('vue')
Vue = Vue.__esModule && Vue.default ? Vue.default : Vue // 兼容 esModule，通过CDN引入和本地引入是不同的模式，此处兼容两种引入方式
const { kebabCase2PascalCase } = require('@/utils')

// 全局注册动态加载 DNS 的 js 文件组件
Vue.component('remote-js', {
  props: {
    src: { type: String, required: true }
  },
  render(createElement) {
    return createElement('script', {
      attrs: { type: 'text/javascript', src: this.src },
      on: {
        load: () => {
          this.$emit('loaded')
        }
      }
    })
  }
})

// 全局注册动态加载 DNS 的 css 文件组件
Vue.component('remote-css', {
  props: {
    href: { type: String, required: true }
  },
  render(createElement) {
    return createElement('link', {
      attrs: { rel: 'stylesheet', href: this.href },
      on: {
        load: () => {
          this.$emit('loaded')
        }
      }
    })
  }
})

// 自动导出组件 不支持直接导入通过.js文件编写的组件。
const requireComponent = require.context('.', true, /\.(jsx|vue)$/)
const componentMap = {} // 全部组件对象
requireComponent.keys().forEach((key) => {
  const componentConfig = requireComponent(key)
  // 组件如果有name属性，则优先作为组件引用名称
  let componentName = componentConfig.default?.name || ''
  // 没有name属性时，则使用组件文件名或者文件夹名称
  if (!componentName) {
    const componentNames = key
      .replace(/^\.\//, '')
      .replace(/\.\w+$/, '')
      .split('/') // split('/')兼容 ./BasisIcon/BasisIcon.vue 结构
    componentName = componentNames[1] === 'index' ? componentNames[0] : componentNames[1]
  }
  // kebab-case转PascalCase
  if (componentName.indexOf('-') > -1) {
    componentName = kebabCase2PascalCase(componentName)
    componentConfig.default.name = componentName
  }
  if (componentName.startsWith('Basic') || componentName.startsWith('Com')) {
    // 以Basic或Com命名开头的公共组件注册为全局组件
    Vue.component(componentName, componentConfig.default)
  }
  // 可按需引入 import { Upload, CellItem } from '@cp/index'
  const content = require(`@/components/${key.replace(/^\.\//, '')}`)
  componentMap[componentName] = content.__esModule && content.default ? content.default : content
})
module.exports = componentMap
