/*
 * @Description: 文件上传接口
 * @Author: yitong
 * @Date: 2021-12-08 10:30:41
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-09-18 10:01:24
 */
import axios from '@/utils/axios'

/**
 * FIXME: 目前该项目中没使用到直传七牛功能，故暂无开发以下两个接口，后续根据实际业务需要进行完善
 * 七牛云上传凭证获取接口（上传路径：前端->七牛云）
 * @returns {Promise<{
 * uploadToken: string;
 * domain: string
 * }>}
 * **返回**
 * - uploadToken 七牛云上传凭证
 * - domain 绑定域名
 */
export const getQiniuUploadToken = () => axios.get('/v1/qiniu/upload/token')
