import Provider from './components/provider'
import Table from './components/table'
import ListSort from './components/listSort'
import TableLayout from './components/tableLayout'
import SearchForm from './components/searchForm'
import InfiniteSelect from './components/infiniteSelect'

const components = { Provider, Table, ListSort, TableLayout, SearchForm, InfiniteSelect }

export default {
  ...components,
  install(Vue) {
    Object.values(components).forEach((component) => {
      Vue.component(component.name, component)
    })
  },
}
