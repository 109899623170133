export const MENU_CODE = {
  FRONTEND_TOP: 'FRONTEND_TOP',
  BACKEND_TOP: 'BACKEND_TOP',
}

export const MENU_CATEGORY = {
  FRONTEND: 0,
  BACKEND: 1,
}

export const MENU_TYPE = {
  CATEGORY: 0,
  MENU: 1,
  BUTTON: 2,
}
