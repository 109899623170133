<template>
  <div>
    <UserSelectModal v-bind="$props" :default-selected-user-ids="selectedUserIds" :default-selected-users="selectedUsers" @ok="handleOk"></UserSelectModal>
    <div v-if="showCount" class="m-flex m-m-t-4" style="line-height: 20px">
      <a-button :disabled="batchDelUserIds.length === 0" type="danger" class="m-m-r-8" @click="handleBatchDel">批量删除</a-button>
      已选择
      <span class="m-type-primary m-m-h-4">{{ selectedUsers.length }}</span>
      人
    </div>
    <BasisTable
      v-model="batchDelUserIds"
      :selectable="true"
      :pagination="pagination"
      class="m-m-t-4"
      :bordered="true"
      :scroll="scroll"
      empty-desc="暂无数据，请先选择人员"
      row-key="userId"
      :columns="columns"
      :data-source="selectedUsers"
    >
      <template #operation="{ record }">
        <a v-if="!record.disabled" @click="handleDel(record.userId)">删除</a>
      </template>
    </BasisTable>
  </div>
</template>

<script>
import UserSelectModal from '@cp/UserSelectModal'

export default {
  name: 'UserSelectTable', // 选择员工表格组件，该组件通过table表格的形式展示员工
  components: {
    UserSelectModal
  },
  props: {
    ...UserSelectModal.props,
    scroll: {
      type: Object,
      default: () => {
        return { y: 'calc(70vh - 200px)' }
      }
    },
    // 默认数据
    defaultValue: {
      type: Array,
      default: () => []
    },
    pagination: {
      type: Object,
      default: () => {
        return { showTotal: (total) => `共 ${total} 条`, pageSize: 20, pageSizeOptions: ['20', '50', '100', '200'], showSizeChanger: true }
      }
    },
    // 是否显示已选人员
    showCount: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      columns: [
        { dataIndex: 'userName', title: '姓名' },
        { dataIndex: 'jobNumber', title: '工号' },
        { dataIndex: 'operation', title: '操作', width: 80, scopedSlots: { customRender: 'operation' }, sort: false }
      ],
      selectedUsers: [], // [{userId: '', userName: '', jobNumber:'', ...}]
      selectedUserIds: [], // userId 数组
      batchDelUserIds: [] // 需要批量删除的用户id数组
    }
  },
  watch: {
    defaultValue: {
      handler(val) {
        if (Array.isArray(val) && val.length > 0) {
          this.selectedUsers = val
          this.selectedUserIds = val.map((item) => item.userId)
        }
      },
      immediate: true
    }
  },
  methods: {
    handleOk({ selectedUsers, selectedUserIds }) {
      // 对外输出全部使用userId、userName
      this.selectedUsers = selectedUsers.map((item) => {
        item.userId = item.userId || item.id
        item.userName = item.userName || item.name
        return item
      })
      this.selectedUserIds = selectedUserIds

      this.handleChange()
    },
    // 批量删除
    handleBatchDel() {
      this.batchDelUserIds.forEach((item) => this.handleDel(item))
      this.batchDelUserIds.length = 0
    },
    // 单个删除
    handleDel(userId) {
      const index = this.selectedUserIds.findIndex((item) => item === userId)
      this.selectedUsers.splice(index, 1)
      this.selectedUserIds.splice(index, 1)

      this.handleChange()
    },
    handleChange() {
      this.$emit('change', this.selectedUsers)
    }
  }
}
</script>

<style lang="less" scoped></style>
