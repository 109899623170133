import _ from 'lodash'

export default {
  tag: (state) => state.tags.tag,
  language: (state) => state.common.language,
  website: (state) => state.common.website,

  fromUrl: (state) => state.user.fromUrl,
  colorName: (state) => state.common.colorName,
  themeName: (state) => state.common.themeName,
  isShade: (state) => state.common.isShade,
  isCollapse: (state) => state.common.isCollapse,
  keyCollapse: (state, getters) => (getters.screen > 1 ? getters.isCollapse : false),
  screen: (state) => state.common.screen,
  isLock: (state) => state.common.isLock,
  isFullScreen: (state) => state.common.isFullScreen,
  isMenu: (state) => state.common.isMenu,
  lockPasswd: (state) => state.common.lockPasswd,
  tagList: (state) => state.tags.tagList,
  adminTagList: (state, getters) =>
    getters.tagList.filter((item) => item.value && item.value.startsWith('/admin/')),
  frontTagList: (state, getters) =>
    getters.tagList.filter((item) => item.value && !item.value.startsWith('/admin/')),
  deleteTag: (state) => state.tags.deleteTag,
  deptId: (state, getters) => _.toString(getters.userInfo.deptIds),
  cleanCache: (state) => state.tags.cleanCache,
  tagWel: (state) => state.tags.tagWel,
  permission: (state) => state.user.permission,
  menus: (state) => state.user.menus,
  tenantInfo: (state) => ({ ...state.user.tenantInfo }),
  tenantId: (state, getters) => _.toString(getters.tenantInfo.tenantId),
  logsList: (state) => state.logs.logsList,
  logsLen: (state) => state.logs.logsList.length || 0,
  logsFlag: (state, getters) => getters.logsLen === 0,
  userInfo: (state) => state.user.userInfo || {},
  roleInfo: (state) => state.user.roleInfo,
  roleCodes: (state) => state.user.roleInfo.roleCodes,
  maxRoleCode: (state) => state.user.roleInfo.maxRoleCode,
  userId: (state, getters) => _.toString(getters.userInfo.userId),
  accessToken: (state, getters) => getters.userInfo.accessToken,
  refreshToken: (state, getters) => getters.userInfo.refreshToken,
  erp: (state, getters) => getters.userInfo.erp,
}
