import website from '@/config/website'
import _ from 'lodash'

const isFirstPage = website.isFirstPage
const tagWel = website.fistPage
const tagObj = {
  label: '首页', // 标题名称
  value: '/home/index', // 标题的路径
  params: {}, // 标题的路径参数
  query: {}, // 标题的参数
  meta: { title: '首页', isOwn: 1 }, // 额外参数
  group: [], // 分组
}

// 处理首个标签
function setFistTag(list) {
  if (list.length === 1 && _.isEmpty(list[0])) {
    list[0] = tagObj
  } else {
    list.forEach((ele) => {
      if (ele.value === tagWel.value && isFirstPage === false) {
        ele.close = false
      } else {
        ele.close = true
      }
    })
  }
}

const navs = {
  state: {
    tagList: [],
    tag: tagObj,
    tagWel,
    cleanCache: false,
    deleteTag: {},
  },
  actions: {},
  mutations: {
    ADD_TAG: (state, action) => {
      state.tag = action
      if (state.tagList.some((ele) => ele.value === action.value)) {
        return
      }
      state.tagList.push(action)
      setFistTag(state.tagList)
    },
    DEL_TAG: (state, action) => {
      state.cleanCache = true
      state.deleteTag = action
      state.tagList = state.tagList.filter((item) => item.value !== action.value)
      setTimeout(() => {
        state.cleanCache = false
      }, 0)
      setFistTag(state.tagList)
    },
    DEL_ALL_TAG: (state) => {
      state.tagList = [state.tagWel]
    },
    DEL_TAG_OTHER: (state) => {
      state.tagList = state.tagList.filter((item) => {
        if (item.value === state.tag.value) {
          return true
        } else if (!website.isFirstPage && item.value === website.fistPage.value) {
          return true
        }
        return false
      })
      setFistTag(state.tagList)
    },
    SET_TAG_LIST(state, tagList) {
      state.tagList = tagList
    },
    SET_CACHE(state, val) {
      state.cleanCache = val
    },
  },
}
export default navs
