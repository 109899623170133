let timer

const cron = {
  state: {
    flow: [],
    interval: 5000,
  },
  mutations: {
    SET_FLOW: (state, flow = state.flow) => {
      state.flow = flow
    },
    ADD_TASK: (state, task) => {
      const { handler, key, caller, delay, immediate } = task
      if (immediate) {
        handler.call(caller)
      }

      state.flow.push({
        key: key || handler,
        handler,
        caller,
        delay,
        _lastCallTime: Date.now(),
      })
    },

    REMOVE_TASK: (state, key) => {
      if (key) {
        const index = state.flow.findIndex((f) => f.key === key)
        state.flow.splice(index, 1)
      } else {
        state.flow = []
      }
    },
  },
  actions: {
    AddTask({ commit, dispatch, state }, payload) {
      let task = payload
      if (typeof payload === 'function') {
        task = { handler: payload }
      }
      commit('ADD_TASK', task)
      commit('SET_FLOW')

      if (!state.timer) {
        // init timer
        dispatch('Start')
      }
    },
    Start({ dispatch, state }) {
      timer = setTimeout(function interval() {
        state.flow.forEach((task) => {
          const { handler: h, caller: c, delay = 0 } = task
          if (Date.now() - task._lastCallTime > delay) {
            h.call(c)
            task._lastCallTime = Date.now()
          }
        })
        timer = setTimeout(interval, state.interval)

        if (!state.flow.length) {
          dispatch('Stop')
        }
      }, state.interval)
    },
    Stop() {
      clearTimeout(timer)
      timer = null
    },

    RemoveTask({ commit, dispatch, state }, payload) {
      commit('REMOVE_TASK', payload)
      commit('SET_FLOW')

      if (!state.flow.length) {
        dispatch('Stop')
      }
    },
  },
}

export default cron
