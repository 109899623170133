// https://levelup.gitconnected.com/how-to-add-a-watermark-to-your-website-with-html-canvas-f2c39474308a
// Use canvas to implement watermark
// eslint-disable-next-line max-statements
export default function __canvasWM({
  container = document.body,
  width = '300px',
  height = '220px',
  textAlign = 'center',
  textBaseline = 'middle',
  font = '12px Microsoft Yahei',
  fillStyle = 'rgba(184, 184, 184, 0.25)',
  content = 'Please do not pass it on',
  rotate = '30',
  zIndex = 9999
} = {}) {
  const args = arguments[0]
  const canvas = document.createElement('canvas')

  canvas.setAttribute('width', width)
  canvas.setAttribute('height', height)
  const ctx = canvas.getContext('2d')

  ctx.textAlign = textAlign
  ctx.textBaseline = textBaseline
  ctx.font = font
  ctx.fillStyle = fillStyle
  ctx.rotate((Math.PI / 180) * rotate)
  ctx.fillText(content, parseFloat(width) / 2, parseFloat(height) / 2)

  const base64Url = canvas.toDataURL()
  const __wm = document.querySelector('.__wm')

  const watermarkDiv = __wm || document.createElement('div')
  const styleStr = `
          position:absolute !important;
          top:0 !important;
          left:0 !important;
          width:100% !important;
          height:100% !important;
          z-index:${zIndex} !important;
          pointer-events:none !important;
          background-repeat:repeat !important;
          background-image:url('${base64Url}') !important;
          background-position: -50px -50px !important;`

  watermarkDiv.setAttribute('style', styleStr)
  watermarkDiv.classList.add('__wm')

  if (!__wm) {
    container.style.position = 'relative'
    container.insertBefore(watermarkDiv, container.firstChild)
  }

  const MutationObserver = window.MutationObserver || window.WebKitMutationObserver
  if (MutationObserver) {
    let mo = new MutationObserver(() => {
      const __wm = document.querySelector('.__wm')
      // Re-call __canvasWM only when the __wm element changes
      if ((__wm && __wm.getAttribute('style') !== styleStr) || !__wm) {
        // Avoid triggering all the time
        mo.disconnect()
        mo = null
        __canvasWM(JSON.parse(JSON.stringify(args)))
      }
    })

    mo.observe(container, {
      attributes: true,
      subtree: true,
      childList: true
    })
  }
}
