import { getDepartmentTree as getDeptTree } from '@/api/crm/department'
import PropTypes from 'ant-design-vue/es/_util/vue-types'
import _ from 'lodash'
import { Component, Vue } from 'vue-property-decorator'
import InputDeptSearch from './InputDeptSearch'
import './style.scss'

const AddUserModalProps = {
  /**
   * @type {(ids:string[], records:object[])=>Promise<any>}
   * 提交操作
   */
  action: PropTypes.func,
  alert: PropTypes.bool.def(false),
  getDeptSearch: PropTypes.func.def(getDeptTree),
  getUserList: PropTypes.func.isRequired,
  /** @type {string} ATable.type 表格组件多选与单选 */
  type: PropTypes.oneOf(['checkbox', 'radio'])
}

@Component
class AbstractModal extends Vue {
  formData = {}

  loading = false
  visible = false

  show(data) {
    this.visible = true

    if (data) {
      this.formData = _.cloneDeep(data)
    }
  }

  close() {
    this.visible = false
    this.clear()
  }

  clear() {
    this.selection = []
    this.formData = {}
    this.loading = false
    this.dataSource = []
  }
}

@Component({
  props: { ...AddUserModalProps },
  inheritAttrs: false
})
export default class AddUserModal extends AbstractModal {
  static SEARCH_PARAMS_EMPTY = { deptId: null, userId: null }
  static props = AddUserModalProps

  deptTreeData = []
  searchParams = _.clone(AddUserModal.SEARCH_PARAMS_EMPTY)

  isSidebarLoading = false
  isContentLoading = false
  tableConfig = {
    enablePagination: false,
    showHandler: false,
    handlerColumn: {
      width: 120
    },
    enableMultiSelect: true,
    rowKey: 'id'
  }
  dataSource = []
  tbLoading = false
  selection = []
  page = {
    size: 15,
    current: 1,
    total: 0,
    pageSizeOptions: ['15', '30', '50', '100']
  }
  show(data) {
    this.refresh(true)
    // super.show(data)
    this.selection = []
    AbstractModal.options.methods.show.call(this, data)
  }

  handleSubmit() {
    const rows = _.cloneDeep(this.selection)
    const ids = rows.map((it) => it.id).join(',')
    if (_.isEmpty(ids)) {
      this.$message.warning('请选择数据')
      return
    }
    this.close()
    this.$emit('submit', ids, rows)
  }

  async refresh(force) {
    try {
      this.isSidebarLoading = true
      this.isContentLoading = true
      this.deptTreeData = await getDeptTree()
      if (_.head(this.deptTreeData)) {
        if (force) {
          this.searchParams.deptId = _.head(this.deptTreeData).id
        }
      }
      this.isSidebarLoading = false
      this.tableRefresh(force)
    } finally {
      this.isSidebarLoading = false
      this.isContentLoading = false
    }
  }

  clear() {
    this.searchParams = _.clone(this.constructor.SEARCH_PARAMS_EMPTY)
    this.selection = []
  }
  // 表格选择
  onTableSelect(selection) {
    this.selection = selection
  }
  async tableRefresh(force) {
    let { size: pageSize, current: pageNo } = this.page
    if (force) {
      pageSize = 15
      pageNo = 1
    }
    this.tbLoading = true
    const res = await this.getUserList({
      pageNo,
      pageSize,
      ..._.pick(this.searchParams, ['deptId', 'userId'])
    })
    this.dataSource = res.records
    this.page.total = res.total
    this.tbLoading = false
  }
  renderSidebar() {
    const {
      isSidebarLoading: loading,
      deptTreeData: list,
      searchParams: { deptId }
    } = this

    return (
      <div class="sidebar">
        <InputDeptSearch
          userId={this.searchParams.userId}
          deptId={this.searchParams.deptId}
          data={this.getDeptSearch}
          onSearch={(e) => {
            if (e.deptId) {
              this.searchParams.deptId = e.deptId
            }
            this.searchParams.userId = e.userId
            this.refresh()
          }}
        />
        <AEmpty vShow={_.isEmpty(list) && !loading} />
        <div class="sidebar__scroll">
          <ASpin spinning={loading}>
            <ATree
              defaultExpandAll
              key={list.length}
              selectedKeys={_.compact([deptId])}
              replaceFields={{ title: 'deptName', key: 'id' }}
              treeData={list}
              onSelect={(keys) => {
                // 阻止取消选中部门 (点击选中的部门，会取消选中)
                if (_.isEmpty(keys)) {
                  return
                }
                ;[this.searchParams.deptId] = keys
                this.tableRefresh(true)
              }}
            />
          </ASpin>
        </div>
      </div>
    )
  }

  renderContent() {
    const { dataSource } = this
    const options = {
      columns: [
        {
          label: '姓名',
          prop: 'name'
        },
        {
          label: ' 工号 ',
          prop: 'jobNumber'
        },
        {
          label: ' 部门 ',
          prop: 'deptNames'
        }
      ],
      dataSource,
      isToolbar: false,
      isSearchPanel: false,
      isSelection: true,
      isAction: false,
      scroll: false
    }

    return (
      <div class="content">
        <ASpin spinning={this.tbLoading}>
          <CommonTable
            ref="basic-config-table"
            class="basic-config-table"
            maxHeight="480"
            columns={options.columns}
            config={this.tableConfig}
            data={options.dataSource}
            onSelect={this.onTableSelect}
            onSelectAll={this.onTableSelect}
          ></CommonTable>
          <div class="m-flex m-row-between m-p-t-16">
            <APagination
              vModel={this.page.current}
              size="small"
              total={this.page.total}
              pageSize={this.page.size}
              pageSizeOptions={this.page.pageSizeOptions}
              showSizeChanger
              showQuickJumper
              onChange={(page) => {
                this.page.current = page
                this.tableRefresh(false)
              }}
              onShowSizeChange={(size) => {
                this.page.size = size
                this.tableRefresh(false)
              }}
            />
          </div>
        </ASpin>
      </div>
    )
  }

  render() {
    // vm.$attrs 直接绑定在 AModal 上，用于配置模态窗属性
    const { loading, visible, close, handleSubmit, renderSidebar, renderContent, $attrs } = this

    return (
      <AModal
        class="oa-page-role-setting"
        confirmLoading={loading}
        destroyOnClose
        wrapClassName="oa-add-user-modal"
        width={1000}
        visible={visible}
        props={{
          title: '添加员工',
          ...$attrs
        }}
        onCancel={close}
        onOk={handleSubmit}
      >
        {
          /** table.selectedRows 会失去引用造成页面不更新, 使用 table.selectedRowKeys */
          this.alert && this.$refs.table && !!this.$refs.table.selectedRowKeys.length && (
            <AAlert
              class="mb-md pr-lg"
              vShow={_.size(this.$refs.table.selectedRows)}
              afterClose={() => {
                this.selection = []
              }}
              closable
              message={`已选择: ${_.map(this.selection.selectedRows, 'name')}`}
            />
          )
        }
        <div class="oa-add-user-modal__container">
          {renderSidebar()}
          {renderContent()}
        </div>
      </AModal>
    )
  }
}
