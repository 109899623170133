<template>
  <div :style="{ width: size + 'px', height: size + 'px' }" class="basic-avatar">
    <img :src="src || defaultAvatar" />
  </div>
</template>

<script>
export default {
  name: 'BasicAvatar',
  props: {
    src: {
      type: String,
      default: ''
    },
    size: {
      type: Number,
      default: 30
    }
  },
  data() {
    return {
      defaultAvatar: require('@/assets/images/avatar.png')
    }
  }
}
</script>

<style lang="less" scoped>
.basic-avatar {
  display: inline-flex;
  overflow: hidden;
  align-items: center;
  margin: 0 8px;
  border-radius: 100%;
  img {
    width: 100%;
    height: 100%;
    border-radius: 100%;

    object-fit: cover;
  }
}
</style>
