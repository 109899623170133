import axios from '@/utils/axios'

/**
 * 字典查询接口
 * @param {object} params
 * @param {string} params.code 字典码
 * @returns {Promise<{
 *   id: string;
 *   parentId: string;
 *   code: string;
 *   dictKey: string;
 *   dictValue: string;
 *   sort: number;
 *   remark: string;
 * }[]>} **data**
 *    - id: ID;
 *    - parentId: 父ID;
 *    - code: 字典码;
 *    - dictKey: 字典值;
 *    - dictValue: 字典名称;
 *    - sort: 排序;
 *    - remark: 字典备注;
 */
export const getDictList = (params) => axios.get('/dict/dictionary', { params })
