import defaultSettings from '@/config/defaultSettings'

export default {
  state: {
    title: defaultSettings.title, // 平台名称
    headerHeight: defaultSettings.headerHeight, // 头部导航高度
    tabsHeight: defaultSettings.tabsHeight, // 头部tab栏高度
    hideTabs: defaultSettings.hideTabs, // 隐藏头部tab栏
    fixedHeader: defaultSettings.fixedHeader, // 固定 Header
    fixedSidebar: defaultSettings.fixedSidebar, // 固定左侧菜单栏
    siderMinWidth: defaultSettings.siderMinWidth, // 侧边栏最小宽度，合起来时的宽度
    siderWidth: defaultSettings.siderWidth, // 侧边栏宽度，展开时的宽度
    siderCollapsed: defaultSettings.siderCollapsed, // 侧边栏状态
    contentWidth: defaultSettings.contentWidth, // layout of content: `Fluid` or `Fixed`, only works when layout is topmenu
    autoHideHeader: false,
    color: '',
    isMobile: false,
    layout: '',
    multiTab: true,
    theme: 'dark',
    weak: false
  },
  mutations: {
    SET_SIDER_COLLAPSED: (state, type) => {
      state.siderCollapsed = type
    }
  },
  actions: {}
}
