/**
 * @file 扩展 crypto-js
 */

import CryptoJS from 'crypto-js'
import _ from 'lodash'

const _MIXIN = {
  /**
   * 返回uuidv4
   * @returns {String} uf3f6f1d-e357-4602-9c43-18d6ae4c4c7e
   */
  uuidv4() {
    // 以字母u开头，可用作id
    return 'uxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0
      const v = c === 'x' ? r : (r & 0x3) | 0x8
      return v.toString(16)
    })
  },
  /**
   * http请求头签名
   * @param {String[]} pathVariables 路径变量 [tenantId, clientId]
   * @param {Object} requestParams 请求参数
   * @param {Object} requestBody 请求体
   * @param {String} clientSecret 应用密钥
   * @returns {String} bc01202a770cf209b9581724bb4ed706af365d61195b2cdc648516bac45822cd
   */
  httpSign(pathVariables = [], requestParams = {}, requestBody = {}, clientSecret = null) {
    const nonce = _MIXIN.uuidv4() // 流水号
    const timestamp = new Date().getTime() // 时间戳	当前系统时间戳，取13位长度的毫秒值
    const data = [`nonce=${nonce}`, `timestamp=${timestamp}`]
    if (!_.isEmpty(requestParams)) {
      // 向data中添加查询参数
      data.push(..._.map(requestParams, (value, key) => `${key}=${value}`))
    }
    data.push(`clientSecret=${clientSecret}`)
    if (!_.isEmpty(pathVariables)) {
      // 添加路径变量
      data.unshift(_.sortBy(pathVariables).join('&'))
    }
    if (!_.isEmpty(requestBody)) {
      data.push(JSON.stringify(requestBody))
    }
    // sha256编码
    return CryptoJS.SHA256(data.join('&')).toString()
  },
  /**
   * 将字符串进行base64编码
   * @param {String} data 要编码的字符串
   * @returns {String} 返回编码后的字符串
   */
  base64Encode(data) {
    const _WORD_ARRAY = CryptoJS.enc.Utf8.parse(data)
    return CryptoJS.enc.Base64.stringify(_WORD_ARRAY)
  },
  // MD5 返回的是数组，重命名并重写返回字符串
  md5(data) {
    return this.MD5(data).toString()
  }
}

export default _.mixin(CryptoJS, _MIXIN)
