const logs = {
  state: {
    logsList: [],
  },
  actions: {
    SendLogs({ commit }) {
      return new Promise(() => {
        // TODO: 调用发送日志接口
        commit('CLEAR_LOGS')
      })
    },
  },
  mutations: {
    ADD_LOGS: (state, { type, message, stack, info }) => {
      state.logsList.push(
        Object.assign(
          {
            url: window.location.href,
            time: new Date().toLocaleString(),
          },
          {
            type,
            message,
            stack,
            info: info.toString(),
          },
        ),
      )
    },
    CLEAR_LOGS: (state) => {
      state.logsList = []
    },
  },
}

export default logs
