/*
 * @Description: 工具方法：这里写项目常用的工具函数，其他的可以使用lodash
 * lodash文档 https://lodash.com/docs/4.17.15
 * @Author: yitong
 * @Date: 2021-10-11 14:33:08
 * @LastEditors: yitong
 * @LastEditTime: 2022-06-30 18:10:45
 */
import * as debounce from './lib/debounce'
import * as throttle from './lib/throttle'
import * as functions from './lib/functions'
import * as test from './lib/test'
import * as file from './lib/file'
import * as date from './lib/date'
import http from './lib/http'
import crypto from './lib/crypto'
import * as tree from './lib/tree'
import * as areaTree from './lib/area-tree'

import { version } from './package.json'

const $m = {
  v: version,
  version,
  ...debounce,
  ...functions,
  ...throttle,
  crypto,
  date,
  file,
  test,
  http,
  get: http.get,
  post: http.post,
  put: http.put,
  delete: http.delete,
  tree,
  ...areaTree
}

const install = (Vue) => {
  Vue.prototype.$m = $m
}
export { version, install }
export default { version, install }
