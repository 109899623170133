/*
 * @Description: 店铺费用设置相关的接口配置
 * @tutorial https://www.eolink.com/share/project/api/?groupID=1637277&search=~2Fv1~2Fcost~2Fshop&shareCode=hQQ9HE&shareToken=$2y$10$VVFZd6TbnCxhaoyy~2FnDiI.zRQbAElEI31sZEkkTCje7x2ai3omXBW&shareID=262525
 */

import axios from '@/utils/axios'

/**
 * 店铺费用设置未添加店铺查询接口
 * @returns {Promise<{
 *   shopNo: string;
 *   shopName: string;
 *   platformId: number;
 *   platformName: string;
 * }[]>} **data**
 *    - shopNo: 店铺编码;
 *    - shopName: 店铺名称;
 *    - platformId: 平台ID，对应字典组：PlatformId;
 *    - platformName: 平台名称，对应字典组：PlatformId;
 */
export const getShopNoAdd = () => axios.get('/v1/cost/shop/noadd')

/**
 * 店铺费用设置添加店铺接口
 * @param {object} params
 * @param {string} params.shopNo 店铺编码，多个以英文逗号分隔
 * @param {string} params.itemId 费用项ID，多个以英文逗号分隔
 * @returns {Promise<{
 * }>} **data**
 */
export const postShopAdd = (params) => axios.post('/v1/cost/shop/add', params)

/**
 * 店铺费用设置未添加店铺查询接口
 * @returns {Promise<{
 *   shopNo: string;
 *   shopName: string;
 *   platformId: number;
 *   platformName: string;
 * }[]>} **data**
 *    - shopNo: 店铺编码;
 *    - shopName: 店铺名称;
 *    - platformId: 平台ID，对应字典组：PlatformId;
 *    - platformName: 平台名称，对应字典组：PlatformId;
 */
export const getItemNoAdd = () => axios.get('/v1/cost/shop/item/noadd')

/**
 * 店铺费用项批量添加接口
 * @param {object} params
 * @param {string} params.shopNo 店铺编码，多个以英文逗号分隔
 * @param {string} params.itemId 费用项ID，多个以英文逗号分隔
 * @returns {Promise<{
 * }>} **data**
 */
export const postItemAdd = (params) => axios.post('/v1/cost/shop/item/add', params)

/**
 * 店铺费用项批量移除接口
 * @param {object} params
 * @param {string} params.shopNo 店铺编码，多个以英文逗号分隔
 * @param {string} params.itemId 费用项ID，多个以英文逗号分隔
 * @returns {Promise<{
 * }>} **data**
 */
export const postItemRemove = (params) => axios.post('/v1/cost/shop/item/remove', params)

/**
 * 店铺的费用分类及费用项查询接口
 * @param {object} params
 * @param {string} params.shopNo 店铺编码，多个以英文逗号分隔
 * @param {string} [params.type] 类型，Default：普通，CustomFormula：自定义公式
 * @returns {Promise<{
 *   classes: {
 *     classId: string;
 *     classCode: string;
 *     className: string;
 *     item: {
 *       itemId: string;
 *       itemCode: string;
 *       itemName: string;
 *       isDefault: number;
 *       type: string;
 *     }[];
 *     children: {
 *       classId: string;
 *       classCode: string;
 *       className: string;
 *       item: {
 *         itemId: string;
 *         itemCode: string;
 *         itemName: string;
 *         isDefault: number;
 *         type: string;
 *       }[];
 *       children: string;
 *     }[];
 *   }[];
 * }>} **data**
 *    - classes:
 *      - classId: 费用分类ID;
 *      - classCode: 分类编码;
 *      - className: 分类名称;
 *      - item:
 *        - itemId: 费用项ID;
 *        - itemCode: 费用项编码;
 *        - itemName: 费用项名称;
 *        - isDefault: 是否默认，1-是，0-否;
 *        - type: 类型，Default：普通，CustomFormula：自定义公式;
 *      - children:
 *        - classId: ;
 *        - classCode: ;
 *        - className: ;
 *        - item:
 *          - itemId: ;
 *          - itemCode: ;
 *          - itemName: ;
 *          - isDefault: ;
 *          - type: ;
 *        - children: ;
 */
export const getCostShopClassItem = (params) => axios.get('/v1/cost/shop/class/item', params)

/**
 * 最新科目分类及科目查询接口
 * @param {object} params
 * @param {number} [params.isDefault] 费用项是否默认，1-是，0-否
 * @param {string} [params.type] 类型，Default：原子科目，CustomFormula：复合科目，Stat：统计科目
 * @param {string} [params.dimension] 统计维度，Shop：店铺，Anchor：主播，Sku：单品
 * @returns {Promise<{
 *   classes: {
 *     classId: string;
 *     classCode: string;
 *     className: string;
 *     item: {
 *       itemId: string;
 *       itemCode: string;
 *       itemName: string;
 *       isDefault: number;
 *       type: string;
 *       remark: string;
 *       bizType: string;
 *       isSupport: number;
 *       dimension: string;
 *     }[];
 *     children: {
 *       classId: string;
 *       classCode: string;
 *       className: string;
 *       item: {
 *         itemId: string;
 *         itemCode: string;
 *         itemName: string;
 *         isDefault: number;
 *         type: string;
 *         remark: string;
 *         bizType: string;
 *         isSupport: number;
 *         dimension: string;
 *       }[];
 *       children: string;
 *     }[];
 *   }[];
 * }>} **返回**
 *    - classes:
 *      - classId: 费用分类ID;
 *      - classCode: 分类编码;
 *      - className: 分类名称;
 *      - item:
 *        - itemId: 费用项ID;
 *        - itemCode: 费用项编码;
 *        - itemName: 费用项名称;
 *        - isDefault: 是否默认，1-是，0-否;
 *        - type: 类型，Default：原子科目，CustomFormula：复合科目，Stat：统计科目;
 *        - remark: 科目描述;
 *        - bizType: 业务类型，Expense：费用，Income：收入，Cost：成本，Other：其他;
 *        - isSupport: 是否支持实时查询，1：支持，0：不支持;
 *        - dimension: 统计维度，Shop：店铺，Anchor：主播，Sku：单品;
 *      - children:
 *        - classId: ;
 *        - classCode: ;
 *        - className: ;
 *        - item:
 *          - itemId: ;
 *          - itemCode: ;
 *          - itemName: ;
 *          - isDefault: ;
 *          - type: ;
 *          - remark: ;
 *          - bizType: ;
 *          - isSupport: ;
 *          - dimension: ;
 *        - children: ;
 */
export const getCostClassItem = (params) => axios.get('/v1/cost/class/item', params)

/**
 * 最新费用项列表查询接口
 * @param {object} params
 * @param {number} params.pageNo 请求页码
 * @param {number} params.pageSize 每页条数
 * @param {string} [params.itemCode] 费用项编码，支持模糊搜索
 * @param {string} [params.itemName] 费用项名称，支持模糊搜索
 * @param {number} [params.status] 状态，1-有效，0-无效
 * @param {string} [params.type] 类型，Default：普通，CustomFormula：自定义公式
 * @param {number} [params.isDefault] 是否默认，1-是，0-否
 * @returns {Promise<{
 *   total: number;
 *   pages: number;
 *   records: {
 *     id: string;
 *     itemCode: string;
 *     itemName: string;
 *     isDefault: number;
 *     classId: string;
 *     className: string;
 *     status: number;
 *     type: string;
 *     createTime: Date;
 *     updateTime: Date;
 *   }[];
 * }>} **data**
 *    - total: 总条数;
 *    - pages: 总页数;
 *    - records:
 *      - id: 费用项ID;
 *      - itemCode: 费用项编码;
 *      - itemName: 费用项名称;
 *      - isDefault: 是否默认，1-是，0-否;
 *      - classId: 所属费用分类ID;
 *      - className: 所属费用分类名称;
 *      - status: 状态，1-有效，0-无效;
 *      - type: 类型，Default：普通，CustomFormula：自定义公式;
 *      - createTime: 创建时间;
 *      - updateTime: 修改时间;
 */
export const getItemList = (params) => axios.get('/v1/cost/class/item/list', params)

/**
 * 自定义公式费用项配置列表查询接口
 * @param {object} params
 * @param {string} params.itemId 费用项ID，多个以英文逗号分隔
 * @param {string} [params.shopNo] 店铺ID，多个以英文逗号分隔
 * @returns {Promise<{
 *   id: string;
 *   shopNo: string;
 *   shopName: string;
 *   platformId: number;
 *   platformName: string;
 *   itemId: string;
 *   formulaCode: string;
 *   formulaName: string;
 *   formulaRule: string;
 *   shopCode: string;
 *   anchorId: string;
 *   anchorCode: string;
 *   anchorName: string;
 * }[]>} **返回**
 *    - id: ID;
 *    - shopNo: 店铺ID;
 *    - shopName: 店铺名称;
 *    - platformId: 平台ID;
 *    - platformName: 平台名称;
 *    - itemId: 费用项ID;
 *    - formulaCode: 自定义公式编码;
 *    - formulaName: 自定义公式名称;
 *    - formulaRule: 公式规则;
 *    - shopCode: 店铺编码;
 *    - anchorId: 主播ID;
 *    - anchorCode: 主播编码;
 *    - anchorName: 主播名称;
 */
export const getFormulaList = (params) => axios.get('/v1/cost/shop/item/formula/list', params)

/**
 * 自定义公式费用项详情查询接口
 * @param {object} params
 * @param {string} [params.id] ID（id与shopNo&itemId必传其一）
 * @param {string} [params.shopNo] 店铺ID（id与shopNo&itemId必传其一）
 * @param {string} [params.itemId] 费用项ID（id与shopNo&itemId必传其一）
 * @returns {Promise<{
 *   id: string;
 *   shopNo: string;
 *   shopName: string;
 *   platformId: number;
 *   platformName: string;
 *   itemId: string;
 *   formulaCode: string;
 *   formulaName: string;
 *   formulaRule: string;
 *   shopCode: string;
 *   anchorId: string;
 *   anchorCode: string;
 *   anchorName: string;
 * }>} **返回**
 *    - id: ID;
 *    - shopNo: 店铺ID;
 *    - shopName: 店铺名称;
 *    - platformId: 平台ID;
 *    - platformName: 平台名称;
 *    - itemId: 费用项ID;
 *    - formulaCode: 自定义公式编码;
 *    - formulaName: 自定义公式名称;
 *    - formulaRule: 公式规则;
 *    - shopCode: 店铺编码;
 *    - anchorId: 主播ID;
 *    - anchorCode: 主播编码;
 *    - anchorName: 主播名称;
 */
export const getItemFormula = (params) => axios.get('/v1/cost/shop/item/formula', params)

/**
 * 自定义公式费用项配置删除接口
 * @param {object} params
 * @param {string} params.id ID，多个以英文逗号分隔
 * @returns {Promise<{
 * }>} **返回**
 */
export const delItemFormula = (params) => axios.del('/v1/cost/shop/item/formula', params)

/**
 * 自定义公式费用项配置添加接口
 * @param {object} params
 * @param {string} params.shopNo 店铺编码，多个以英文逗号分隔
 * @param {string} params.itemId 费用项ID
 * @param {string} params.formulaCode 自定义公式编码
 * @param {string} params.formulaName 自定义公式名称
 * @param {string} params.formulaRule 公式规则
 * @param {string} [params.anchorId] 主播ID，多个以英文逗号分隔
 * @returns {Promise<{
 * }>} **返回**
 */
export const postItemFormula = (params) => axios.post('/v1/cost/shop/item/formula', params)

/**
 * 自定义公式费用项配置编辑接口
 * @param {object} params
 * @param {string} params.id ID，多个以英文逗号分隔
 * @param {string} params.formulaCode 自定义公式编码
 * @param {string} params.formulaName 自定义公式名称
 * @param {string} params.formulaRule 公式规则
 * @returns {Promise<{
 * }>} **返回**
 */
export const putItemFormula = (params) => axios.put('/v1/cost/shop/item/formula', params)
