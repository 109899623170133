/*
 * @Description: 登录相关
 * @Author: yitong
 * @Date: 2021-11-24 10:58:50
 * @LastEditors: yitong
 * @LastEditTime: 2021-11-24 11:17:27
 */

import axios from '@/utils/axios'

/**
 * 获取验证码接口(图形验证码)
 * https://www.eolink.com/share/project/api/detail?groupID=-1&apiID=5548789&shareCode=hQQ9HE&shareToken=$2y$10$VVFZd6TbnCxhaoyy~2FnDiI.zRQbAElEI31sZEkkTCje7x2ai3omXBW&shareID=262525
 */
export const getOauthCaptcha = () => axios.get('/tank-auth/oauth/captcha')

/**
 * 账号密码登录接口
 * @param {object} params 参数
 * @param {string} params.tenantId 租户ID
 * @param {string} params.username 用户名
 * @param {string} params.password 密码（md5加密后）
 * @param {string} params.grant_type 授权方式，固定值：captcha
 * @param {string} params.scope 授权范围，固定值：all
 * @param {string} params.type 类型，固定值：account
 */
export const postOauthToken = (params) =>
  axios.post(
    '/tank-auth/oauth/token',
    {},
    {
      params,
      headers: {
        'Captcha-Code': params.code,
        'Captcha-Key': params.key
      }
    }
  )

/**
 * 根据域名查询信息接口
 * @param {object} params
 * @param {string} params.domain 域名
 * @returns {Promise<{
 *   tenantId: string;
 *   tenantName: string;
 *   backgroundUrl: string;
 *   appKey: string;
 * }>} **data**
 *    - tenantId: 租户ID;
 *    - tenantName: 租户名称;
 *    - backgroundUrl: 背景图片;
 *    - appKey: 租户创建的应用appKey;
 */
export const getDomainInfo = (params) => axios.get('/v1/domain/info', { params })

/**
 * 账号密码登录接口
 * @param {object} params
 * @param {string} params.account 账号
 * @param {string} params.password 密码（加密后）
 * @param {string} params.grantType 授权方式，固定值：captcha
 * @param {string} params.scope 授权范围，固定值：all
 * @param {string} params.type 类型，固定值：account
 * @returns {Promise<{
 *   tenantId: string
 *   tenantName: string
 *   userId: string
 *   accessToken: string
 *   tokenType: string
 *   refreshToken: string
 *   expiresIn: string
 *   scope: string
 *   jti: string
 *   license: string
 * }>} ** 返回体 **
 *   - tenantId: 租户ID
 *   - tenantName: 租户名称
 *   - userId: 用户ID
 *   - accessToken: 访问token
 *   - tokenType: token类型
 *   - refreshToken: 刷新token
 *   - expiresIn: 有效时长
 *   - scope: 授权范围
 *   - jti: jti标志
 *   - license: 许可证
 */
export const postUserLogin = (params) => axios.post('/v1/user/login', params)
