export const getBoolean = (value) => {
  switch (typeof value) {
    case 'undefined':
      return true
    case 'function':
      return value()
    default:
      return value
  }
}
