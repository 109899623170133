export const AUTO_TYPE = {
  DETAIL_MODAL: 'DETAIL_MODAL',
  BUSINESS_LIST: 'BUSINESS_LIST',
  BUSINESS_DETAIL: 'BUSINESS_DETAIL',
  BUSINESS_DETAIL_BY_RECORD: 'BUSINESS_DETAIL_BY_RECORD',
  RENEWAL_SERVICE: 'RENEWAL_SERVICE',
  RENEWAL_FOLLOW_UP: 'RENEWAL_FOLLOW_UP',
  ADD_BUSINESS: 'ADD_BUSINESS',
  CONFIRM_ADD_FOLLOW: 'CONFIRM_ADD_FOLLOW',
  ADD_FOLLOW: 'ADD_FOLLOW',
}

export default {
  namespaced: true,
  state: {
    autoProcess: [],
    businessRecord: {},
  },
  getters: {
    autoProcess: (state) => state.autoProcess,
  },
  mutations: {
    SET_AUTO_PROCESS(state, autoProcess) {
      state.autoProcess = autoProcess
    },
    SHIFT_AUTO_PROCESS(state) {
      return state.autoProcess.shift()
    },
    SET_BUSINESS_RECORD(state, record) {
      state.businessRecord = record
    },
  },
}
