<template>
  <div class="__wm">
    <slot />
  </div>
</template>

<script>
import canvasWM from './__canvasWM'
import { mapGetters } from 'vuex'

export default {
  name: 'WaterMark',
  computed: mapGetters(['userInfo']),

  mounted() {
    const { name = '', account = '' } = this.userInfo

    if (name && account) {
      canvasWM({
        // @ts-ignore
        container: this.$el,
        content: `${name}@云山快仓${account.substr(-4)}`,
        width: `${300 * 0.75}px`,
        height: `${220 * 0.75}px`
      })
    }
  },

  beforeDestroy() {}
}
</script>

<style>
.__wm {
  position: fixed !important;
  height: 100vh !important;
  width: 100vw !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  opacity: 0.8;
  z-index: 9999 !important;
}
</style>
