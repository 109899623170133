/**
 * 项目默认配置项
 */

module.exports = {
  title: 'CRM系统',
  clientId: 'yuncang', // 客户端id
  clientSecret: 'yuncang_secret', // 客户端密钥
  namespace: 'shop', // 命名空间
  encrypted: process.env.NODE_ENV === 'production', // 本地缓存是否使用加密
  encryptedKey: 'shop_admin:shop_secret',

  headerHeight: 48, // 头部导航高度
  tabsHeight: 40, // 头部tab栏高度
  hideTabs: true, // 隐藏头部tab栏
  fixedHeader: true, // 固定 Header
  fixedSidebar: true, // 固定左侧菜单栏
  navTheme: 'light', // theme for nav menu
  colorWeak: false,
  contentWidth: 'Fluid', // layout of content: `Fluid` or `Fixed`, only works when layout is topmenu
  siderMinWidth: 64, // 侧边栏最小宽度，合起来时的宽度
  siderWidth: 220, // 侧边栏宽度，展开时的宽度
  siderCollapsed: false, // 侧边栏是否收起
  hideCopyButton: false,
  hideHintAlert: false,
  layout: 'sidemenu',
  primaryColor: '#2483FF',
  theme: 'light',
  menu: {
    locale: false
  },
  multiTab: false,
  // 字体预览 https://at.alicdn.com/t/project/2787014/32204e3f-3fc2-4101-9746-ba7e31682058.html?spm=a313x.7781069.1998910419.83
  iconfontUrl: '//at.alicdn.com/t/font_2787014_2btlymmyvs5.js',
  production: process.env.NODE_ENV === 'production' && process.env.VUE_APP_PREVIEW !== 'true',
  // 福瑞达泛微OA域名
  fwDomain: ''
}
