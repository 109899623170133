/**
 * @description 线索管理
 */
import axios from '@/utils/axios'

/**
 * 新增线索
 * @param {object} params
 * @param {string} params.name 线索名称
 * @param {string} [params.alias] 简称
 * @param {string} [params.defaultBrand] 默认品牌
 * @param {string} [params.brand] 品牌，多个品牌逗号分隔
 * @param {number} [params.insertType] 提交类型： 默认0 不认领，1 认领到事业部，2 认领到业务部，3 认领到个人 ，4 确认有效
 * @param {string} [params.industry] 客户行业：dictCode: leadsIndustry
 * @param {string} [params.customerType] 客户类型：dictCode: customerType'
 * @param {string} [params.address] 客户地区，用-分隔
 * @param {string} [params.detailAddress] 详细地址
 * @param {string} [params.customerLevel] 客户等级：dictCode: customerLevel
 * @param {string} [params.customerValue] 客户价值：dictCode: customerValue
 * @param {string} [params.customerLoyalty] 客户忠诚度：dictCode: customerLoyalty
 * @param {string} [params.source] 线索来源：dictCode: leadsSource
 * @param {string} [params.remark] 备注
 * @param {string} [params.legalPerson] 法定代表人
 * @param {string} [params.logisticsCompany] 原物流合作公司:dictCode: crmLogisticsCompany
 * @param {string} [params.storageMethod] 原仓储方式: dictCode: crmStorageMethod
 * @param {string} [params.establishment] 公司成立日期
 * @param {string} [params.creditCode] 社会统一信用代码
 * @param {string} [params.organizationCode] 组织机构代码
 * @param {string} [params.companyType] 公司类型
 * @param {string} [params.invoiceTitle] 发票抬头
 * @param {string} [params.taxNumber] 纳税人识别号
 * @param {number} [params.productNum] 备案产品数
 * @param {string} [params.produceCompany] 生产企业
 * @param {string} [params.produceCompanyAddress] 生产企业地址
 * @param {string} [params.produceCompanyReal] 实际生产企业
 * @param {object[]} [params.leadsFiles] 线索附件，限5个，可上传 .pdf / .doc /.xlsx / .jpg/ .png 等文件，且文件大小不超过 3 m
 * @param {string} params.leadsFiles.name 附件名称
 * @param {string} params.leadsFiles.patch 附件路径
 * @param {string} params.leadsFiles.fileType 附件类型
 * @param {object[]} params.contacts 联系人信息
 * @param {string} params.contacts.name 联系人名称
 * @param {string} [params.contacts.position] 联系人职位
 * @param {string} params.contacts.mobile 联系人手机号
 * @param {string} [params.contacts.wechat] 联系人微信
 * @param {string} [params.contacts.qqAccount] 联系人QQ
 * @param {string} [params.contacts.email] 联系人邮箱
 * @param {object[]} [params.productList] 产品信息
 * @param {string} [params.productList.productType] 产品类目：dictCode: productType
 * @param {string} [params.productList.productSku] 产品SKU
 * @param {string} [params.productList.avgWeight] 产品均重
 * @param {string} [params.productList.dailyOrders] 日均单量
 * @param {object[]} [params.shopList] 店铺信息
 * @param {string} [params.shopList.platformName] 平台名称
 * @param {string} [params.shopList.name] 店铺名称
 * @param {string} [params.shopList.website] 网址
 */
export const postLeadsAdd = (params) => axios.post('/v1/leads/add', params)

/**
 * 新增商机/问题
 * @param {object} params
 * @param {string} params.leadsId 线索ID
 * @param {string} params.name 商机名称
 * @param {string} params.content 商机内容
 * @param {string} params.type 类型：1 商机， 2 问题
 * @param {string} params.managementId 经营信息Id
 * @param {object[]} [params.fileList] 附件集合
 * @param {string} [params.fileList.name] 附件名称
 * @param {string} [params.fileList.path] 附件路径
 * @param {string} [params.fileList.fileType] 文件类型,file,img等
 * @param {string} [params.fileList.type] 附件类型: 1 线索附件，2 商机附件，3 跟进记录附件， 4 合同附件
 */
export const postBusinessAdd = (params) => axios.post('/v1/leads/business/add', params)

/**
 * 商机/问题详情查询
 * @param {object} params
 * @param {string} params.id 商机ID
 * @returns {Promise<{
 *   id: number
 *   leadsId: number
 *   leadsName: string
 *   name: string
 *   content: string
 *   status: number
 *   followUpNum: number
 *   lastTime: string
 *   fileList: {
 *     name: string
 *     path: string
 *     fileType: string
 *     type: string
 *   }[]
 * }>} ** 返回体 **
 *   - id: 商机ID
 *   - leadsId: 线索Id
 *   - leadsName: 线索名称
 *   - name: 商机名称
 *   - content: 商机详情
 *   - status: 商机状态：0 跟进中，1 赢单，2 输单/问题状态：0 解决中，1已解决
 *   - followUpNum: 跟进数量
 *   - lastTime: 最后跟进时间
 *   - fileList: 附件集合
 *     - name: 附件名称
 *     - path: 附件下载地址
 *     - fileType: 附件类型，file,img等
 *     - type: 附件类型: 1 线索附件，2 商机附件，3 跟进记录附件， 4 合同附件
 */
export const getBusinessDetail = (params) => axios.get('/v1/leads/business/detail', { params })

/**
 * 商机/问题列表查询
 * @param {object} params
 * @param {string} [params.leadsId] 线索ID
 * @param {string} [params.leadsName] 线索名称
 * @param {string} [params.name] 商机名称
 * @param {number} [params.status] 商机状态：0 跟进中，1 赢单，2 输单
 * @param {string} params.type 类型： 商机 1 ，问题 2
 * @param {number} params.pageSize 分页大小
 * @param {number} params.pageNo 页码
 * @param {string} params.divisionDept 事业部ID
 * @param {string} params.businessDept 业务部ID
 * @param {string} params.selectUser 个人ID
 * @param {string} [params.beginTime] 开始时间
 * @param {string} [params.endTime] 结束时间
 * @param {string} [params.collaboratorId ] 协作人Id
 * @returns {Promise<{
 *   current: number
 *   total: string
 *   pages: number
 *   records: {
 *     id: number
 *     leadsId: string
 *     leadsName: string
 *     name: string
 *     content: string
 *     followUpNum: number
 *     followUpStatus: string
 *     statusDescription: string
 *     managementId: string
 *     managementName: string
 *     lastTime: string
 *     createUserName: string
 *     createTime: string
 *   }[]
 *   size: number
 * }>} ** 返回体 **
 *   - current: 当前页码
 *   - total: 总条数
 *   - pages: 总页数
 *   - records:
 *     - id: 商机Id
 *     - leadsId: 线索ID
 *     - leadsName: 线索名称
 *     - name: 商机名称
 *     - content: 商机内容
 *     - followUpNum: 跟进数量
 *     - followUpStatus: 跟进状态 followUpStatus ：0无进展，1跟进接洽，4方案阶段，5内部评估，6对接合同，7赢单，8输单，9无效
 *     - statusDescription: 跟进状态描述：跟进中1 看仓、 2 货品了解、3 拜访、
 *     - managementId: 客户经营ID
 *     - managementName: 客户经营名称
 *     - lastTime: 最后跟进时间
 *     - createUserName: 创建者
 *     - createTime: 创建时间
 *   - size: 分页大小
 */
export const getBusinessPage = (params) => axios.get('/v1/leads/business/page', { params })

/**
 * 编辑商机/问题
 * @param {object} params
 * @param {number} params.id 商机ID
 * @param {string} params.name 商机名称
 * @param {string} params.content 商机内容
 * @param {string} params.status 商机状态：0 跟进中，1 赢单，2 输单
 * @param {object[]} [params.fileList] 附件集合
 * @param {string} params.fileList.name 附件名称
 * @param {string} params.fileList.path 附件路径
 * @param {string} params.fileList.fileType 文件类型,file,img等
 * @param {string} params.fileList.type 附件类型: 1 线索附件，2 商机附件，3 跟进记录附件， 4 合同附件
 */
export const postBusinessUpdate = (params) => axios.post('/v1/leads/business/update', params)

/**
 * 联系人列表查询
 * @param {object} params
 * @param {string} [params.leadsName] 客户名称
 * @param {string} [params.name] 联系人名称
 * @param {string} params.pageSize 分页大小
 * @param {string} params.pageNo 页码
 * @returns {Promise<{
 *   current: number
 *   total: string
 *   pages: number
 *   records: {
 *     id: number
 *     leadsId: number
 *     leadsName: string
 *     name: string
 *     mobile: string
 *     wechat: string
 *     qqAccount: string
 *     email: string
 *     position: string
 *     remark: string
 *     updateTime: string
 *   }[]
 *   size: number
 * }>} ** 返回体 **
 *   - current: 当前页码
 *   - total: 总条数
 *   - pages: 总页数
 *   - records:
 *     - id: 联系人ID
 *     - leadsId: 线索Id
 *     - leadsName: 线索名称
 *     - name: 联系人名称
 *     - mobile: 联系人手机
 *     - wechat: 微信
 *     - qqAccount: QQ
 *     - email: 邮箱
 *     - position: 职务
 *     - remark: 备注
 *     - updateTime: 更新时间
 *   - size: 分页大小
 */
export const getContactsPage = (params) => axios.get('/v1/leads/contacts/page', { params })

/**
 * 线索详情查看
 * @param {object} params
 * @param {string} params.id 线索ID
 * @returns {Promise<{
 *   id: string
 *   name: string
 *   alias: string
 *   defaultBrand: string
 *   brand: string
 *   industry: string
 *   customerType: string
 *   address: string
 *   detailAddress: string
 *   customerLevel: string
 *   customerValue: string
 *   customerLoyalty: string
 *   source: string
 *   customerStatus: string
 *   remark: string
 *   legalPerson: string
 *   establishment: string
 *   creditCode: string
 *   organizationCode: string
 *   companyType: string
 *   invoiceTitle: string
 *   taxNumber: string
 *   ownerUserId: number
 *   ownerUserName: string
 *   divisionDeptId: string
 *   divisionDeptName: string
 *   businessDeptId: string
 *   businessDeptName: string
 *   createTime: string
 *   screenTime: string
 *   developTime: string
 *   sigingTime: string
 *   logisticsCompany: string
 *   storageMethod: string
 *   productNum: number
 *   produceCompany: string
 *   produceCompanyAddress: string
 *   produceCompanyReal: string
 *   integrityRate: string
 *   leadsFiles: {
 *     id: string
 *     name: string
 *     patch: string
 *     fileType: string
 *   }[]
 *   contacts: {
 *     id: string
 *     name: {
 *     }
 *     position: string
 *     mobile: string
 *     wechat: string
 *     email: string
 *     qqAccount: string
 *   }[]
 *   productList: {
 *     id: string
 *     productType: string
 *     productSku: string
 *     avgWeight: string
 *     dailyOrders: string
 *   }[]
 *   contractList: {
 *     id: string
 *     name: string
 *     contractNo: string
 *     startTime: string
 *     endTime: string
 *     contractFiles: {
 *       id: string
 *       name: string
 *       patch: string
 *       fileType: string
 *     }[]
 *   }[]
 * }>} ** 返回体 **
 *   - id: 线索ID
 *   - name: 线索名称
 *   - alias: 简称
 *   - defaultBrand: 默认品牌
 *   - brand: 品牌，多个品牌逗号分隔
 *   - industry: 客户行业：dictCode: leadsIndustry
 *   - customerType: 客户类型：dictCode: customerType'
 *   - address: 客户地区，用-分隔
 *   - detailAddress: 详细地址
 *   - customerLevel: 客户等级：dictCode: customerLevel
 *   - customerValue: 客户价值：dictCode: customerValue
 *   - customerLoyalty: 客户忠诚度：dictCode: customerLoyalty
 *   - source: 线索来源：dictCode: leadsSource
 *   - customerStatus: 客户状态：dictCode: crmCustomerStatus
 *   - remark: 备注
 *   - legalPerson: 法定代表人
 *   - establishment: 公司成立日期
 *   - creditCode: 社会统一信用代码
 *   - organizationCode: 组织机构代码
 *   - companyType: 公司类型
 *   - invoiceTitle: 发票抬头
 *   - taxNumber: 纳税人识别号
 *   - ownerUserId: 管户人ID
 *   - ownerUserName: 管户人姓名
 *   - divisionDeptId: 事业部ID
 *   - divisionDeptName: 事业部名称
 *   - businessDeptId: 业务部ID
 *   - businessDeptName: 业务部名称
 *   - createTime: 创建时间
 *   - screenTime: 转有效线索时间
 *   - developTime: 转开发用户时间
 *   - sigingTime: 签约客户转运维时间
 *   - logisticsCompany: 原物流合作公司:dictCode: crmLogisticsCompany
 *   - storageMethod: 原仓储方式: dictCode: crmStorageMethod
 *   - productNum: 备案产品数
 *   - produceCompany: 生产企业
 *   - produceCompanyAddress: 生产企业地址
 *   - produceCompanyReal: 实际生产企业
 *   - integrityRate: 完整率
 *   - leadsFiles: 线索附件，限5个，可上传 .pdf / .doc /.xlsx / .jpg/ .png 等文件，且文件大小不超过 3 m
 *     - id: 附件Id
 *     - name: 附件名称
 *     - patch: 附件路径
 *     - fileType: 附件类型
 *   - contacts: 联系人信息
 *     - id: 联系人ID
 *     - name: 联系人名称
 *     - position: 联系人职位
 *     - mobile: 联系人手机号
 *     - wechat: 联系人微信
 *     - email: 联系人邮箱
 *     - qqAccount: 联系人QQ
 *   - productList: 产品信息
 *     - id: 产品ID
 *     - productType: 产品类目：dictCode: productType
 *     - productSku: 产品SKU
 *     - avgWeight: 产品均重
 *     - dailyOrders: 日均单量
 *   - contractList: 合同信息
 *     - id: 合同ID
 *     - name: 合同名称
 *     - contractNo: 合同编码
 *     - startTime: 合同开始时间
 *     - endTime: 合同结束时间
 *     - contractFiles: 合同附件
 *       - id: 附件Id
 *       - name: 附件名称
 *       - patch: 附件路径
 *       - fileType: 附件类型
 */
export const getLeadsDetail = (params) => axios.get('/v1/leads/detail', { params })

/**
 * 线索附件列表查询
 * @param {object} params
 * @param {string} [params.type] 附件类型: 1 线索附件，2 商机附件
 * @param {string} params.leadsId 线索ID
 * @param {string} params.pageSize 分页大小
 * @param {string} params.pageNo 页码
 * @returns {Promise<{
 *   current: number
 *   total: string
 *   pages: number
 *   records: {
 *     id: number
 *     name: string
 *     path: string
 *     fileType: string
 *     type: number
 *     assId: number
 *     assIdName: string
 *     createUser: number
 *     createName: string
 *     createTime: string
 *   }[]
 *   size: number
 * }>} ** 返回体 **
 *   - current: 当前页码
 *   - total: 总条数
 *   - pages: 总页数
 *   - records:
 *     - id: 附件ID
 *     - name: 附件名称
 *     - path: 附件路径
 *     - fileType: 文件类型
 *     - type: 附件类型: 1 线索附件，2 商机附件，3 跟进记录附件， 4 合同附件',5 问题附件
 *     - assId: 关联对象ID
 *     - assIdName: 关联对象名称
 *     - createUser: 创建人ID
 *     - createName: 创建人名称
 *     - createTime: 上传时间
 *   - size: 分页大小
 */
export const getFileList = (params) => axios.get('/v1/leads/file/list', { params })

/**
 * 附件列表查询
 * @param {object} params
 * @param {string} [params.type] 附件类型: 1 线索附件，2 商机附件，3 跟进记录附件， 4 合同附件
 * @param {number} params.leadId 线索id
 * @param {string} params.pageSize 分页大小
 * @param {string} params.pageNo 页码
 * @returns {Promise<{
 *   current: number
 *   total: string
 *   pages: number
 *   records: {
 *     id: string
 *     name: string
 *     assId: string
 *     type: string
 *     path: string
 *     fileType: string
 *     createTime: string
 *     createUserName: string
 *     createUser: string
 *   }[]
 *   size: number
 * }>} ** 返回体 **
 *   - current: 当前页码
 *   - total: 总条数
 *   - pages: 总页数
 *   - records:
 *     - id: 附件id
 *     - name: 附件名称
 *     - assId: 关联ID
 *     - type: 附件类型: 1 线索附件，2 商机附件，3 跟进记录附件， 4 合同附件
 *     - path: 文件真实路径
 *     - fileType: 文件类型,file,img
 *     - createTime: 上传时间
 *     - createUserName: 上传者
 *     - createUser: 创建用户
 *   - size: 分页大小
 */
export const getFilesPage = (params) => axios.get('/v1/leads/files/page', { params })

/**
 * 跟进记录添加
 * @param {object} params
 * @param {string} params.leadsId 线索ID
 * @param {string} [params.businessId] 商机ID
 * @param {string} params.contactsName 联系人名称
 * @param {string} params.mobile 联系电话
 * @param {string} [params.wechat] 微信号
 * @param {string} params.followUpTime 跟进时间
 * @param {string} params.type 跟进类型: 1 线索跟进，2 商机跟进
 * @param {string} [params.process] 跟进环节: dictCode: followUpProcess
 * @param {string} params.method 跟进方式：dictCode: followUpMethod
 * @param {string} params.title 标题
 * @param {string} params.detail 跟进内容
 * @param {object[]} params.fileList 附件集合
 * @param {string} params.fileList.name 附件名称
 * @param {string} params.fileList.path 附件路径
 * @param {string} params.fileList.fileType 附件类型，file,img等
 * @param {string} params.fileList.type 3 跟进记录附件
 */
export const postUpAdd = (params) => axios.post('/v1/leads/follow/up/add', params)

/**
 * 跟进详情查询
 * @param {object} params
 * @param {string} params.id 跟进记录Id
 * @returns {Promise<{
 *   id: number
 *   leadsId: number
 *   leadsName: string
 *   businessId: string
 *   businessName: string
 *   contactsName: string
 *   mobile: string
 *   wechat: string
 *   followUpTime: string
 *   type: number
 *   process: string
 *   method: string
 *   title: string
 *   detail: string
 *   createUser: string
 *   createUserName: string
 *   fileList: {
 *     name: string
 *     path: string
 *     fileType: string
 *   }[]
 * }>} ** 返回体 **
 *   - id: 跟进记录ID
 *   - leadsId: 线索Id
 *   - leadsName: 线索名称
 *   - businessId: 商机ID
 *   - businessName: 商机名称
 *   - contactsName: 联系人名称
 *   - mobile: 联系人电话
 *   - wechat: 微信号
 *   - followUpTime: 跟进时间
 *   - type: 跟进类型: 1 线索跟进，2 商机跟进
 *   - process: 跟进环节: dictCode: followUpProcess
 *   - method: 跟进方式：dictCode: followUpMethod
 *   - title: 标题
 *   - detail: 跟进内容
 *   - createUser: 跟进人Id
 *   - createUserName: 跟进人名称
 *   - fileList: 附件集合
 *     - name: 附件名称
 *     - path: 附件路径
 *     - fileType: 文件类型，file，img等
 */
export const getUpDetail = (params) => axios.get('/v1/leads/follow/up/detail', { params })

/**
 * 跟进记录查询
 * @param {object} params
 * @param {string} [params.leadsId] 线索ID
 * @param {string} [params.userId] 用户Id
 * @param {string} params.businessId 商机id
 * @param {string} [params.method] 跟进方式：dictCode: followUpMethod
 * @param {string} [params.process] 跟进环节: dictCode: followUpProcess
 * @param {string} [params.title] 跟进标题
 * @param {string} [params.businessName] 商机名称
 * @param {string} [params.leadsName] 客户名称(线索)
 * @param {string} params.pageSize 分页大小
 * @param {string} params.pageNo 页码
 * @param {string} [params.type] 跟进类型: 1 线索跟进，2 商机跟进， 3 问题跟进
 * @returns {Promise<{
 *   current: number
 *   total: string
 *   pages: number
 *   records: {
 *     id: string
 *     leadsId: string
 *     leadsName: string
 *     businessId: string
 *     businessName: string
 *     contactsName: string
 *     mobile: string
 *     wechat: string
 *     followUpTime: string
 *     type: string
 *     process: string
 *     method: string
 *     title: string
 *     detail: string
 *     createUser: string
 *     createUserName: string
 *     updateTime: string
 *   }[]
 *   size: number
 * }>} ** 返回体 **
 *   - current: 当前页码
 *   - total: 总条数
 *   - pages: 总页数
 *   - records:
 *     - id: 跟进记录ID
 *     - leadsId: 线索Id
 *     - leadsName: 线索名称
 *     - businessId: 商机id
 *     - businessName: 商机名称
 *     - contactsName: 联系人名称
 *     - mobile: 联系人电话
 *     - wechat: 微信号
 *     - followUpTime: 跟进时间
 *     - type: 跟进类型: 1 线索跟进，2 商机跟进， 3 问题跟进
 *     - process: 跟进环节: dictCode: followUpProcess
 *     - method: 跟进方式：dictCode: followUpMethod
 *     - title: 标题
 *     - detail: 跟进内容
 *     - createUser: 跟进人Id
 *     - createUserName: 跟进人名称
 *     - updateTime: 跟进时间
 *   - size: 分页大小
 */
export const getUpList = (params) => axios.get('/v1/leads/follow/up/list', { params })

/**
 * 跟进记录统计
 * @param {object} params
 * @param {string} [params.leadsId] 线索Id
 * @param {string} [params.userId] 用户ID
 * @param {string} [params.type] 跟进动作: 1 信息录入、2 联系对接、3 商机跟进、4 客户维护
 * @returns {Promise<{
 *   type: string
 *   method: string
 *   count: string
 * }[]>} ** 返回体 **
 *   - type: 跟进动作: 1 信息录入、2 联系对接、3 商机跟进、4 客户维护
 *   - method: 跟进方式：dictCode: followUpMethod
 *   - count: 跟进数量
 */
export const getUpStat = (params) => axios.get('/v1/leads/follow/up/stat', { params })

/**
 * 线索导入
 * @param {object} params
 * @param {string} params.file 导入文件
 */
export const postLeadsImport = (params) => axios.postForm('/v1/leads/import', params)

/**
 * 指派线索
 * @param {object} params
 * @param {string} params.leadsIds 线索ID，多个Id逗号分隔
 * @param {string} params.ownerUserId 高级经理ID
 */
export const postOperateAssign = (params) => axios.post('/v1/leads/operate/assign', params)

/**
 * 认领线索
 * @param {object} params
 * @param {string} params.leadsIds 线索ID，多个线索使用逗号分隔
 */
export const postOperateClaim = (params) => axios.post('/v1/leads/operate/claim', params)

/**
 * 维护客户状态变更
 * @param {object} params
 * @param {string} params.leadsIds 线索Id
 * @param {string} params.customerStatus 客户状态：dictCode: crmCustomerStatus
 */
export const postStatusUpdate = (params) =>
  axios.post('/v1/leads/operate/customer/status/update', params)

/**
 * 客户开发申请
 * @param {object} params
 * @param {string} params.leadsIds 线索ID，多个ID逗号分隔
 */
export const postOperateDevelop = (params) => axios.post('/v1/leads/operate/develop', params)

/**
 * 合同洽谈中
 * @param {object} params
 * @param {string} params.leadsIds 线索Id
 */
export const postOperateNegotiation = (params) =>
  axios.post('/v1/leads/operate/negotiation', params)

/**
 * 管户人变更
 * @param {object} params
 * @param {string} params.leadsIds 线索Id，多个ID以逗号分隔
 * @param {string} params.ownerUserId 管户人Id
 */
export const postOwnerUpdate = (params) => axios.post('/v1/leads/operate/owner/update', params)

/**
 * 退回线索
 * @param {object} params
 * @param {string} params.leadId 线索id
 */
export const postOperateReturn = (params) =>
  axios.post('/v1/leads/operate/return', null, { params })

/**
 * 转有效线索
 * @param {object} params
 * @param {string} params.leadsIds 线索ID，多个线索则使用逗号分隔
 */
export const postOperateScreen = (params) => axios.post('/v1/leads/operate/screen', params)

/**
 * 转客户运维(转专属)
 * @param {object} params
 * @param {string} params.leadsIds 线索ID，多个Id逗号分隔
 */
export const postOperateSiging = (params) => axios.post('/v1/leads/operate/siging', params)

/**
 * 线索-货主关联
 * @param {object} params
 * @param {number} params.ownerId
 * @param {number} params.leadsId
 */
export const postOwnerAssociation = (params) => axios.post('/v1/leads/owner/association', params)

/**
 * 线索/客户列表分页查询
 * @param {object} params
 * @param {string} params.status 线索状态：0 未确认有效，1 已确认有效（待认领），2 已认领 ，3 开发中，4 洽谈合同中，5 已合作客户
 * @param {string} [params.cliamStatus] 认领状态：0 未认领，1 事业部认领，2 业务部认领，3高级经理认领
 * @param {string} [params.industry] 客户行业：dictCode: leadsIndustry
 * @param {string} [params.name] 客户名称
 * @param {string} [params.source] 线索来源：dictCode: leadsSource
 * @param {string} [params.address] 所属地区
 * @param {string} [params.startTime] 开始时间
 * @param {string} [params.endTime] 结束时间
 * @param {string} [params.timeType] 有时间筛选时必填，0 新增时间，1 转有效，2 转开发，3 转维护
 * @param {string} [params.score] 线索评分，区间传值,0-59；60-69；70-79；80-89；90-100；
 * @param {string} [params.customerStatus] 客户状态：dictCode: crmCustomerStatus
 * @param {string} [params.ownerUserId] 责任人（管户人）userId
 * @param {string} params.pageSize 分页大小
 * @param {string} params.pageNo 页码
 * @param {number} [params.checkRole] 1 角色动态限制(根据不同角色层级限制)，2 事业部限制，3 业务部限制，
 * @param {string} [params.createUserId] 创建人ID
 * @param {string} [params.brand] 品牌名，模糊查询
 * @returns {Promise<{
 *   current: number
 *   total: string
 *   pages: number
 *   records: {
 *     id: string
 *     name: string
 *     alias: string
 *     defaultBrand: string
 *     brand: string
 *     tagName: string
 *     industry: string
 *     customerType: string
 *     productType: string
 *     address: string
 *     ownerUserId: string
 *     ownerUserName: string
 *     divisionDeptId: string
 *     divisionDeptName: string
 *     businessDeptId: string
 *     businessDeptName: string
 *     status: number
 *     claimStatus: number
 *     source: string
 *     score: number
 *     createTime: string
 *     screenTime: string
 *     developTime: string
 *     sigingTime: string
 *     customerStatus: string
 *     createUserId: string
 *     createUserName: string
 *   }[]
 *   size: number
 * }>} ** 返回体 **
 *   - current: 当前页码
 *   - total: 总条数
 *   - pages: 总页数
 *   - records:
 *     - id: 线索Id
 *     - name: 客户名称
 *     - alias: 客户简称
 *     - defaultBrand: 默认品牌
 *     - brand: 品牌，多个品牌逗号分隔
 *     - tagName: 客户标签，多个标签逗号分隔
 *     - industry: 客户行业：dictCode: leadsIndustry
 *     - customerType: 客户类型：dictCode: customerType'
 *     - productType: 产品类目：dictCode: productType
 *     - address: 城市
 *     - ownerUserId: 管户人Id
 *     - ownerUserName: 管户人姓名
 *     - divisionDeptId: 事业部ID
 *     - divisionDeptName: 事业部名称
 *     - businessDeptId: 业务部Id
 *     - businessDeptName: 业务部名称
 *     - status: 线索状态：0 未确认有效，1 已确认有效（待认领），2 已认领 ，3 开发中，4 洽谈合同中，5 已合作客户
 *     - claimStatus: 认领状态：0 未认领，1 事业部认领，2 业务部认领，3高级经理认领
 *     - source: 线索来源：dictCode: leadsSource
 *     - score: 线索评分
 *     - createTime: 创建时间
 *     - screenTime: 转有效线索时间
 *     - developTime: 转开发客户时间
 *     - sigingTime: 签约客户转运维时间
 *     - customerStatus: 客户状态：dictCode: crmCustomerStatus
 *     - createUserId: 创建人ID
 *     - createUserName: 创建人名称
 *   - size: 分页大小
 */
export const getLeadsPage = (params) => axios.get('/v1/leads/page', { params })

/**
 * 删除线索
 * @param {object} params
 * @param {string} params.ids 按逗号拼接
 */
export const deleteLeadsRemove = (params) => axios.delete('/v1/leads/remove', { params })

/**
 * 线索导入模板下载
 */
export const postTemplateDownload = (params) =>
  axios.post('/v1/leads/template/download', params, {
    responseType: 'blob',
    originalData: true,
  })

/**
 * 线索编辑
 * @param {object} params
 * @param {string} params.id
 * @param {string} params.name 线索名称
 * @param {string} [params.alias] 简称
 * @param {string} [params.defaultBrand] 默认品牌
 * @param {string} [params.brand] 品牌，多个品牌逗号分隔
 * @param {string} [params.industry] 客户行业：dictCode: leadsIndustry
 * @param {string} [params.customerType] 客户类型：dictCode: customerType'
 * @param {string} [params.address] 客户地区，用-分隔
 * @param {string} [params.detailAddress] 详细地址
 * @param {string} [params.customerLevel] 客户等级：dictCode: customerLevel
 * @param {string} [params.customerValue] 客户价值：dictCode: customerValue
 * @param {string} [params.customerLoyalty] 客户忠诚度：dictCode: customerLoyalty
 * @param {string} [params.source] 线索来源：dictCode: leadsSource
 * @param {string} [params.remark] 备注
 * @param {string} [params.legalPerson] 法定代表人
 * @param {string} [params.logisticsCompany] 原物流合作公司:dictCode: crmLogisticsCompany
 * @param {string} [params.storageMethod] 原仓储方式: dictCode: crmStorageMethod
 * @param {string} [params.establishment] 公司成立日期
 * @param {string} [params.creditCode] 社会统一信用代码
 * @param {string} [params.organizationCode] 组织机构代码
 * @param {string} [params.companyType] 公司类型
 * @param {string} [params.invoiceTitle] 发票抬头
 * @param {string} [params.taxNumber] 纳税人识别号
 * @param {number} [params.productNum] 备案产品数
 * @param {string} [params.produceCompany] 生产企业
 * @param {string} [params.produceCompanyAddress] 生产企业地址
 * @param {string} [params.produceCompanyReal] 实际生产企业
 * @param {object[]} [params.leadsFiles] 线索附件，限5个，可上传 .pdf / .doc /.xlsx / .jpg/ .png 等文件，且文件大小不超过 3 m
 * @param {string} params.leadsFiles.id 附件ID
 * @param {string} params.leadsFiles.name 附件名称
 * @param {string} params.leadsFiles.patch 附件路径
 * @param {string} params.leadsFiles.fileType 附件类型
 * @param {object[]} params.contacts 联系人信息
 * @param {string} params.contacts.id 联系人ID
 * @param {string} params.contacts.name 联系人名称
 * @param {string} [params.contacts.position] 联系人职位
 * @param {string} params.contacts.mobile 联系人手机号
 * @param {string} [params.contacts.wechat] 联系人微信
 * @param {string} [params.contacts.qqAccount] 联系人QQ
 * @param {string} [params.contacts.email] 联系人邮箱
 * @param {object[]} [params.productList] 产品信息
 * @param {string} [params.productList.id] 产品ID
 * @param {string} [params.productList.productType] 产品类目：dictCode: productType
 * @param {string} [params.productList.productSku] 产品SKU
 * @param {string} [params.productList.avgWeight] 产品均重
 * @param {string} [params.productList.dailyOrders] 日均单量
 * @param {string} [params.productList.storageMethod] 原仓储方式: dictCode: crmStorageMethod
 * @param {string} [params.productList.logisticsCompany] 原物流合作公司:dictCode: crmLogisticsCompany
 * @param {object[]} [params.shopList] 店铺信息
 * @param {string} [params.shopList.platformName] 平台名称
 * @param {string} [params.shopList.name] 店铺名称
 * @param {string} [params.shopList.website] 网址
 */
export const postLeadsUpdate = (params) => axios.post('/v1/leads/update', params)

/**
 * 商机方案列表查询
 * @param {object} params
 * @param {string} params.businessId 商机Id
 * @returns {Promise<{
 *   programmeList: {
 *     id: number
 *     name: string
 *     seq: number
 *     remark: string
 *     createTime: string
 *     fileList: {
 *       name: string
 *       path: string
 *       fileType: string
 *       type: string
 *     }[]
 *   }
 * }>} ** 返回体 **
 *   - programmeList:
 *     - id: 方案ID
 *     - name: 方案名称
 *     - seq: 方案序号
 *     - remark: 方案创建原因
 *     - createTime: 方案创建时间YYYY-MM-DD
 *     - fileList: 方案附件
 *       - name: 附件名称
 *       - path: 附件路径
 *       - fileType: 文件类型，file，img等
 *       - type: 附件类型：1 线索附件，2 商机附件，3 跟进记录附件， 4 合同附件 , 5 问题附件， 6 经营货品， 7 方案附件 ,8 看仓/拜访
 */
export const getBusinessProgramme = (params) =>
  axios.post('/v1/leads/business/programme', null, { params })

/**
 * 跟进评论列表
 * @param {object} params
 * @param {string} params.followUpId 跟进id
 * @param {string} params.pageNo 页码
 * @param {string} params.pageSize 分页大小
 * @returns {Promise<{
 *   current: number
 *   total: string
 *   pages: number
 *   records: {
 *     operateUser: string
 *     operateName: string
 *     parentId: string
 *     content: string
 *     id: string
 *     createaTime: string
 *     followUpId: string
 *     parentUser: string
 *   }[]
 *   size: number
 * }[]>} ** 返回体 **
 *   - current: 当前页码
 *   - total: 总条数
 *   - pages: 总页数
 *   - records:
 *     - operateUser: 评论人
 *     - operateName: 评论人姓名
 *     - parentId: 父评论ID
 *     - content: 评论内容
 *     - id: 评论ID
 *     - createaTime: 评论时间
 *     - followUpId: 跟进ID
 *     - parentUser: 被回复人
 *   - size: 分页大小
 */
export const getCommentList = (params) => axios.get('/v1/leads/follow/up/comment/list', { params })

/**
 * 跟进评论新增
 * @param {object} params
 * @param {string} params.followUpId 跟进Id
 * @param {string} [params.parentId] 父评论ID
 * @param {string} params.content 评论内容
 * @param {string} [params.parentUser] 被回复人
 * @param {string} [params.parentUserName] 被回复人名称
 * @param {object[]} params.fileList 附件集合
 * @param {string} params.fileList.name 附件名称
 * @param {string} params.fileList.path 附件路径
 * @param {string} params.fileList.fileType 文件类型,file,img等
 * @param {string} params.fileList.type 附件类型: 1 线索附件，2 商机附件，3 跟进记录附件， 4 合同附件
 * @returns {Promise<{
 *   id: string
 *   content: string
 *   parentId: string
 *   operateUser: string
 *   operateName: string
 *   followUpId: string
 *   createaTime: string
 *   parentUser: string
 *   parentUserName: string
 * }[]>} ** 返回体 **
 *   - id: 评论ID
 *   - content: 评论内容
 *   - parentId: 父评论ID
 *   - operateUser: 评论人
 *   - operateName: 评论人姓名
 *   - followUpId: 跟进ID
 *   - createaTime: 评论时间
 *   - parentUser: 被回复人
 *   - parentUserName: 被回复人名称
 */
export const postCommentAdd = (params) => axios.post('/v1/leads/follow/up/comment/add', params)

/**
 * 线索导出
 * @param {object} params
 * @param {string} [params.leadIds] 线索ID,多个按逗号拼接
 */
export const getLeadsExport = (params) =>
  axios.get('/v1/leads/export', { params, responseType: 'blob', originalData: true })

/**
 * 线索协作人添加
 * @param {object} params
 * @param {string} params.userIds 用户ID，多个ID逗号分隔
 * @param {string} params.leadsIds 线索ID，多个ID逗号分隔
 */
export const postCollaboratorAdd = (params) => axios.post('/v1/leads/collaborator/add', params)

/**
 * 协作人列表
 */
export const getCollaboratorList = (params) => axios.get('/v1/leads/collaborator/list', { params })

/**
 * 协作人删除
 * @param {object} params
 * @param {string} params.ids 协作人记录ID
 */
export const deleteCollaboratorRemove = (params) =>
  axios.delete('/v1/leads/collaborator/remove', { data: params })

/**
 * 协作人变更
 * @param {object} params
 * @param {string} params.userIds 用户ID，多个ID逗号分隔
 * @param {string} params.leadsIds 线索ID，多个ID逗号分隔
 */
export const postCollaboratorUpdate = (params) =>
  axios.post('/v1/leads/collaborator/update', params)

/**
 * 商机赢单/输单判定
 * @param {object} params
 * @param {string} params.ids 商机ID，多个ID逗号分隔
 * @param {string} params.status 状态: 赢单1,输单2
 */
export const getOperateDetermine = (params) =>
  axios.get('/v1/leads/business/operate/determine', { params })

/**
 * (加盟)线索导入模板下载
 */
export const postLeagueTemplateDownload = (params) =>
  axios.post('/v1/leads/franchise/template/download', params, {
    responseType: 'blob',
    originalData: true,
  })

/**
 * (加盟)线索导入
 * @param {object} params
 * @param {string} params.file 导入文件
 */
export const postFranchiseImport = (params) => axios.postForm('/v1/leads/franchise/import', params)
