<template>
  <div class="operations__btns">
    <template v-if="setNodes('refresh')">
      <el-tooltip class="operations__btns--tooltip" content="刷新" placement="bottom-end" effect="dark">
        <el-button class="operations__btns--item" size="mini" type="text" @click="() => refresh()">
          <i class="iconfont iconicon_refresh" />
        </el-button>
      </el-tooltip>
    </template>
    <template v-if="setNodes('filter')">
      <el-popover placement="bottom" width="40" trigger="click">
        <template #reference>
          <el-tooltip class="operations__btns--tooltip" content="显隐" placement="bottom-end" effect="dark">
            <el-button class="operations__btns--item" size="mini" type="text">
              <i class="iconfont iconicon_setting" />
            </el-button>
          </el-tooltip>
        </template>

        <!-- 设置表格列可见性 -->
        <div class="operations__column--visible">
          <el-checkbox-group v-model="value">
            <el-checkbox v-for="item of tableColumns" :key="item.prop" :label="item.prop" class="operations__column--item">
              {{ item.label }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </el-popover>
    </template>
    <template v-if="setNodes('search')">
      <el-tooltip v-if="setNodes('search')" class="operations__btns--tooltip" content="搜索区显隐" placement="bottom-end" effect="dark">
        <el-button class="operations__btns--item" type="text" icon="iconfont iconicon_search" @click="search()"> </el-button>
      </el-tooltip>
    </template>
  </div>
</template>
<script>
export default {
  name: 'CommonTableLabel',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    tableColumns: {
      type: Array,
      default: () => []
    },
    config: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    setConfig() {
      return Object.assign(
        {
          show: ['refresh', 'filter', 'search'] // 自定义类型 刷新:refresh  列过滤:filter 搜索显示:search
        },
        this.config
      )
    }
  },
  watch: {
    value(a) {
      this.$emit('input', a)
    }
  },
  methods: {
    refresh() {
      this.$emit('refresh')
    },
    setNodes(type) {
      return this.setConfig.show.includes(type)
    },
    search() {
      this.$emit('search')
    }
  }
}
</script>
<style lang="scss" scoped>
.el-button--text {
  padding: 0 5px;
}
</style>
