import { domTitle, setDocumentTitle } from '@/utils/domUtil'
import NProgress from 'nprogress' // progress bar
import store from '@/store'
import router from '@/router'
import { isQywx } from './utils/userAgent'
import { WX_APP_CONFIG } from './config/wechat'
import { oauth2Login } from './api/crm/user'
import { APP_TYPE } from './config/app'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const allowList = ['message'] // no redirect allowList
const defaultRoutePath = '/'

router.beforeEach((to, from, next) => {
  const meta = to.meta || router.$avueRouter.meta || {}
  meta.title !== 'undefined' && setDocumentTitle(`${to.meta.title} - ${domTitle}`)
  if (
    !store.getters.accessToken &&
    !store.getters.fromUrl &&
    !['oauth', 'lose', 'login', 'UserPwdReset', 'message'].includes(to.name)
  ) {
    if (isQywx()) {
      handleOauth2Login(to, from, next)
      return
    }
    next({ name: 'login' })
    return
  }

  if (store.getters.accessToken) {
    if (to.path === store.state.loginPath) {
      next({ path: defaultRoutePath })
    } else {
      next()
    }
  } else if (allowList.includes(to.name) || to.meta.auth === false) {
    // 在免登录名单，直接进入
    next()
  } else {
    store.dispatch('ALogout', true)
  }
})

router.afterEach(() => {
  NProgress.done() // finish progress bar
})

const handleOauth2Login = async (to, from, next) => {
  if (to.query.state !== WX_APP_CONFIG.state) {
    const { corpid, agentid, state, scope } = WX_APP_CONFIG
    const redirectUri = window.location.origin
    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${corpid}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}&state=${state}&agentid=${agentid}#wechat_redirect`
  } else if (to.query.code) {
    try {
      await store.dispatch('GetTenantInfo')
      const res = await oauth2Login({
        code: to.query.code,
        type: APP_TYPE.WX,
      })
      await store.dispatch('LoginSuccess', res)
      next()
    } catch {
      next({
        name: 'login',
      })
    }
  }
}
