import _ from 'lodash'
import * as dictApi from '@/api/dict'

/**
 * Vuex module for managing dictionary data.
 * @typedef {Object} DictionaryItem
 * @property {string} code - The code associated with the dictionary.
 * @property {string} dictKey - The key for a specific entry in the dictionary.
 * @property {string} dictValue - The value associated with the key in the dictionary.
 */

/**
 * @typedef {Object} DictionaryState
 * @property {Array<DictionaryItem>} dictList - The array of dictionary items.
 */

const bizModule = {
  /**
   * @type {DictionaryState} state - The module's state.
   */
  state: {
    dictList: [],
    /** @internal 锁 getDictList 方法，未执行完成不会被重新调用 */
    _lock: {},
  },
  mutations: {
    /**
     * Set the dictionary data for a specific code.
     * @param {Object} state - The module state.
     * @param {Object} payload - The payload containing the code and dictionary data.
     * @param {string} payload.code - The code for which to set the dictionary data.
     * @param {Array<DictionaryItem>} payload.dictionaryData - The dictionary data to set.
     */
    SET_DICT_LIST(state, dictList) {
      const uniqueCodes = _(dictList).map('code').uniq().value()

      // Remove existing entries with the same codes
      state.dictList = state.dictList.filter((item) => !uniqueCodes.includes(item.code))

      // Add the new entries to the state.dictList
      state.dictList = [...state.dictList, ...dictList]
    },
    CLEAR_DICT_LIST(state) {
      state.dictList = []
      state._lock = {}
    },
  },
  actions: {
    /**
     * @param {Array<string> | string} codes
     */
    async getDictList({ commit, state, dispatch, getters }, codes) {
      if (typeof codes !== 'string' && !Array.isArray(codes)) {
        throw new Error('传入数组或字符串')
      }
      let codesList = Array.from(new Set(typeof codes === 'string' ? [codes] : codes))

      // 全都存在直接返回
      if (codesList.every((code) => getters.dictionary[code]?.length > 0)) {
        return codesList.reduce((dict, code) => {
          dict[code] = getters.dictionary[code]
          return dict
        }, {})
      }
      const code = codesList.join(',')

      try {
        if (state._lock[code]) {
          await state._lock[code]
          return await dispatch('getDictList', code)
        }
        const req = dictApi.getDictList({ code })
        state._lock[code] = req
        codesList.forEach((c) => (state._lock[c] = req))

        const res = await req
        commit('SET_DICT_LIST', res)

        return res
      } finally {
        delete state._lock[code]
        codesList.forEach((c) => delete state._lock[c])
      }
    },
    /**
     * Get the dictionary list for a given code and return a hash table.
     * @param {Object} context - The Vuex action context.
     * @param {Object} payload - The payload containing the dictionary code.
     * @param {string} payload.code - The code for which to retrieve the dictionary list.
     * @returns {Promise<void>} A promise that resolves when the dictionary list is fetched.
     */
    async getDictionary({ dispatch }, code) {
      return _.groupBy(await dispatch('getDictList', code), 'code')
    },
  },
  getters: {
    /**
     * Get the dictionary list for a given code.
     * @param {Object} state - The module state.
     * @returns {Array<DictionaryItem>} - The dictionary list.
     */
    dictList: (state) => {
      return state.dictList
    },
    dictionary: (state) => {
      return _.groupBy(state.dictList, 'code')
    },
  },
}

export default bizModule
