<template>
  <div>
    <a-table
      ref="classItemTable"
      :key="tableId"
      :data-source="source"
      :columns="tableColumns"
      :pagination="false"
      class="class-item-table"
      row-key="_id"
      size="small"
      :default-expand-all-rows="defaultExpandAllRows"
      :expand-row-by-click="true"
      v-bind="$props"
    >
      <template #_title="text, record">
        <span>
          {{ record._title }}
        </span>

        <!-- 自定义费用项的公式名称 -->
        <a-tooltip :title="record.formulaName ? '公式：' + transformFormulaName(record.formulaName) : '公式未配置'">
          <a-icon v-if="record.itemId && record.type === 'CustomFormula'" class="m-tips-color" type="info-circle" />
        </a-tooltip>

        <a v-if="costShopAiBtn && (record.isDefault || record.type === 'CustomFormula')" @click="openCostShopAiValue(record)">
          {{ costShopAiBtnName }}
        </a>
        <a-dropdown v-if="isDel && record.itemId" :trigger="['click']" class="dropdown-list">
          <a @click="(e) => e.preventDefault()"> ... </a>
          <template #overlay>
            <a-menu @click="(opt) => handleMenuClick(opt, record)">
              <a-menu-item key="remove">移除</a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </template>

      <template v-for="col in mothColumnSlots" #[col.dataIndex]="text, record">
        <a-input-number
          v-if="record[`${col.dataIndex}_editable`] && editable"
          :ref="`${col.dataIndex}_editable`"
          :key="col.dataIndex"
          v-focus
          class="month-edit-cost"
          :value="record[col.dataIndex]"
          :max="9999999999.99"
          :min="0.0"
          :precision="2"
          @change="
            (val) => {
              record[`${col.dataIndex}_update`] = true
              $m.debounce(() => {
                record[col.dataIndex] = val === null ? 0.0 : val
              })
            }
          "
          @blur="saveEvent($event, record, col.dataIndex, col.costIndex)"
        />

        <div v-else :key="col.dataIndex" class="month-mouse-class">
          <!-- 最底下的合计 -->
          <span v-if="record.id === 'SummaryId'">
            {{ $m.numberFormat(record[col.dataIndex]) }}
          </span>

          <!-- 费用分类使用费用项的汇总，费用项使用输入值 -->
          <template v-else>
            {{ !!record.classId ? collect(record, col) : $m.numberFormat(record[col.dataIndex]) }}
          </template>

          <!-- 编辑按钮 -->
          <a-icon v-if="record.allow && record.id !== 'SummaryId' && editable" :key="col.dataIndex" class="input-edit" type="edit" @click="editEvent(record, col.dataIndex)" />
        </div>
      </template>
    </a-table>
    <basis-modal v-model="aiCostValue.visible" size="small" title="补数据" :confirm-loading="aiCostValue.loading" @ok="centralizeSubmit">
      <a-form-model :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" :model="aiCostValue.form" layout="inline">
        <a-form-model-item label="起止时间">
          <a-range-picker
            :key="aiCostValue.priceRangeDateKey"
            v-model="aiCostValue.priceRangeDate"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            :disabled-date="disabledDate"
            @calendarChange="calendarPriceRangeChange"
            @change="onDateChange"
            @openChange="openChange"
          >
          </a-range-picker>
        </a-form-model-item>
      </a-form-model>
    </basis-modal>
  </div>
</template>

<script>
import T from 'ant-design-vue/es/table/Table'
import moment from 'moment'
import { Modal } from 'ant-design-vue'
import { getItemFormula } from '@/api/config/cost-setting'

export default {
  name: 'BasicClassItemTable',
  directives: {
    focus: {
      inserted(el) {
        el.querySelector('input').focus()
      }
    }
  },
  props: {
    ...T.props,
    // FIXME: defaultExpandAllRows 设置为false时导致内部的统计结果不生效
    defaultExpandAllRows: {
      type: Boolean,
      default: true
    },
    defaultColumns: {
      type: Array,
      default: () => {
        return []
      }
    },
    classItemSource: {
      type: Array,
      default: () => {
        return []
      }
    },
    otherCostValue: {
      type: Object,
      default: () => {
        return {}
      }
    },
    valueSource: {
      type: Array,
      default: () => {
        return []
      }
    },
    editable: {
      type: Boolean,
      default: true
    },
    month: {
      type: String,
      default: ''
    },
    shopNo: {
      type: String,
      default: ''
    },
    isSummary: {
      type: Boolean,
      default: true
    },
    summaryName: {
      type: String,
      default: '合计'
    },
    saveCostShopValue: {
      type: Function,
      default: () => {}
    },
    isDel: {
      type: Boolean,
      default: false
    },
    removeCostShopClassItem: {
      type: Function,
      default: () => {}
    },
    costShopAiBtn: {
      type: Boolean,
      default: true
    },
    costShopAiBtnName: {
      type: String,
      default: '补数据'
    },
    costShopAiValue: {
      type: Function,
      default: () => {}
    },
    // 组装列的类型，assembleType === month 生成this.month 的月初到月底，assembleType === year 生成 自然月
    assembleType: {
      type: String,
      default: 'month',
      validator: (value) => {
        return ['month', 'year'].includes(value)
      }
    },
    classCode: {
      type: Object,
      default: () => {
        return {
          open: true,
          code: ['Sales001']
        }
      }
    }
  },
  data() {
    return {
      formulaItemIds: [], // 自定义费用项的id数组
      source: [],
      tableId: 1,
      showEditFactor: '',
      tempRow: undefined,
      tableLoading: false,
      config: {
        index: true,
        // enablePagination: true,
        showOverflowTooltip: false,
        showHandler: false,
        rowKey: '_id',
        defaultExpandAll: true
      },
      assembleColumns: [],
      aiCostValue: {
        record: {},
        loading: false,
        visible: false,
        selectPriceDate: '',
        priceRangeDate: [],
        priceRangeDateKey: 1,
        form: {
          beginDate: '',
          endDate: ''
        }
      }
    }
  },
  computed: {
    customColumnNames() {
      const column = []
      // ! 取出作为外部传入的列，属于自定义的，如：月度费用
      this.defaultColumns.forEach((item) => {
        if (!item.slot && item.costIndex) {
          column.push({
            dataIndex: item.dataIndex,
            costIndex: item.costIndex
          })
        }
      })
      return column.map((it) => it.costIndex)
    },
    mothColumnSlots() {
      const column = []
      // ! 从tableColumns中取出作为内部生成列，属于自定义的，如：每日2020-12-01、2020-12-02...
      // ! 在表格中将使用mothColumnSlots 生成
      this.tableColumns.forEach((item) => {
        if (item.slot && item.dataIndex) {
          column.push({
            dataIndex: item.dataIndex,
            costIndex: item.costIndex
          })
        }
      })
      return column
    },
    tableColumns() {
      const _defaultColumns = _.cloneDeep(this.defaultColumns)
      _defaultColumns.forEach((item) => {
        if (item.costIndex && !item.slot) {
          item.dataIndex = `${item.costIndex}_${this.month}`
          item.scopedSlots.customRender = `${item.costIndex}_${this.month}`
          item.slot = true
        }
      })
      return _defaultColumns.concat(this.assembleColumns)
    }
  },
  watch: {
    month() {
      this.initColumns()
    }
  },
  mounted() {
    this.initColumns()
  },
  methods: {
    // 公式对外展示的名称转换   #{销售额}-#{水电费}*1.5%  ===>  【销售额】 - 【水电费】 * 1.5%
    transformFormulaName(formulaName = '') {
      if (!formulaName) return ''
      return formulaName.replace(/\#\{/g, '【').replace(/\}/g, '】').replace(/\+/g, ' + ').replace(/\-/g, ' - ').replace(/\*/g, ' * ').replace(/\//g, ' / ')
    },
    initColumns() {
      this.assembleColumns = this.generateData(this.assembleType === 'month' ? 'D' : 'M')
    },
    async init() {
      try {
        const _classItemSource = _.cloneDeep(this.classItemSource)

        if (_classItemSource.length > 0 && this.isSummary) {
          _classItemSource.push(this.addSummary())
        }

        await this.convertTreeDataToTable(_classItemSource, this.valueSource, this.otherCostValue)
        this.source = _classItemSource

        // 获取自定义费用项的公式名称
        this.$m.tree.forEach(this.source, async (node) => {
          if (this.formulaItemIds.includes(node.itemId)) {
            node.formulaName = await this.getItemFormula(node.itemId)
          }
        })

        // 处理合计
        this.mothColumnSlots.forEach((col) => {
          this.summary(col.dataIndex, col.costIndex)
        })
        // end
      } finally {
        this.tableId++
      }
    },
    convertTreeDataToTable(treeData, shopValues, otherCostValue) {
      // 自定义费用项的id数组
      this.formulaItemIds = []

      // 查找出日实际值、月实际值、预算值
      const convertMonthToData = (propKey, valueArr, day) => {
        const costDataArr = valueArr.map((shop) => {
          return shop.costDate === this.month ? `${propKey}_${shop.costDate}` : shop.costDate
        })

        const _MonthColumns = _.cloneDeep(this.tableColumns)
        // 查找到月度费用、及整个月的日期字段，如果接口返回数据没匹配到的数据设置默认 0
        const list = _MonthColumns.filter((item) => [propKey, day].includes(item.costIndex) && !costDataArr.includes(item.dataIndex))
        return list.map((item) => {
          return {
            costDate: item.dataIndex,
            costValue: 0
          }
        })
      }
      // chagevalue
      /**
       * @param propKey prop标记
       * @param valueArr 值集合
       * @param row 表格遍历时的 每行数据
       * @param dateType 每天的标记
       */
      const changeValue = (propKey, valueArr, row, dateType = '') => {
        const shopValueArr = valueArr.filter((res) => res.classId === row._id || res.itemId === row._id)
        shopValueArr.forEach((shop) => {
          // 设置日期 = 费用值，设置对应设置id
          const { costDate, costValue, id: shopValueId } = shop
          row[costDate] = costValue
          let _k = costDate
          if (costDate === this.month) {
            row[`${propKey}_${costDate}`] = costValue
            _k = `${propKey}_${costDate}`
          }

          row[`${_k}_valueId`] = shopValueId
          row[`${_k}_editable`] = false
        })
        //

        convertMonthToData(propKey, shopValueArr, dateType).forEach((monthVal) => {
          const { costDate, costValue } = monthVal
          row[costDate] = costValue
          row[`${costDate}_editable`] = false
        })
      }

      const traverse = (items, classCode, fromChildren) => {
        for (const item of items) {
          // concat children and shops
          item.children = _.concat(item.children || [], item.item || [])
          item.allow = true
          item._id = item.classId || item.itemId
          item._code = item.classId || item.itemId
          item._name = item.classCode || item.itemCode
          item._title = item.className || item.itemName

          // 获取自定义费用项的公式名称
          if (item.itemId && item.type === 'CustomFormula') {
            this.formulaItemIds.push(item.itemId)
          }
          // 销售分类
          !fromChildren && (classCode = item.classCode)

          if (this.classCode.open) {
            this.classCode.code.includes(classCode) && (item.allow = !!item.itemId)
          } else {
            item.allow = !!item.itemId
          }

          changeValue('actualShopCost', shopValues, item, 'dayCost')
          this.customColumnNames
            .filter((key) => key !== 'actualShopCost')
            .forEach((key) => {
              changeValue(key, otherCostValue[key], item)
            })

          if (_.size(item.children)) {
            traverse(item.children, classCode, true)
          } else {
            delete item.children
          }
        }
      }
      traverse(treeData, '', false)
    },
    addSummary() {
      return {
        children: [],
        classCode: 'Summary-All',
        classId: 'summary-classId-000',
        className: this.summaryName,
        hasChildren: false,
        id: 'SummaryId',
        item: [],
        parentId: '0'
      }
    },
    editEvent(row, dataIndex) {
      // 编辑icon 触发事件
      if (this.tempRow) {
        this.tempRow[this.showEditFactor] = false
      }
      this.tempRow = row
      this.showEditFactor = `${dataIndex}_editable`
      row[`${dataIndex}_editable`] = true
    },
    saveEvent($event, row, dataIndex, costIndex) {
      // 保存
      this.tempRow = {}
      let costDate = dataIndex
      this.showEditFactor = ''
      row[dataIndex] = $event.target.value
      row[`${dataIndex}_editable`] = false

      if (this.customColumnNames.includes(costIndex)) {
        const arr = dataIndex.split('_')
        arr.length > 0 ? (costDate = arr[1]) : (costDate = this.month)
      }
      const isUpdate = row[`${dataIndex}_update`] || false
      if (isUpdate) {
        const params = {
          shopNo: this.shopNo,
          id: row[`${dataIndex}_valueId`],
          costValue: row[dataIndex] || 0,
          costDate,
          classId: row.classId,
          itemId: row.itemId
        }
        this.saveCostShopValue(params).then((res) => {
          this.summary(dataIndex, costIndex)
          this.$message.success('操作成功')
          row[`${dataIndex}_valueId`] = res.id
        })
      }
    },
    openCostShopAiValue(record) {
      this.aiCostValue.record = _.cloneDeep(record)
      this.aiCostValue.form.beginDate = ''
      this.aiCostValue.form.endDate = ''
      this.aiCostValue.priceRangeDate = []
      this.aiCostValue.visible = true
    },
    async centralizeSubmit() {
      const { record, form } = this.aiCostValue
      const { beginDate, endDate } = form
      if (beginDate === '' && endDate === '') {
        this.$message.error('起止日期不能为空')
        return
      }
      if (record.itemCode) {
        const params = {
          shopNo: this.shopNo,
          itemId: record.itemId,
          ...this.aiCostValue.form
        }
        this.aiCostValue.loading = true
        try {
          const res = await this.costShopAiValue(params)
          if (res) {
            this.$emit('reload', res)
            this.$message.success('操作成功')
          }
        } finally {
          this.aiCostValue.visible = false
          this.aiCostValue.loading = false
        }
      }
    },
    openChange(status) {
      if (!status) {
        // 延时为了日期选择框消失动效完成后 再更新key 值达到重渲染
        setTimeout(() => {
          this.priceRangeDateKey++
        }, 500)
      }
    },
    onDateChange(val) {
      this.aiCostValue.form.beginDate = val[0]
      this.aiCostValue.form.endDate = val[1]
      this.selectPriceDate = ''
    },
    calendarPriceRangeChange(date) {
      this.selectPriceDate = date[0]
    },
    disabledDate(current) {
      // 只能选择近3个月
      const min = this.$moment().subtract(3, 'months').endOf('day') // 三个月前 2021.11.14 23:59:59
      const max = this.$moment().endOf('day') // 当前日期 2022.02.14 23:59:59
      return current < min || current > max
    },
    treeDataToLine(tableData) {
      const treeData = _.cloneDeep(tableData)
      // 将树形数据转成线性数据
      const fn = (arr) => {
        const res = []
        arr.forEach((item) => {
          item._children = _.concat(item.children || [])
          delete item.children
          if (item._children) {
            const _item = _.cloneDeep(item)

            delete _item._children
            delete _item.children
            delete _item.item

            res.push(_item)
            res.push(...fn(item._children))
          }
        })
        return res
      }
      return fn(treeData)
    },
    /**
     * 生成列配置，可生成 this.month 月份中天的配置，可生成 this.month 所在年的自然月配置
     */
    generateData(type = 'M') {
      const list = []
      const format = type === 'D' ? 'YYYY-MM-DD' : 'YYYY-MM'
      if (this.month === '' || typeof this.month === 'undefined') {
        return list
      }
      try {
        let ms = moment(this.month).startOf(type === 'D' ? 'month' : 'year')
        do {
          const found = _.find(list, { prop: ms.format(format) })
          const columnsConfig = {
            width: 100,
            slot: true,
            costIndex: type === 'D' ? 'dayCost' : 'monthCost'
          }
          if (!found) {
            list.push({
              ...columnsConfig,
              title: ms.format(format),
              scopedSlots: { customRender: ms.format(format) },
              dataIndex: ms.format(format)
            })
          } else {
            _.remove(list, (item) => item === found)
            list.push(found)
          }
          ms = ms.add(1, type === 'D' ? 'day' : 'month')
        } while (type === 'D' ? ms.date() !== 1 : ms.month() !== 0)
      } finally {
        return list
      }
    },
    summary(dataKey) {
      const _dataSource = this.treeDataToLine(_.cloneDeep(this.source))
      // 每一列的所有费用项求和
      const count = _dataSource.filter((item) => item.id !== 'SummaryId' && !!item.itemId).reduce((pre, cur) => pre + parseFloat(cur[dataKey]), 0)

      const SummaryObj = this.source.find((it) => it.id === 'SummaryId')
      SummaryObj && (SummaryObj[dataKey] = count)
    },
    /**
     * 费用项，每日向上级汇总处理函数
     */
    collect(row, col) {
      const list = row.children
      let count = 0
      if (list) {
        count = list.reduce((acc, cur) => {
          return acc + parseFloat(cur[col.dataIndex] ? cur[col.dataIndex] : 0)
        }, 0)
      }

      row[col.dataIndex] = count
      return this.$m.numberFormat(count)
    },
    handleMenuClick(e, record) {
      Modal.confirm({
        title: '移除',
        content: '是否确定移除该费用项？',
        cancelText: '取消',
        okText: '确定',
        onOk: async () => {
          await this.removeCostShopClassItem({ shopNo: this.shopNo, itemId: record.itemId })
          this.$message.success('删除成功')
          this.$emit('reload')
        }
      })
    },
    // 获取自定义费用项的公式
    async getItemFormula(itemId) {
      try {
        const { formulaName = null } = await getItemFormula({ shopNo: this.shopNo, itemId })
        return formulaName
      } catch (err) {
        return null
      }
    }
  }
}
</script>

<style lang="less" scoped>
.class-item-table {
  .dropdown-list {
    float: right;
    margin-left: 0;
    padding: 0 6px;
    letter-spacing: 2px;
    font-weight: bold;
  }
  .month-mouse-class {
    i {
      display: none;
      cursor: pointer;
    }
    &:hover {
      i {
        display: inline-block;
      }
    }
  }
  .month-edit-cost {
    width: 80%;
    height: 26px;
    margin-right: 15px;

    ::v-deep .ant-input-number-input {
      height: 26px;
    }
  }

  ::v-deep tr:last-child td {
    position: sticky;
    z-index: 1;
    bottom: 0px;
    background: #fff;
    box-shadow: 5px 0 10px #e4e4e4;
  }
}
</style>
