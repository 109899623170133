/*
 * @Description: 组件的工具函数
 * @Author: yitong
 * @Date: 2022-01-04 11:43:51
 * @LastEditors: yitong
 * @LastEditTime: 2022-01-04 11:51:31
 */

/**
 * @description JavaScript基本类型及其判断
 * @property {any} data 需要判断类型的数据
 * @return {String} boolean number string function array date regExp undefined null object symbol
 */
export const getType = (data) => {
  const str = Object.prototype.toString.call(data)
  const map = {
    '[object Boolean]': 'boolean',
    '[object Number]': 'number',
    '[object String]': 'string',
    '[object Function]': 'function',
    '[object AsyncFunction]': 'function',
    '[object Promise]': 'promise',
    '[object Array]': 'array',
    '[object Date]': 'date',
    '[object RegExp]': 'regExp',
    '[object Undefined]': 'undefined',
    '[object Null]': 'null',
    '[object Object]': 'object',
  }
  return map[str]
}
