<template>
  <div class="basic-mians m-flex m-col-top" :class="{ 'basic-mains--block': block }">
    <div v-if="$scopedSlots.left" class="sideNav">
      <div class="side">
        <slot name="left"></slot>

        <div class="m-p-t-12"><slot name="leftSubBtn"></slot></div>
      </div>
    </div>
    <div class="nain">
      <slot name="search"></slot>
      <basic-container>
        <slot name="container"></slot>
      </basic-container>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BasicMain',
  props: {
    radius: {
      type: [String, Number],
      default: 10
    },
    background: {
      type: String,
      default: ''
    },
    block: {
      type: Boolean,
      default: true
    },
    config: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      span: 19
    }
  },
  computed: {
    styleName() {
      return {
        borderRadius: this.setPx(this.radius),
        background: this.background
      }
    },
    setConfig() {
      return {
        ...{
          leftCard: true,
          searchCard: true,
          containerCard: true
        },
        ...this.config
      }
    }
  },
  methods: {
    setSlot(slot) {
      return _.keys(this.$slots).includes(slot)
    }
  }
}
</script>

<style lang="scss">
.basic-mains {
  box-sizing: border-box;
  height: 100%;
  min-height: 100%;
  padding: 10px 6px;
  &--block {
    position: relative;
    box-sizing: border-box;
    padding: 0 16px;
    .side {
      overflow: hidden;
      overflow-y: auto;
      box-sizing: border-box;
      min-width: 230px;
      height: 100%;
      margin-right: 24px;
      padding: 16px;
      background: #fff;
    }
    .nain {
      overflow: hidden;
      flex: 1;
      width: 100%;
      margin: 0 auto;
    }
    .el-card {
      box-sizing: border-box;
      min-height: 100%;
      margin-bottom: 24px;
    }
    .el-card__body {
      box-sizing: border-box;
      height: 100%;
      min-height: 100%;
    }
  }
  .el-card {
    width: 100%;
  }

  &:first-child {
    padding-top: 0;
  }
}
</style>
