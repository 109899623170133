import website from '@/config/website'

const common = {
  state: {
    language: 'zh',
    isCollapse: false,
    isFullScreen: false,
    isMenu: true,
    isShade: false,
    screen: -1,
    screenInfo: {
      clientWidth: 0,
      clientHeight: 0,
    },
    isLock: false,
    showTag: false,
    showDebug: true,
    showCollapse: true,
    showSearch: true,
    showLock: true,
    showFullScreen: true,
    showTheme: true,
    showMenu: true,
    showColor: true,
    colorName: '#409EFF',
    themeName: 'theme-default',
    lockPasswd: '',
    website,
  },
  mutations: {
    SET_LANGUAGE: (state, language) => {
      state.language = language
    },
    SET_SHADE: (state, active) => {
      state.isShade = active
    },
    SET_COLLAPSE: (state) => {
      state.isCollapse = !state.isCollapse
    },
    SET_FULLSCREEN: (state) => {
      state.isFullScreen = !state.isFullScreen
    },
    SET_IS_MENU: (state, menu) => {
      state.isMenu = menu
    },
    SET_LOCK: (state) => {
      state.isLock = true
    },
    SET_SCREEN: (state, screen) => {
      state.screen = screen
    },
    SET_SCREEN_INFO: (state, params) => {
      state.screenInfo.clientWidth = params.clientWidth
      state.screenInfo.clientHeight = params.clientHeight
    },
    SET_COLOR_NAME: (state, colorName) => {
      state.colorName = colorName
    },
    SET_THEME_NAME: (state, themeName) => {
      state.themeName = themeName
    },
    SET_LOCK_PASSWD: (state, lockPasswd) => {
      state.lockPasswd = lockPasswd
    },
    CLEAR_LOCK: (state) => {
      state.isLock = false
      state.lockPasswd = ''
    },
  },
  actions: {
    AUpdateScreen({ commit }) {
      const clientWidth = document.body.clientWidth

      const screen = clientWidth >= 1200 ? 3 : clientWidth >= 992 ? 2 : clientWidth >= 768 ? 1 : 0
      commit('SET_SCREEN', screen)
    },
  },
}
export default common
