/**
 * @description 角色
 */
import axios from '@/utils/axios'

/**
 * CRM - 菜单树查询接口
 * @param {object} params
 * @param {string} [params.category] 菜单分类，为空查询全部，0-前台（中台）菜单，1-后台菜单，2-PDA
 * @returns {Promise<{
 *   id: string
 *   parentId: string
 *   name: string
 *   code: string
 *   alias: string
 *   path: string
 *   source: string
 *   sort: string
 *   category: string
 *   type: string
 *   action: string
 *   isOpen: string
 *   remark: string
 *   children: {
 *     id: string
 *     parentId: string
 *     name: string
 *     code: string
 *     alias: string
 *     path: string
 *     source: string
 *     sort: string
 *     category: string
 *     type: string
 *     action: string
 *     isOpen: string
 *     remark: string
 *     children: {
 *     }[]
 *   }[]
 * }[]>} ** 返回体 **
 *   - id: 菜单ID
 *   - parentId: 父菜单ID
 *   - name: 菜单名称
 *   - code: 菜单编号
 *   - alias: 菜单别名
 *   - path: 请求地址
 *   - source: 菜单资源
 *   - sort: 排序
 *   - category: 菜单分类，0-前台（中台）菜单，1-后台菜单，2-PDA
 *   - type: 菜单类型，0-目录，1-菜单，2-按钮
 *   - action: 操作按钮类型
 *   - isOpen: 是否打开新页面
 *   - remark: 备注
 *   - children: 子菜单
 *     - id:
 *     - parentId:
 *     - name:
 *     - code:
 *     - alias:
 *     - path:
 *     - source:
 *     - sort:
 *     - category:
 *     - type:
 *     - action:
 *     - isOpen:
 *     - remark:
 *     - children:
 */
export const getMenuTree = (params) => axios.get('/v1/menu/tree', { params })

/**
 * CRM-角色新增接口
 * @param {object} params
 * @param {string} params.name 角色名
 * @param {string} [params.remark] 角色说明
 * @returns {Promise<{
 *   id: string
 * }>} ** 返回体 **
 *   - id: 角色ID
 */
export const postRoleInfo = (params) => axios.post('/v1/role/info', params)

/**
 * CRM - 角色删除接口
 * @param {object} params
 * @param {string} params.id 角色ID
 */
export const deleteRoleInfo = (params) => axios.delete('/v1/role/info', { data: params })

/**
 * CRM - 角色列表查询接口
 * @param {object} params
 * @param {string} [params.name] 角色名，为空查询全部，支持模糊查询
 * @param {string} params.type 类型：1-角色；2-权限组
 * @returns {Promise<{
 *   id: string
 *   code: string
 *   name: string
 *   remark: string
 *   isDefault: string
 * }[]>} ** 返回体 **
 *   - id: 角色ID
 *   - code: 角色编码
 *   - name: 角色名
 *   - remark: 角色说明
 *   - isDefault: 是否默认，1-是，0-否
 */
export const getRoleInfo = (params) => axios.get('/v1/role/info', { params })

/**
 * CRM - 角色修改接口
 * @param {object} params
 * @param {string} params.id 角色ID
 * @param {string} [params.name] 角色名
 * @param {string} [params.remark] 角色说明
 */
export const putRoleInfo = (params) => axios.put('/v1/role/info', params)

/**
 * CRM - 角色菜单权限查询接口
 * @param {object} params
 * @param {string} params.roleId 角色ID
 * @returns {Promise<{
 *   menuIds: string
 * }>} ** 返回体 **
 *   - menuIds: 菜单ID，多个以英文逗号分隔
 */
export const getRoleMenu = (params) => axios.get('/v1/role/menu', { params })

/**
 * CRM - 角色菜单权限修改接口
 * @param {object} params
 * @param {string} params.roleId 角色ID
 * @param {string} params.menuIds 菜单ID，多个以英文逗号分隔
 */
export const putRoleMenu = (params) => axios.put('/v1/role/menu', params)

/**
 * CRM - 角色列表查询接口V2
 * @param {object} params
 * @param {string} [params.name] 角色名，为空查询全部，支持模糊查询
 * @param {string} params.type 类型：1-角色；2-权限组
 * @returns {Promise<{
 *   userId: string
 *   deivision: string
 *   business: string
 *   middleground: string
 *   maxRoleCode: string
 *   roleCodeList: {
 *   }[]
 *   roleVoList: {
 *     id: string
 *     code: string
 *     name: string
 *     remark: string
 *     deptId: string
 *     level: string
 *     isDefault: string
 *   }[]
 * }[]>} ** 返回体 **
 *   - userId: 角色编码
 *   - deivision: 事业部ID
 *   - business: 业务部ID
 *   - middleground: 中台ID
 *   - maxRoleCode: 最大权限编码
 *   - roleCodeList: 角色编码集合
 *   - roleVoList: 角色列表
 *     - id: 角色ID
 *     - code: 角色编码
 *     - name: 角色名称
 *     - remark: 角色说明
 *     - deptId: 部门ID
 *     - level: 部门级别： 1 中台，2 事业部，3 业务部
 *     - isDefault: 是否默认： 1 是 0 否
 */
export const getRoleInfo2 = (params) => axios.get('/v2/role/info', { params })
