import Vue from 'vue'
import App from './App'
import router from '@/router'
import './permission' // 权限
import './error' // 日志
import store from '@/store'
import Element from 'element-ui'
import Antd from 'ant-design-vue'
import 'normalize.css'

import website from '@/config/website'

import Moment from 'moment'

import permission from '@/core/directives/permission'

import vcolorpicker from 'vcolorpicker'
Vue.use(vcolorpicker)

Vue.config.productionTip = false
Vue.prototype.$moment = Moment

Vue.use(router)
Vue.use(permission)
Vue.use(Element)
Vue.use(Antd)

Vue.config.devtools = process.env.NODE_ENV !== 'production'

import bootstrap from '@/core/bootstrap'
bootstrap()

// 加载website
Vue.prototype.website = website

import './styles/common.scss'
import 'ant-design-vue/dist/antd.less'
import '@/assets/styles/global.less' // global style

// 注册全局插件
import Plugins from '@/plugins'
Vue.use(Plugins)

import MdvProUtils from '@ys/pro-utils'
Vue.use(MdvProUtils)

import { translate } from '@/utils/util'
Vue.prototype.$translate = translate

// 注册全局组件 实现自动动态 require 组件，无需手动添加
import '@/components/index'
import '@ys/micro-components'
import YsComponent from '@ys/ui'

Vue.use(YsComponent)

Vue.config.productionTip = false

export const init = async () => {
  if (store.getters.accessToken) {
    await Promise.all([store.dispatch('GetMenuList'), store.dispatch('getRoleInfo')])
  }
  const app = new Vue({
    router,
    store,
    render: (h) => h(App),
  })
  app.$mount('#app')
}

init()
