<template>
  <div class="basic-container" :class="{ 'basic-container--block': block }">
    <el-card>
      <slot></slot>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'BasicContainer',
  props: {
    radius: {
      type: [String, Number],
      default: 10
    },
    background: {
      type: String,
      default: ''
    },
    block: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    styleName() {
      return {
        borderRadius: this.setPx(this.radius),
        background: this.background
      }
    }
  }
}
</script>

<style lang="scss">
.basic-container {
  box-sizing: border-box;
  // padding: 10px 0px;
  height: calc(100% - 80px);
  padding-top: 16px;
  .el-card__body {
    padding: 16px 0px !important;
    background: #fff;
  }
  &--block {
    // height: 100%;
    .el-card {
      // height: 100%;
    }
  }
  .el-card {
    width: 100%;
    box-shadow: 0px 5px 8px rgba(66, 66, 66, 0.12), 0px 2px 4px rgba(66, 66, 66, 0.04);
  }
  &:first-child {
    padding-top: 0;
  }
}
</style>
