<template>
  <el-table-column v-if="column.slot" v-bind="elAttrs">
    <template #default="scope">
      <slot v-bind="scope">
        <!-- slot用于展开行时column为空 -->
        {{ scope.column && scope.row[scope.column.property] }}
      </slot>
    </template>

    <template #header="scope">
      <slot name="header" v-bind="scope">
        {{ scope.column && scope.column.label }}
      </slot>
    </template>
  </el-table-column>
  <el-table-column v-else v-bind="elAttrs" />
</template>

<script>
import { elTableColumnAttrs } from './config'

export default {
  name: 'TableColumn',
  props: {
    column: {
      type: Object,
      default: () => ({ showOverflowTooltip: true })
    }
  },
  computed: {
    _column() {
      return Object.assign({ showOverflowTooltip: true }, this.column)
    },
    elAttrs() {
      const copy = {}
      for (const key in this._column) {
        if (elTableColumnAttrs.includes(key)) {
          copy[key] = this._column[key]
        }
      }
      return copy
    }
  }
}
</script>

<style></style>
