/**
 * 规则校验函数
 * 通过 this.$m.test.isJsonString('{"a": 1}') 的方式进行调用。返回 true 或者 false
 */
/**
 * 是否邮箱号
 */
export const isEmail = (value) => {
  return /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/.test(
    value
  )
}
/**
 * 是否手机号
 */
export const isMobile = (value) => {
  return /^1[3-9]\d{9}$/.test(value)
}
/**
 * 是否URL 校验是否URL链接，返回true或者false。
 */
export const isUrl = (url) => {
  return /^(((ht|f)tps?):\/\/)?[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/.test(url)
}
/**
 * 是否普通日期 验证一个字符串是否日期，返回true或者false
  如下为正确：
  2021-10-14、2021-10-14 10:13:13、2021/10/14 9:10、2021/10/14 09:10、2021/10-14 9:10 1223518920000(数字格式的时间戳)

  如下为错误：
  2021年10月14日、2021-10-14 25:32

  总的来说，年月日之间可以用"/"或者"-"分隔(不能用中文分隔)，时分秒之间用":"分隔，数值不能超出范围，如月份不能为13，则检验成功，否则失败。
 * @param {String} date
 * @returns
 */
export const isDate = (date) => {
  // iOS 下使用 "YYYY-MM-DD HH:mm:ss" 格式不能转换, 需转换成 "YYYY/MM/DD HH:mm:ss"
  return !/Invalid|NaN/.test(
    new Date(typeof date === 'string' ? date.replace(/\-/g, '/') : date).toString()
  )
}
/**
 * 是否十进制数值
 * 整数，小数，负数，带千分位数(2,359.08)等可以检验通过，返回true或者false
 */
export const isNumber = (value) => {
  return /^-?\d+(,\d{3})*(\.\d{1,})?$/.test(value)
}
/**
 * 是否非负整数
 * 所有字符都在0-9之间，才校验通过，结果返回true或者false
 */
export const isDigits = (value) => {
  return /^\d+$/.test(value)
}
/**
 * 是否身份证号 支持1代/2代(15位/18位数字) 验证位数和尾数，不做特征码校验
 * 身份证号，包括尾数为"X"的类型，可以校验通过，结果返回true或者false
 */
export const isIdCard = (value) => {
  return /^\d{6}((((((19|20)\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|(((19|20)\d{2})(0[13578]|1[02])31)|((19|20)\d{2})02(0[1-9]|1\d|2[0-8])|((((19|20)([13579][26]|[2468][048]|0[48]))|(2000))0229))\d{3})|((((\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|((\d{2})(0[13578]|1[02])31)|((\d{2})02(0[1-9]|1\d|2[0-8]))|(([13579][26]|[2468][048]|0[048])0229))\d{2}))(\d|X|x)$/.test(
    value
  )
}
/**
 * 银行卡号（10到30位, 覆盖对公/私账户, 参考微信支付 https://pay.weixin.qq.com/wiki/doc/api/xiaowei.php?chapter=22_1
 * @param {String} value
 * @returns {Boolean} true | false
 */
export const isBankCardNo = (value) => {
  return /^[1-9]\d{9,29}$/.test(value)
}
/**
 * 是否车牌号
 * 可以校验旧车牌号和新能源类型车牌号，结果返回true或者false
 */
export const isCarNo = (value) => {
  // 新能源车牌
  const xReg =
    /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-HJ-NP-Z](?:((\d{5}[A-HJK])|([A-HJK][A-HJ-NP-Z0-9][0-9]{4}))|[A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳])$/
  // 旧车牌
  const cReg =
    /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-HJ-NP-Z][A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳]$/
  if (value.length === 7) {
    return cReg.test(value)
  } else if (value.length === 8) {
    return xReg.test(value)
  }
  return false
}
/**
 * 数字/货币金额（支持负数、千分位分隔符）
 * @param  {String|Number} value
 * @return {Boolean}
 */
export const isMoney = (value) => {
  return /^-?\d+(,\d{3})*(\.\d{1,2})?$/.test(value)
}
/**
 * 是否汉字
 * 可以为单个汉字，或者汉字组成的字符串，结果返回true或者false
 */
export const isChinese = (value) => {
  const reg = /^[\u4e00-\u9fa5]+$/gi
  return reg.test(value)
}
/**
 * 是否字母
 * 只能为"a-z"或者"A-Z"之间的字符，结果返回true或者false
 */
export const isLetter = (value) => {
  return /^[a-zA-Z]*$/.test(value)
}
/**
 * 是否字母或者数字
 * 只能是字母或者数字，结果返回true或者false
 * @param {String} 字母或者数字字符串
 */
export const isEnOrNum = (str) => {
  const reg = /^[0-9a-zA-Z]*$/g
  return reg.test(str)
}
/**
 * 是否固定电话
 */
export const isLandline = (value) => {
  const reg = /^\d{3,4}-\d{7,8}(-\d{3,4})?$/
  return reg.test(value)
}
/**
 * 判断是否为空 返回true或者false
  这里指的“空”，包含如下几种情况：

  值为undefined(一种类型)，非字符串"undefined"
  字符串长度为0，也即空字符串
  值为false(布尔类型)，非字符串"false"
  值为数值0(非字符串"0")，或者NaN
  值为null，空对象{}，或者长度为0的数组
 * @param {any} value
 * @returns {Boolean} true | false
 */
export const isEmpty = (value) => {
  switch (typeof value) {
    case 'undefined':
      return true
    case 'string':
      if (value.replace(/(^[ \t\n\r]*)|([ \t\n\r]*$)/g, '').length === 0) return true
      break
    case 'boolean':
      if (!value) return true
      break
    case 'number':
      if (value === 0 || isNaN(value)) return true
      break
    case 'object':
      if (value === null || value.length === 0) return true
      for (const i in value) {
        return false
      }
      return true
  }
  return false
}
/**
 * 校验是否为JSON字符串，返回true或者false
 * @param {Json} json Json字符串
 请留意json字符串的要求：整体为一个字符串，字符串对象内的属性需要用""双引号包含
 console.log(this.$m.test.isJsonString('{"a": 1}'));
 * @returns
 */
export const isJsonString = (json) => {
  if (typeof json === 'string') {
    try {
      const obj = JSON.parse(json)
      if (typeof obj === 'object' && obj) {
        return true
      }
      return false
    } catch (e) {
      return false
    }
  }
  return false
}
/**
 * 校验是否数组，返回true或者false
 * @param {Array} array 数组
 * @returns {Boolean} true | false
 */
export const isArray = (array) => {
  if (typeof Array.isArray === 'function') {
    return Array.isArray(array)
  }
  return Object.prototype.toString.call(array) === '[object Array]'
}
/**
 * 校验是否对象，返回true或者false
 * @param {Object} obj
 * @returns {Boolean} true | false
 */
export const isObject = (obj) => {
  return Object.prototype.toString.call(obj) === '[object Object]'
}
/**
 * 是否为图片
 * @param {String} filename 文件名或者文件链接
 * @returns {Boolean}
 */
export const isImage = (filename) => {
  return /\.(png|webp|jpg|jpeg|gif|bmp)$/.test(filename.toLowerCase())
}
/**
 * 是否为视频 具体格式可根据实际业务进行调整
 * @param {String} filename 文件名或者文件链接
 * @returns {Boolean}
 */
export const isVideo = (filename) => {
  return /\.(mp4|m4v|mov|qt|avi|flv|wmv|wav|3gp|asf|mpeg|mpg|mkv|rm|rmvb|vob)$/.test(
    filename.toLowerCase()
  )
}
/**
 * 校验是否验证码(要求为数字)，返回true或者false
 * console.log(this.$m.test.code('1232', 4)) // true
 * @param {String} value 验证码字符串
 * @param {Number} len 验证码长度，默认为6
 * @returns {Boolean} true | false
 */
export const code = (value, len = 6) => {
  return new RegExp(`^\\d{${len}}$`).test(value)
}
/**
 * 是否包含某个值
 * 字符串中是否全包含某一个子字符串，区分大小写，结果返回true或者false
 * console.log(this.$m.test.contains('Hello World!', 'World')) // true
 * @param {String} str 字符串
 * @param {String} subStr 子字符串
 */
export const contains = (str, subStr) => {
  return str.indexOf(subStr) >= 0
}
/**
 * 数值是否在某个范围内
 * 如30在"29-35"这个范围内，不在"25-28"这个范围内，结果返回true或者false
 * @param {Number} number 数值
 * @param {Array} array 如"[25,30]"
 */
export const range = (number, array) => {
  return number >= array[0] && number <= array[1]
}
/**
 * 字符串长度是否在某个范围内
 * 如"abc"长度为3，范围在"2-5"这个区间，结果返回true或者false
 * @param {String} str 字符串
 * @param {Array} array 如"[25,30]"
 */
export const rangeLength = (str, array) => {
  return str.length >= array[0] && str.length <= array[1]
}
/**
 * 校验数字是否为偶数
 * @param {Number} num
 * @returns {Boolean}
 */
export const isEven = (num) => num % 2 === 0
/**
 * 检查用户的设备是否处于暗黑模式
 */
export const isDarkMode = () =>
  window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
