/*
  注释掉的字段将从其他位置传入
*/
export const elTableAttrs = [
  // 'data',
  'size',
  'width',
  'height',
  'maxHeight',
  'fit',
  'stripe',
  'border',
  'rowKey',
  'context',
  'showHeader',
  'showSummary',
  'sumText',
  'summaryMethod',
  'rowClassName',
  'rowStyle',
  'cellClassName',
  'cellStyle',
  'headerRowClassName',
  'headerRowStyle',
  'headerCellClassName',
  'headerCellStyle',
  'highlightCurrentRow',
  'currentRowKey',
  'emptyText',
  'expandRowKeys',
  'defaultExpandAll',
  'defaultSort',
  'tooltipEffect',
  'spanMethod',
  'selectOnIndeterminate',
  'indent',
  'treeProps',
  'lazy',
  'load'
]

export const elTableColumnAttrs = [
  'type',
  'label',
  'className',
  'labelClassName',
  'property',
  'prop',
  'width',
  'minWidth',
  'renderHeader',
  'sortable',
  'sortMethod',
  'sortBy',
  'resizable',
  'columnKey',
  'align',
  'headerAlign',
  'showTooltipWhenOverflow',
  'showOverflowTooltip',
  'fixed',
  'formatter',
  'selectable',
  'reserveSelection',
  'filterMethod',
  'filteredValue',
  'filters',
  'filterPlacement',
  'filterMultiple',
  'index',
  'sortOrders'
]

export const elPageAttrs = [
  // 'pageSize',
  'small',
  // 'total',
  'pageCount',
  'pagerCount',
  // 'currentPage',
  // 'layout',
  // 'pageSizes',
  'popperClass',
  'prevText',
  'nextText',
  'background',
  'disabled',
  'hideOnSinglePage'
]
