/**
 * @description 部门管理
 */
import axios from '@/utils/axios'

/**
 * 新增部门
 * @param {object} params
 * @param {string} params.name 部门名称
 * @param {string} params.parentId 上级部门ID，顶级部门为0
 * @param {string} params.level 部门级别：1 中台，2 事业部，3 业务部
 */
export const postDepartmentAdd = (params) => axios.post('/v1/department/add', params)

/**
 * 删除部门
 * @param {object} params
 * @param {string} params.ids 部门ID，多个ID逗号分隔
 */
export const deleteDepartmentRemove = (params) => axios.delete('/v1/department/remove', { params })

/**
 * 部门结构树查询
 * @param {object} params
 * @param {string} [params.parentId] 父级部门Id
 * @returns {Promise<{
 *   id: string
 *   name: string
 *   level: string
 *   remark: string
 *   children: {
 *     id: string
 *     name: string
 *     level: string
 *     remark: string
 *     children: {
 *     }[]
 *   }[]
 * }[]>} ** 返回体 **
 *   - id: 部门Id
 *   - name: 部门名称
 *   - level: 部门级别：1 中台，2 事业部，3 业务部
 *   - remark: 备注
 *   - children: 子部门列表
 *     - id: 部门ID
 *     - name: 部门名称
 *     - level: 部门级别：1 中台，2 事业部，3 业务部
 *     - remark: 备注
 *     - children:
 */
export const getDepartmentTree = (params) => axios.get('/v1/department/tree', { params })

/**
 * 编辑部门
 * @param {object} params
 * @param {string} params.name 部门名称
 * @param {string} params.parentId 上级部门ID，顶级部门为0
 * @param {string} params.level 部门级别：1 中台，2 事业部，3 业务部
 */
export const postDepartmentUpdate = (params) => axios.post('/v1/department/update', params)

/**
 * 部门成员列表查询
 * @param {object} params
 * @param {string} params.deptId 部门Id
 * @param {string} params.pageSize 分页大小
 * @param {string} params.pageNo 页码
 * @param {string} [params.roleCode] 角色编码
 * @returns {Promise<{
 *   current: number
 *   total: string
 *   pages: number
 *   records: {
 *     id: string
 *     userName: string
 *     userAccount: string
 *     email: string
 *     mobile: string
 *     telephone: string
 *     position: string
 *   }[]
 *   size: number
 * }>} ** 返回体 **
 *   - current: 当前页码
 *   - total: 总条数
 *   - pages: 总页数
 *   - records:
 *     - id: 用户ID
 *     - userName: 用户名称
 *     - userAccount: 用户账号
 *     - email: 邮箱
 *     - mobile: 手机号
 *     - telephone: 固话
 *     - position: 职位
 *   - size: 分页大小
 */
export const getUserList = (params) => axios.get('/v1/department/user/list', { params })
