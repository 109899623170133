<template>
  <div class="formula-editor">
    <div class="m-m-b-8">
      公式校验结果：<span :class="'m-type-' + status">{{ status === 'error' ? '错误=>' + result : result === undefined ? '请点击下方的按钮进行公式编辑' : '正确' }}</span>
    </div>
    <div contenteditable="false" class="code-mirror">
      <span v-for="(item, index) in formulaRules" :key="index" :class="getItemClass(item)">{{ item.label.toString().replace(/\#\{/g, '【').replace(/\}/g, '】') }}</span>
    </div>
    <div class="m-m-t-8">
      <div class="m-m-b-4 m-flex m-row-between">
        <a-tooltip placement="right" title="从科目库添加科目作为运算项，同时选择多个科目时，各科目按照加法进行处理">
          <a-button size="small" ghost type="primary" @click.stop="openModal('selectCostItemModal')">+添加科目</a-button>
          <SelectCostItemModal ref="selectCostItemModal" :params="params" :method="method" @ok="handleSelectCostItemOk"></SelectCostItemModal>
        </a-tooltip>

        <span v-if="showSelectFormulaEntry && dataSource.length > 0" class="m-flex" style="line-height: 24px">
          <SelectFormulaModal ref="selectFormulaModal" :record="record" :data-source="dataSource" @ok="handleSelectFormulaOk"></SelectFormulaModal>
          <a @click.stop="openModal('selectFormulaModal')">+选择已有公式科目</a>
          <a-tooltip placement="topRight" title="从当前报表模板中选择已有公式（除当前公式外）快捷填充到当前公式中">
            <a-icon style="color: #2483ff" type="info-circle" />
          </a-tooltip>
        </span>

        <slot name="extra"></slot>
      </div>
      <div class="key-list">
        <a-tag v-for="item in keys" :key="item.value" class="key-list-item" :class="item.key" size="small" type="primary" @click="handleClickKey(item)">{{ item.value }}</a-tag>
      </div>
    </div>
  </div>
</template>

<script>
import SelectCostItemModal from './SelectCostItemModal'
import SelectFormulaModal from './SelectFormulaModal'
export default {
  name: 'FormulaEditor', // 公式编辑组件
  components: {
    SelectCostItemModal,
    SelectFormulaModal
  },
  props: {
    // 当前正在编辑的记录
    record: {
      type: Object,
      default: undefined // {id,name,statType,type,formulaRules:[{key:'item',value:'#{9206911201001}',label:'#{京挑客}'}],formulaName:'#{京挑客}',formulaCode:'#{9206911201001}'}
    },
    // 当前正在编辑的模板数据源
    dataSource: {
      type: Array,
      default: undefined // [record]
    },
    // 是否显示“选择已有公式科目”入口
    showSelectFormulaEntry: {
      type: Boolean,
      default: false
    },
    // 获取科目列表的接口参数 {isDefault: 费用项是否默认，1-是，0-否, type: 类型，Default：普通，CustomFormula：自定义公式, Stat：统计科目, dimension: 统计维度，Shop：店铺，Anchor：主播，Sku：单品}
    params: {
      type: Object,
      default: () => {}
    },
    // 获取科目列表的请求方法
    method: {
      type: [Function, String],
      default: '/v1/cost/class/item' // 最新科目分类及科目查询接口/v1/cost/class/item，在“业绩解码”和“租户后台”项目都存在该接口且为常用接口，故设置为默认值
    }
  },
  data() {
    return {
      // 公式示例：#{费用项ID}+#{费用项ID}*0.05+(#{费用项ID}-#{费用项ID})/1.5
      keys: [
        { key: 'num', value: 1 },
        { key: 'num', value: 2 },
        { key: 'num', value: 3 },
        { key: 'num', value: 4 },
        { key: 'num', value: 5 },
        { key: 'num', value: 6 },
        { key: 'num', value: 7 },
        { key: 'num', value: 8 },
        { key: 'num', value: 9 },
        { key: 'num', value: 0 },
        { key: 'point', value: '.' },
        { key: 'base', value: '+' },
        { key: 'base', value: '-' },
        { key: 'base', value: '*' },
        { key: 'base', value: '/' },
        { key: 'percent', value: '%' },
        { key: 'left', value: '(' },
        { key: 'right', value: ')' },
        { key: 'back', value: '←' },
        { key: 'clear', value: '清空' }
      ],
      baseCalcKeys: ['+', '-', '*', '/'],
      validCalcKeys: ['+', '-', '*', '/', '(', ')', '.', ''], // 有效的运算符号，没有这些符号在前面不能加后续的内容
      formulaRules: [], // 存放输入值的数组 [{key: 'num',value: 1,label:1},{key:'item',value:'#{finalSalesAmount}',label:'#{销售额}'}]

      // 选择已有公式科目
      selectFormulaVisible: false,
      selectedFormulaId: '' // 当前选中的公式科目id
    }
  },
  computed: {
    // 计算公式编码，示例：#{finalSalesAmount}×0.05
    formulaCode() {
      return this.formulaRules.reduce((pre, cur) => pre + cur.value, '')
    },
    // 计算公式名称，示例：#{销售额}×0.05
    formulaName() {
      return this.formulaRules.reduce((pre, cur) => pre + cur.label, '')
    },
    // 最后一个字符
    lastStr() {
      return this.formulaCode.charAt(this.formulaCode.length - 1)
    },
    result() {
      try {
        const _res = this.formulaCode.replace(/%/g, '/100').replace(/\#\{.*?\}/g, 1)
        // eslint-disable-next-line no-eval
        return eval(_res)
      } catch (error) {
        return error
      }
    },
    status() {
      const { result } = this
      return result === undefined || result.toString().indexOf('Error') === -1 ? 'success' : 'error'
    }
  },
  watch: {
    record: {
      handler(val, oldVal) {
        if (!_.isEqual(val, oldVal)) {
          // 回填默认值
          this.formulaRules = _.cloneDeep(val.formulaRules || [])
        }
      },
      immediate: true,
      deep: true
    },
    formulaRules: {
      handler(val, oldVal) {
        // 初始化时不激活表单校验
        if (oldVal !== undefined) {
          const { formulaName, formulaCode, status, result } = this
          const valid = result !== undefined && status === 'success'
          const res = { formulaRules: valid ? val : [], formulaName: valid ? formulaName : '', formulaCode: valid ? formulaCode : '' }
          console.log('change', res)
          this.$emit('change', res)
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    // 供父组件调用
    clear() {
      this.formulaRules = []
    },
    // 点击数字及运算符
    handleClickKey({ key, value }) {
      switch (key) {
        case 'clear':
          this.formulaRules = []
          break
        case 'back':
          this.formulaRules.pop()
          break
        default:
          this.handleInput(key, value, value)
          break
      }
    },
    handleInput(key, value, label) {
      if (!this.canInput(key, value)) return
      // 如果为“运算符+0+数字”的组成方式，自动去掉前面的0
      if (this.baseCalcKeys.includes(this.formulaCode.slice(-2, -1)) && Number(this.lastStr) === 0 && key === 'num') this.formulaRules.pop()

      this.formulaRules.push({ key, value, label })
    },
    // 输入前的合法性校验
    canInput(key, value) {
      let valid = true
      let msg = '请先选择有效的运算符号'
      const { lastStr, baseCalcKeys } = this
      let keys = []
      switch (key) {
        case 'item':
          keys = [...baseCalcKeys, '(']
          valid = lastStr === '' || keys.includes(lastStr)
          msg += `，如：“${keys}”`
          break
        case 'point':
          valid = !isNaN(lastStr) && lastStr !== ''
          msg = '逗号之前必须是有效的数字'
          break
        case 'num':
          keys = [...baseCalcKeys, '.', '(']
          valid = keys.includes(lastStr) || !isNaN(lastStr)
          msg += `，如：“${keys}”或者有效的数字`
          break
        case 'base':
          keys = ['}', ')', '.', '%']
          valid = keys.includes(lastStr) || !isNaN(lastStr) || (value === '-' && ['*', '/', '('].includes(lastStr))
          msg += `，如：“${keys}”或者有效的数字`
          break
        case 'percent':
          valid = !isNaN(lastStr)
          msg = '百分号之前必须是有效的数字'
          break
        case 'left':
          keys = [...baseCalcKeys, '(']
          valid = keys.includes(lastStr) || lastStr === ''
          msg += `，如：“${keys}”`
          break
        case 'right':
          keys = [')', '}', '%']
          valid = keys.includes(lastStr) || !isNaN(lastStr)
          msg = `右括号之前只能是有效的数字、“#{xx科目}”、“右括号”`
          break
        default:
          break
      }
      if (!valid) this.$message.warning(msg)
      return valid
    },
    // 获取公式每一项的样式class
    getItemClass({ key }) {
      let _class = ''
      switch (key) {
        case 'item':
          _class = 'm-type-primary'
          break
        case 'base':
          _class = 'm-type-success m-m-h-4'
          break
        case 'left':
        case 'right':
          _class = 'm-type-warning'
          break
        default:
          _class = 'm-tips-color'
      }
      return _class
    },
    openModal(ref) {
      if (this.canInput('left', '(')) this.$refs[ref].open()
    },
    handleSelectCostItemOk(itemList) {
      // 支持批量添加科目
      itemList.map(({ itemId, itemName }, index) => {
        this.handleInput('item', `#{${itemId}}`, `#{${itemName}}`)
        if (index < itemList.length - 1) this.handleInput('base', '+', '+')
      })
    },
    handleSelectFormulaOk(record) {
      const leftBracket = { key: 'left', value: '(', label: '(' }
      const rightBracket = { key: 'right', value: ')', label: ')' }
      if (this.canInput('left', '(')) {
        this.formulaRules = [...this.formulaRules, leftBracket, ...record.formulaRules, rightBracket]
        this.$refs.selectFormulaModal.close()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.formula-editor {
  margin: 0 auto;

  .tag,
  ::v-deep .tag {
    min-width: 24px;
    height: 24px;
    margin: 4px !important;
    cursor: pointer;
    text-align: center;
    border-color: @primary-color;
    background-color: #fff;

    &:hover {
      color: #fff;
      background-color: @primary-color;
    }
  }
}

.code-mirror {
  overflow-y: auto;
  height: 150px;
  min-height: 150px;
  padding: 16px;
  border-radius: 2px;
  background-color: #f5f5f5;
  font-size: 16px;
}

.key-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -4px;

  &-item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 32px;
    margin: 4px;
    cursor: pointer;
    text-align: center;
    color: @text-color-secondary;
    border-color: @border-color-base;
    font-size: 16px;
    font-weight: bold;

    &.base {
      color: @success-color;
    }

    &.left,
    &.right {
      color: @warning-color;
    }

    &:hover {
      color: #fff;
      border-color: @primary-color;
      background-color: @primary-color;
    }

    &.clear,
    &.check {
      font-size: 14px;
      font-weight: normal;
    }

    &.clear,
    &.back {
      &:hover {
        border-color: @error-color;
        background-color: @error-color;
      }
    }

    &.check {
      &:hover {
        border-color: @success-color;
        background-color: @success-color;
      }
    }
  }
}
</style>
