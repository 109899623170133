/**
 * @description 用户
 */
import axios from '@/utils/axios'

/**
 * CRM-用户导出接口
 * @param {object} params
 * @param {string} [params.keyWord] 关键词（用户姓名、手机号、工号），支持模糊搜索
 * @param {string} [params.deptId] 部门ID
 * @param {string} [params.position] 职位
 * @param {string} [params.roleId] 角色ID
 * @param {string} params.type 导出类型，取值范围：XLS、XLSX
 */
export const getUserExport = (params) => axios.get('/v1/user/export', { params })

/**
 * 用户菜单权限查询接口
 * @param {object} params
 * @param {string} params.userId 用户ID
 * @returns {Promise<{
 *   id: string
 *   name: string
 *   code: string
 *   alias: string
 *   path: string
 *   source: string
 *   sort: number
 *   category: number
 *   type: number
 *   action: number
 *   isOpen: number
 *   remark: string
 *   isOwn: number
 *   children: {
 *     id: string
 *     name: string
 *     code: string
 *     alias: string
 *     path: string
 *     source: string
 *     sort: number
 *     category: number
 *     type: number
 *     action: number
 *     isOpen: number
 *     remark: string
 *     isOwn: number
 *     children: {
 *     }[]
 *   }[]
 * }[]>} ** 返回体 **
 *   - id: 菜单ID
 *   - name: 菜单名称
 *   - code: 菜单编号
 *   - alias: 菜单别名
 *   - path: 请求地址
 *   - source: 菜单资源
 *   - sort: 排序
 *   - category: 菜单分类，0-前台（中台）菜单，1-后台菜单，2-PDA
 *   - type: 菜单类型，0-目录，1-菜单，2-按钮
 *   - action: 操作按钮类型
 *   - isOpen: 是否打开新页面
 *   - remark: 备注
 *   - isOwn: 是否拥有权限，1-拥有，0-没有
 *   - children:
 *     - id:
 *     - name:
 *     - code:
 *     - alias:
 *     - path:
 *     - source:
 *     - sort:
 *     - category:
 *     - type:
 *     - action:
 *     - isOpen:
 *     - remark:
 *     - isOwn:
 *     - children:
 */
export const getUserMenu = (params) => axios.get('/v1/user/menu', { params })

/**
 * CRM-用户创建接口
 * @param {object} params
 * @param {string} params.name 用户名称
 * @param {string} params.mobile 手机号
 * @param {string} params.deptId 部门Id
 * @param {string} params.roleIds 角色Id，多个角色逗号分隔
 * @param {string} [params.email] 邮箱
 * @param {string} [params.position] 岗位
 * @param {string} [params.telephone] 固话
 */
export const postUserInfo = (params) => axios.post('/v1/user/info', params)

/**
 * CRM-用户详情查询接口
 * @param {object} params
 * @param {string} params.userId 用户ID
 * @returns {Promise<{
 *   userId: string
 *   account: string
 *   name: string
 *   avatar: string
 *   mobile: string
 *   telephone: string
 *   position: string
 *   email: string
 *   status: string
 *   deptIds: string
 *   deptNames: string
 *   roles: {
 *     id: string
 *     code: string
 *     name: string
 *     type: string
 *     remark: string
 *   }[]
 * }>} ** 返回体 **
 *   - userId: 用户ID（非钉钉员工ID）
 *   - account: 账号
 *   - name: 员工姓名
 *   - avatar: 头像url
 *   - mobile: 手机号码
 *   - telephone: 分机号
 *   - position: 职位
 *   - email: 员工邮箱
 *   - status: 状态，1-有效，0-无效
 *   - deptIds: 所属部门ID列表，多个用逗号分隔
 *   - deptNames: 所属部门名称列表，多个用逗号分隔
 *   - roles:
 *     - id: 角色ID
 *     - code:
 *     - name: 角色名称
 *     - type: 类型
 *     - remark: 备注
 */
export const getUserInfo = (params) => axios.get('/v1/user/info', { params })

/**
 * CRM-用户删除接口
 * @param {object} params
 * @param {string} params.userIds 用户ID，非钉钉员工ID，多个用逗号分隔
 */
export const deleteUserInfo = (params) => axios.delete('/v1/user/info', { data: params })

/**
 * CRM - 用户修改接口
 * @param {object} params
 * @param {string} params.userId 用户ID
 * @param {string} params.roleIds 角色ID列表，多个用逗号分隔
 * @param {string} [params.name] 用户名称，空值后台不做修改，前端有传才修改
 * @param {string} [params.mobile] 手机号，空值后台不做修改，前端有传才修改
 * @param {string} [params.telephone] 固话，空值后台不做修改，前端有传才修改
 * @param {string} [params.email] 邮箱，空值后台不做修改，前端有传才修改
 * @param {string} [params.avatar] 头像，空值后台不做修改，前端有传才修改
 * @param {string} params.deptId 部门ID
 */
export const putUserInfo = (params) => axios.put('/v1/user/info', params)

/**
 * CRM-用户列表查询接口
 * @param {object} params
 * @param {number} params.pageNo 请求页码
 * @param {number} params.pageSize 每页条数
 * @param {string} [params.keyWord] 关键词（用户姓名、手机号、工号），支持模糊搜索
 * @param {string} [params.deptId] 部门ID
 * @param {string} [params.roleId] 角色ID,,多个用逗号分隔
 * @param {string} [params.roleCode] 角色编码
 * @returns {Promise<{
 *   total: number
 *   pages: number
 *   records: {
 *     userId: string
 *     account: string
 *     name: string
 *     avatar: string
 *     mobile: string
 *     telephone: string
 *     position: string
 *     email: string
 *     status: string
 *     deptIds: string
 *     deptNames: string
 *     isAdmin: string
 *     roles: {
 *       roleId: string
 *       roleCode: string
 *       roleName: string
 *       type: string
 *     }
 *   }[]
 * }>} ** 返回体 **
 *   - total: 总条数
 *   - pages: 总页数
 *   - records:
 *     - userId: 用户ID
 *     - account: 账号
 *     - name: 员工姓名
 *     - avatar: 头像url
 *     - mobile: 手机号码
 *     - telephone: 分机号
 *     - position: 职位
 *     - email: 员工邮箱
 *     - status: 状态，1-有效，0-无效
 *     - deptIds: 所属部门ID列表，多个用逗号分隔
 *     - deptNames: 所属部门名称列表，多个用逗号分隔
 *     - isAdmin: 是否超级管理人员
 *     - roles:
 *       - roleId: 角色ID
 *       - roleCode:
 *       - roleName: 角色名称
 *       - type: 类型
 */
export const getUserList = (params) => axios.get('/v1/user/list', { params })

/**
 * 用户密码修改接口
 * @param {object} params
 * @param {string} params.userId 用户ID
 * @param {string} params.oldPassword 旧密码，md5加密
 * @param {string} [params.newPassword] 新密码，md5加密
 */
export const postPwdModify = (params) => axios.post('/v1/user/pwd/modify', params)

/**
 * 用户重置密码接口
 * @param {object} params
 * @param {string} params.id 缓存key
 * @param {string} params.value 短信验证码
 * @param {string} params.mobile 手机号码
 * @param {string} params.newPassword 新密码，md5加密
 */
export const postPwdReset = (params) => axios.post('/v1/user/pwd/reset', params)

/**
 * CRM-用户电子名片
 * @param {object} params
 * @param {string} params.userId 用户ID
 */
export const getBusinessCard = (params) => axios.post('/v1/user/business/card', params)

/**
 * CRM-用户可查询部门用户数据
 * @param {object} params
 * @param {string} [params.divisionDept] 事业部ID
 * @param {string} [params.businessDept] 业务部ID
 * @returns {Promise<{
 *   userList: {
 *     id: string
 *     name: string
 *   }[]
 *   divisionList: {
 *   }[]
 *   id: string
 *   name: string
 *   businessList: {
 *   }[]
 * }>} ** 返回体 **
 *   - userList: 用户列表
 *     - id: 用户id
 *     - name: 用户名
 *   - divisionList: 事业部部门列表
 *   - id: 事业部ID
 *   - name: 事业部名称
 *   - businessList: 业务部部门列表
 */
export const getQueryData = (params) => axios.get('/v1/user/business/query/data', { params })

/** CRM-指派用户列表查询接口
 * @param {object} params
 * @param {number} params.pageNo 请求页码
 * @param {number} params.pageSize 每页条数
 * @param {string} [params.keyWord] 关键词（用户姓名、手机号、工号），支持模糊搜索
 * @param {string} [params.deptId] 部门ID
 * @param {string} [params.roleId] 角色ID,,多个用逗号分隔
 * @param {string} [params.roleCode] 角色编码
 * @returns {Promise<{
 *   total: number
 *   pages: number
 *   records: {
 *     userId: string
 *     account: string
 *     name: string
 *     avatar: string
 *     mobile: string
 *     telephone: string
 *     position: string
 *     email: string
 *     status: string
 *     deptIds: string
 *     deptNames: string
 *     isAdmin: string
 *     roles: {
 *       roleId: string
 *       roleCode: string
 *       roleName: string
 *       type: string
 *     }
 *   }[]
 * }>} ** 返回体 **
 *   - total: 总条数
 *   - pages: 总页数
 *   - records:
 *     - userId: 用户ID
 *     - account: 账号
 *     - name: 员工姓名
 *     - avatar: 头像url
 *     - mobile: 手机号码
 *     - telephone: 分机号
 *     - position: 职位
 *     - email: 员工邮箱
 *     - status: 状态，1-有效，0-无效
 *     - deptIds: 所属部门ID列表，多个用逗号分隔
 *     - deptNames: 所属部门名称列表，多个用逗号分隔
 *     - isAdmin: 是否超级管理人员
 *     - roles:
 *       - roleId: 角色ID
 *       - roleCode:
 *       - roleName: 角色名称
 *       - type: 类型
 */
export const getAssignList = (params) => axios.get('/v1/user/assign/list', { params })

/**
 * 短信验证码发送接口
 * @param {object} params
 * @param {string} params.mobile 手机号码
 * @returns {Promise<{
 *   id: string
 * }>} ** 返回体 **
 *   - id: 验证码在redis中的缓存key
 */
export const postSmsSend = (params) => axios.post('/v1/sms/send', params)

/**
 * 企微/钉钉登录接口
 * @param params The data for the request.
 * @param params.code code
 * @param params.type 1 | 2
 * @returns R_UserLoginVo_ OK
 * @throws ApiError
 */
export const oauth2Login = (params) => axios.post('/v2/user/login', params)
