import _ from 'lodash'
import store from '@/store'

export default function Initializer() {
  store.dispatch('AUpdateScreen')

  window.onresize = _.debounce(() => {
    store.dispatch('AUpdateScreen')
  }, 300)
}
