/**
 * @param {VueComponent} Vue vue构造函数
 * @param {string} [options.noConflict] 是否使用全局变量和注册到Vue实例上的属性名
 */
export default function install(Vue, options = {}) {
  const noConflict = options.lodash?.noConflict
  const lodash = require('lodash')
  if (!noConflict) {
    // global use lodash
    Vue.prototype._ = lodash
  } else {
    // only registry on vm instance
    Vue.prototype[typeof noConflict === 'string' ? noConflict : '_'] = lodash.noConflict()
  }
}
