export const NOOP = () => {}

/**
 * Always return false.
 */
export const NO = () => false

const onRE = /^on[^a-z]/
export const isOn = (key) => onRE.test(key)

export const isModelListener = (key) => key.startsWith('onUpdate:')

export const extend = Object.assign

export const remove = (arr, el) => {
  const i = arr.indexOf(el)
  if (i > -1) {
    arr.splice(i, 1)
  }
}

const hasOwnProperty = Object.prototype.hasOwnProperty
export const hasOwn = (obj, prop) => {
  return hasOwnProperty.call(obj, prop)
}

export const isArray = Array.isArray

export const objectToString = Object.prototype.toString
export const toTypeString = (value) => objectToString.call(value)

export const isMap = (val) => toTypeString(val) === '[object Map]'
export const isSet = (val) => toTypeString(val) === '[object Set]'
export const isDate = (val) => val instanceof Date
export const isFunction = (val) => typeof val === 'function'
export const isString = (val) => typeof val === 'string'
export const isSymbol = (val) => typeof val === 'symbol'
export const isObject = (val) => val !== null && typeof val === 'object'

export const isPromise = (val) => {
  return isObject(val) && isFunction(val.then) && isFunction(val.catch)
}
export const isPlainObject = (val) => toTypeString(val) === '[object Object]'

// compare whether a value has changed, accounting for NaN.
export const hasChanged = (value, oldValue) => !Object.is(value, oldValue)

// 用连字号连接 PascalCase转kebab-case
export const hyphenate = (str) => {
  const hyphenateRE = /([a-z\d])([A-Z])/g
  return str.replace(hyphenateRE, '$1-$2').toLowerCase()
}

// kebab-case转PascalCase
export const kebabCase2PascalCase = (str) => {
  return `-${str}`.replace(/-(\w)/g, ($, $1) => {
    return $1.toUpperCase()
  })
}

export const isUrl = (url) => {
  return /^(((ht|f)tps?):\/\/)?[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/.test(url)
}

export const def = (obj, key, value) => {
  Object.defineProperty(obj, key, {
    configurable: true,
    enumerable: false,
    value
  })
}

export const toNumber = (val) => {
  const n = parseFloat(val)
  return isNaN(n) ? val : n
}

// 格式化 金钱，默认保留两位
export const moneyFormat = (money, fractionDigits = 2) => {
  const _money = parseFloat(money)
  if (!isNaN(_money)) {
    return _money.toFixed(fractionDigits)
  }
  const z = 0
  return z.toFixed(fractionDigits)
}

// percentage
export const percentage = (x, y, symbol = '%') => {
  if (x === '' || y === '') return '--'
  if (y === 0) return '0%'
  let number = (x / y) * 100
  number = Math.round(number)
  if (!isNaN(number)) {
    return `${number}${symbol}`
  }
  return '0%'
}

export const convertLevelShopData = (list, _fn) => {
  _.each(list, (li) => {
    li._isTop = true
  })
  const traverse = (items) => {
    for (const item of items) {
      // concat children and shops
      item._children = _.concat(item.children || [], item.shops || [])
      item._title = item.bizName || item.shopName
      item._value = item.bizId || item.shopNo
      // 给层级注入可修改的时机
      !!_fn && _fn('Biz', item)
      _.each(item.shops, (shop) => {
        shop._title = shop.shopName
        shop._value = shop.shopNo
        // 给店铺注入可修改的时机
        !!_fn && _fn('Shop', shop)
      })

      if (_.size(item.children)) {
        traverse(item.children)
      }
    }
  }
  traverse(list)

  return list
}

export const convertLevelShopsByUser = (userInfo, bizLevelShops, sign = 'power') => {
  const { shopNos, id } = userInfo
  const list = []
  const traverse = (items) => {
    for (const item of items) {
      _.each(item.shops, (shop) => {
        list.push(shop)
      })
      if (_.size(item.children)) {
        traverse(item.children)
      }
    }
  }
  traverse(bizLevelShops)

  const arr = shopNos.split(',')
  if (arr.length > 0) {
    return list
      .filter((item) => arr.includes(item.shopNo))
      .map((item) => {
        return {
          ...item,
          [sign]: id === item.userId
        }
      })
  }
  return []
}
